/* -------------------------------- 

File#: _1_custom-select
Title: Custom Select
Descr: Custom Select Control
Usage: codyhouse.co/license

-------------------------------- */

:root {
    // --default variation only 👇
    --select-icon-size: 1em;
    --select-icon-right-margin: var(--space-sm); // icon margin right
    --select-text-icon-gap: var(--space-xxxs); // gap between text and icon
  }
  
  .select {
    position: relative;
  }
  
  .select__input {
    width: 100%;
    height: 100%;
    padding-right: calc(var(--select-icon-size) + var(--select-icon-right-margin) + var(--select-text-icon-gap)) !important;
  }
  
  .select__icon {
    width: var(--select-icon-size);
    height: var(--select-icon-size);
    pointer-events: none;
    position: absolute;
    right: var(--select-icon-right-margin);
    top: 50%;
    transform: translateY(-50%);
  }
  
  // --custom-dropdown
  :root {
    --select-dropdown-gap: 4px; // distance between select control and custom dropdown
  }
  
  .select__button { // created in JS - custom select control
    width: 100%;
  }
  
  .select__button[aria-expanded="true"] {
    // custom select control if dropdown = visible
  } 
  
  .select__dropdown { // created in JS - custom select dropdown
    position: absolute;
    left: 0;
    top: 100%;
    min-width: 200px;
    max-height: 1px; // updated in JS
    background-color: var(--color-bg);
    box-shadow: var(--shadow-md);
    padding: var(--space-xxs) 0;
    border-radius: var(--radius-md);
    z-index: var(--zindex-popover);
    margin-top: var(--select-dropdown-gap);
    margin-bottom: var(--select-dropdown-gap);
    overflow: auto;
  
    // reset spacing and text units - no longer affected by em units
    @include spaceUnit(1rem);
    @include textUnit(1rem);
  
    visibility: hidden;
    opacity: 0;
    transition: visibility 0s .2s, opacity .2s;
  }
  
  .select__dropdown--right { // change dropdown position based on the available space
    right: 0;
    left: auto;
  }
  
  .select__dropdown--up {
    bottom: 100%;
    top: auto;
  }
  
  .select__button[aria-expanded="true"] + .select__dropdown {
    visibility: visible;
    opacity: 1;
    transition: visibility 0s, opacity .2s;
  }
  
  // custom <optgroup> list - include all <option>s if no <optgroup> available 
  .select__list {
    list-style: none !important;
  }
  
  .select__list:not(:first-of-type) { 
    padding-top: var(--space-xxs);
  }
  
  .select__list:not(:last-of-type) {
    border-bottom: 1px solid var(--color-contrast-low);
    padding-bottom: var(--space-xxs);
  }
  
  .select__item { // single item inside .select__list
    display: block;
    padding: var(--space-xs) var(--space-lg) var(--space-xs) var(--space-md);
    color: var(--color-contrast-high);
    width: 100%;
    text-align: left;
    // truncate text
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  
  .select__item--optgroup { // custom <optgroup> label
    font-size: var(--text-sm);
    color: var(--color-contrast-medium);
   }
  
  .select__item--option { // custom <option> label
    cursor: pointer;
  
    &:hover {
      background-color: var(--color-contrast-lower);
    }
  
    &:focus {
      outline: none;
      background-color: alpha(var(--color-primary), 0.1);
    }
  
    &[aria-selected=true] { // selected option
      background-color: var(--color-primary);
      color: var(--color-white);
      position: relative;
      @include fontSmooth;
  
      &::after { // check icon next to the selected language
        content: '';
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        height: 16px;
        width: 16px;
        right: var(--space-sm);
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpolyline stroke-width='1.5' stroke='%23ffffff' fill='none' stroke-linecap='round' stroke-linejoin='round' points='1,9 5,13 15,3 '/%3E%3C/svg%3E");
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
      }
    }
  }
  
  html:not(.js ) .select .icon { // hide icon if JS = disabled
    display: none;
  }