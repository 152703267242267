:root,
[data-theme="default"] {
  // main
  @include defineColorHSL(--color-primary-darker, 32, 33%, 27%);
  @include defineColorHSL(--color-primary-dark, 32, 33%, 37%);
  @include defineColorHSL(--color-primary, 32, 33%, 47%);
  @include defineColorHSL(--color-primary-light, 32, 33%, 57%);
  @include defineColorHSL(--color-primary-lighter, 32, 33%, 67%);

  @include defineColorHSL(--color-accent-darker, 30, 52%, 52%);
  @include defineColorHSL(--color-accent-dark, 30, 52%, 62%);
  @include defineColorHSL(--color-accent, 30, 52%, 72%);
  @include defineColorHSL(--color-accent-light, 30, 52%, 82%);
  @include defineColorHSL(--color-accent-lighter, 30, 52%, 92%);

  @include defineColorHSL(--color-secondary-darker, 29, 41%, 68%);
  @include defineColorHSL(--color-secondary-dark, 29, 41%, 78%);
  @include defineColorHSL(--color-secondary, 29, 41%, 88%);
  @include defineColorHSL(--color-secondary-light, 29, 41%, 94%);
  @include defineColorHSL(--color-secondary-lighter, 29, 41%, 100%);

  @include defineColorHSL(--color-tertiary-darker, 23, 39%, 24%);
  @include defineColorHSL(--color-tertiary-dark, 23, 39%, 34%);
  @include defineColorHSL(--color-tertiary, 23, 39%, 44%);
  @include defineColorHSL(--color-tertiary-light, 23, 39%, 54%);
  @include defineColorHSL(--color-tertiary-lighter, 23, 39%, 64%);

  @include defineColorHSL(--color-black, 0, 0%, 0%);
  @include defineColorHSL(--color-white, 0, 0%, 100%);

  // feedback
  @include defineColorHSL(--color-warning-darker, 53, 62%, 51%);
  @include defineColorHSL(--color-warning-dark, 53, 62%, 61%);
  @include defineColorHSL(--color-warning, 53, 62%, 71%);
  @include defineColorHSL(--color-warning-light, 53, 62%, 81%);
  @include defineColorHSL(--color-warning-lighter, 53, 62%, 91%);

  @include defineColorHSL(--color-success-darker, 146, 31%, 17%);
  @include defineColorHSL(--color-success-dark, 146, 31%, 27%);
  @include defineColorHSL(--color-success, 146, 31%, 37%);
  @include defineColorHSL(--color-success-light, 146, 31%, 47%);
  @include defineColorHSL(--color-success-lighter, 146, 31%, 57%);

  @include defineColorHSL(--color-error-darker, 348, 85%, 34%);
  @include defineColorHSL(--color-error-dark, 348, 85%, 44%);
  @include defineColorHSL(--color-error, 348, 85%, 54%);
  @include defineColorHSL(--color-error-light, 348, 85%, 64%);
  @include defineColorHSL(--color-error-lighter, 348, 85%, 74%);

  // color contrasts
  @include defineColorHSL(--color-bg, 0, 0%, 100%);
  @include defineColorHSL(--color-contrast-lower, 0, 0%, 96%);
  @include defineColorHSL(--color-contrast-low, 0, 0%, 90%);
  @include defineColorHSL(--color-contrast-medium, 0, 0%, 78%);
  @include defineColorHSL(--color-contrast-high, 0, 0%, 19%);
  @include defineColorHSL(--color-contrast-higher, 0, 0%, 0%);
}

// background color
.bg-secondary-darker {
  background-color: alpha(var(--color-secondary-darker), var(--bg-o, 1));
}
.bg-secondary-dark {
  background-color: alpha(var(--color-secondary-dark), var(--bg-o, 1));
}
.bg-secondary {
  background-color: alpha(var(--color-secondary), var(--bg-o, 1));
}
.bg-secondary-light {
  background-color: alpha(var(--color-secondary-light), var(--bg-o, 1));
}
.bg-secondary-lighter {
  background-color: alpha(var(--color-secondary-lighter), var(--bg-o, 1));
}
.bg-tertiary-darker {
  background-color: alpha(var(--color-tertiary-darker), var(--bg-o, 1));
}
.bg-tertiary-dark {
  background-color: alpha(var(--color-tertiary-dark), var(--bg-o, 1));
}
.bg-tertiary {
  background-color: alpha(var(--color-tertiary), var(--bg-o, 1));
}
.bg-tertiary-light {
  background-color: alpha(var(--color-tertiary-light), var(--bg-o, 1));
}
.bg-tertiary-lighter {
  background-color: alpha(var(--color-tertiary-lighter), var(--bg-o, 1));
}

// border color
.border-secondary-darker {
  border-color: alpha(var(--color-secondary-darker), var(--bg-o, 1));
}
.border-secondary-dark {
  border-color: alpha(var(--color-secondary-dark), var(--bg-o, 1));
}
.border-secondary {
  border-color: alpha(var(--color-secondary), var(--bg-o, 1));
}
.border-secondary-light {
  border-color: alpha(var(--color-secondary-light), var(--bg-o, 1));
}
.border-secondary-lighter {
  border-color: alpha(var(--color-secondary-lighter), var(--bg-o, 1));
}
.border-tertiary-darker {
  border-color: alpha(var(--color-tertiary-darker), var(--bg-o, 1));
}
.border-tertiary-dark {
  border-color: alpha(var(--color-tertiary-dark), var(--bg-o, 1));
}
.border-tertiary {
  border-color: alpha(var(--color-tertiary), var(--bg-o, 1));
}
.border-tertiary-light {
  border-color: alpha(var(--color-tertiary-light), var(--bg-o, 1));
}
.border-tertiary-lighter {
  border-color: alpha(var(--color-tertiary-lighter), var(--bg-o, 1));
}
