.btn {
  --btn-font-size: var(--text-sm);
  --btn-padding-x: var(--space-md);
  --btn-padding-y: var(--space-sm);
  --btn-radius: var(--radius-sm);
  background-color: transparent;
  color: var(--color-primary-darker);
  cursor: pointer;
  border: 1px solid var(--color-primary-darker);
  font-family: var(--font-primary);
  font-weight: 500;
  text-transform: uppercase;
  text-decoration: none;
  line-height: 1.2;
  @include fontSmooth;
  transition: all 0.2s ease;

  &:hover,
  &:focus {
    box-shadow: inset 0 0 0 1px currentColor;
  }
  &:active {
    transform: translateY(2px);
  }
}

// themes
.btn--primary {
  background-color: var(--color-primary);
  color: var(--color-white);
  border: 1px solid var(--color-primary);

  &:hover {
    background-color: var(--color-primary-dark);
    box-shadow: none;
  }

  &:focus {
    box-shadow: 0px 0px 0px 2px alpha(var(--color-primary), 0.2);
  }
}

.btn--subtle {
  background-color: transparent;
  color: var(--color-black);
  border: 1px solid var(--color-black);

  &:hover {
    box-shadow: inset 0 0 0 1px currentColor;
  }
}

.btn--accent {
  background-color: var(--color-accent-dark);
  color: var(--color-white);
  border: 1px solid var(--color-accent-dark);

  &:hover {
    background-color: var(--color-accent-darker);
    box-shadow: none;
  }

  &:focus {
    box-shadow: 0px 0px 0px 2px alpha(var(--color-accent), 0.2);
  }
}

.btn--white {
  background-color: alpha(var(--color-white), 0.9);
  color: var(--color-accent-darker);
  border: 1px solid var(--color-white);

  &:hover {
    background-color: var(--color-white);
  }
}

.btn--black {
  background-color: var(--color-black);
  color: var(--color-white);
  border: 1px solid var(--color-black);

  &:hover {
    background-color: alpha(var(--color-black), 0.7);
    border: 1px solid var(--color-black);
    box-shadow: none;
  }
}

// feedback
.btn--disabled,
.btn[disabled],
.btn[readonly] {
  background-color: var(--color-contrast-low);
  color: var(--color-bg);
  cursor: not-allowed;
  border: 0 solid var(--color-contrast-low);
  font-size: var(--text-xs);

  &:hover {
    background-color: var(--color-contrast-low);
  }
}

// size
.btn--sm {
  padding-left: var(--space-sm);
  padding-right: var(--space-sm);
  font-size: var(--text-xs);
}

.btn--md {
  font-size: 1em;
}

.btn--lg {
  font-size: 1.25em;
}
.btn--icon {
  padding: var(--space-xs) var(--space-sm);
}
