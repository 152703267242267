.collection__items {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: var(--space-sm);
  @include breakpoint(md) {
    grid-template-columns: repeat(3, 1fr);
  }
  li {
    margin-bottom: var(--space-sm);
  }
}
