.hero {
  display: grid;
  align-items: center;
}
@include breakpoint(md) {
  .hero,
  .hero img {
    height: calc(100vh - 250px);
  }
  .hero__media {
    position: absolute;
    width: 55%;
    right: 0;
    img {
      width: 100%;
      object-fit: cover;
    }
  }
  .hero__text {
    width: 40%;
  }
}

// collection
.collection__hero {
  background: url("/img/hero-2.jpg") no-repeat center;
  background-size: cover;
  min-height: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
  @include breakpoint(md) {
    min-height: 300px;
  }
}

// move this to link card component
.link-card {
  position: relative;
  background: alpha(var(--color-bg), 0.85);
  padding: var(--space-md);
  text-align: center;
  display: inline-flex;
  flex-direction: column;
  &:before {
    content: "";
    border: 2px solid var(--color-primary);
    position: absolute;
    top: var(--space-xs);
    left: var(--space-xs);
    right: var(--space-xs);
    bottom: var(--space-xs);
  }
  a {
    z-index: 1;
  }
}
