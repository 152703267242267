.height-400 {
  height: 400px;
}
.max-width-300px {
  max-width: 300px;
}
.media-wrapper--21\:9 {
  padding-bottom: 42.857%; // calc(9/21*100)
}

// 👇 (optional) create responsive variations - edit only [my-util-class, property, value]
// @each $breakpoint, $value in $breakpoints {
//   @include breakpoint(#{$breakpoint}) {
//     .my-util-class\@#{$breakpoint} {
//       property: value;
//     }
//   }
// }

@each $breakpoint, $value in $breakpoints {
    @include breakpoint(#{$breakpoint}) {
      .height-xxl\@#{$breakpoint} {
        height: 6rem;
      }
      .width-xxl\@#{$breakpoint} {
        width: 8rem;
      }
    }
  }