/* -------------------------------- 

File#: _1_main-footer
Title: Main Footer
Descr: Footer navigation
Usage: codyhouse.co/license

-------------------------------- */

.main-footer {
  position: relative;
  z-index: 1;
}

.main-footer__logo {
  flex-shrink: 0;
  width: 130px; // logo width
  height: 37px; // logo height
  display: block;

  svg,
  img {
    display: block;
    width: inherit;
    height: inherit;
  }
}

.main-footer__link {
  text-decoration: none;

  &:hover {
    color: var(--color-contrast-high);
    text-decoration: underline;
  }
}

// colophon
.main-footer__colophon {
  display: grid;
  justify-content: space-between;
  align-items: center;
  grid-template-columns: 1fr 1fr;
  @include breakpoint(md) {
    grid-template-columns: repeat(3, 1fr);
  }
}

.main-footer__social {
  text-decoration: none;
  display: inline-block;

  &:hover {
    color: var(--color-contrast-high);
  }
}
