/* -------------------------------- 

File#: _1_steps
Title: Steps
Descr: Multi-step indicator
Usage: codyhouse.co/license

-------------------------------- */

:root {
  --steps-number: 4;
  --step-separator-line-stroke: 3px;
  --step-separator-line-gap: 3px;
  --step-circle-size: 32px;
  --step-circle-font-size: 16px;
}

.steps {
  background-color: var(--color-contrast-lower);
  padding: var(--space-md);
  border-radius: var(--radius-md);
  font-weight: 500;
}

.steps__list {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: calc(var(--space-xs) * -1);
}

.step {
  display: inline-block; // flex fallback
  display: inline-flex;
  align-items: center;
  margin-bottom: var(--space-xs); // equal to steps__list margin-bottom 👆
}

.step__label {
  color: inherit;
  text-decoration: none;
}

.step--completed .step__label,
.step--current .step__label {
  color: var(--color-primary);
}

.steps {
  background-color: transparent;
  padding: calc(var(--step-circle-size) / 2) 0 0;
  border-radius: 0px;
}

.steps__list {
  margin-bottom: initial;
  align-items: flex-start;

  @supports (grid-area: auto) {
    display: grid;
    align-items: start;
    grid-template-columns: repeat(var(--steps-number), 1fr);
  }
}

.step {
  float: left; // flex fallback
  width: calc(100% / var(--steps-number));
  justify-content: center;
  text-align: center;
  position: relative;
  margin-bottom: initial;
  padding: 0 var(--space-xs);

  @supports (grid-area: auto) {
    width: auto;
  }
}

.step__label {
  display: inline-block; // flex fallback
  margin-top: calc(var(--space-xxs) + var(--step-circle-size) / 2);
  display: none;
}

.step__separator {
  // on bigger devices -> line connecting two adjacent steps
  position: absolute;
  top: calc(var(--step-separator-line-stroke) * -1 / 2);
  left: calc(50% + var(--step-circle-size) / 2 + var(--step-separator-line-gap));
  height: var(--step-separator-line-stroke);
  width: calc(100% - var(--step-circle-size) - var(--step-separator-line-gap) * 2);
  margin: 0;
  background-color: var(--color-white);
  // hide text - if there's any
  color: transparent;
  text-indent: 100%;
  white-space: nowrap;
  overflow: hidden;

  .step--completed & {
    background-color: var(--color-black);
  }
}

.step__circle {
  // flex fallback
  display: inline-block;
  line-height: var(--step-circle-size);

  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--color-white);
  width: var(--step-circle-size);
  height: var(--step-circle-size);
  border-radius: 50%;
  position: absolute;
  left: calc(50% - var(--step-circle-size) / 2);
  top: calc(var(--step-circle-size) * -1 / 2);
  font-size: var(--step-circle-font-size);

  .icon {
    width: var(--step-circle-font-size);
    height: var(--step-circle-font-size);
  }

  .step--completed &,
  .step--current & {
    background-color: var(--color-black);
    color: var(--color-white);
    @include fontSmooth;
  }
}

/* screen reader */
.step--completed .step__label::after,
.step--current .step__label::after {
  position: absolute;
  clip: rect(1px, 1px, 1px, 1px);
  clip-path: inset(50%);
}

.step--completed .step__label::after {
  content: "step completed";
}

.step--current .step__label::after {
  content: "step current";
}
