*,
*::after,
*::before {
  box-sizing: inherit;
}

* {
  font: inherit;
}

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video,
hr {
  margin: 0;
  padding: 0;
  border: 0;
}

html {
  box-sizing: border-box;
}

body {
  background-color: var(--color-bg, white);
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section,
main,
form legend {
  display: block;
}

ol,
ul {
  list-style: none;
}

blockquote,
q {
  quotes: none;
}

button,
input,
textarea,
select {
  margin: 0;
}

.btn,
.form-control,
.link,
.reset {
  background-color: transparent;
  padding: 0;
  border: 0;
  border-radius: 0;
  color: inherit;
  line-height: inherit;
  appearance: none;
}

select.form-control::-ms-expand {
  display: none;
}

textarea {
  resize: vertical;
  overflow: auto;
  vertical-align: top;
}

input::-ms-clear {
  display: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

img,
video,
svg {
  max-width: 100%;
}

[data-theme] {
  background-color: var(--color-bg, white);
  color: var(--color-contrast-high, #313135);
}

:root {
  --space-unit: 1em;
  --space-xxxxs: calc(0.125 * var(--space-unit));
  --space-xxxs: calc(0.25 * var(--space-unit));
  --space-xxs: calc(0.375 * var(--space-unit));
  --space-xs: calc(0.5 * var(--space-unit));
  --space-sm: calc(0.75 * var(--space-unit));
  --space-md: calc(1.25 * var(--space-unit));
  --space-lg: calc(2 * var(--space-unit));
  --space-xl: calc(3.25 * var(--space-unit));
  --space-xxl: calc(5.25 * var(--space-unit));
  --space-xxxl: calc(8.5 * var(--space-unit));
  --space-xxxxl: calc(13.75 * var(--space-unit));
  --component-padding: var(--space-md);
}

.container {
  width: calc(100% - 2*var(--component-padding));
  margin-left: auto;
  margin-right: auto;
}

.grid {
  --gap: 0px;
  --offset: var(--gap);
  display: flex;
  flex-wrap: wrap;
}

.grid > * {
  flex-basis: 100%;
  max-width: 100%;
  min-width: 0;
}

@supports (--css: variables) {
  .grid > *[class*=offset-] {
    margin-left: var(--offset);
  }
}

[class*=gap-xxxxs],
[class*=gap-xxxs],
[class*=gap-xxs],
[class*=gap-xs],
[class*=gap-sm],
[class*=gap-md],
[class*=gap-lg],
[class*=gap-xl],
[class*=gap-xxl],
[class*=gap-xxxl],
[class*=gap-xxxxl],
[class*=grid-gap-],
[class*=flex-gap-] {
  margin-bottom: -0.75em;
  margin-left: -0.75em;
}

[class*=gap-xxxxs] > *,
[class*=gap-xxxs] > *,
[class*=gap-xxs] > *,
[class*=gap-xs] > *,
[class*=gap-sm] > *,
[class*=gap-md] > *,
[class*=gap-lg] > *,
[class*=gap-xl] > *,
[class*=gap-xxl] > *,
[class*=gap-xxxl] > *,
[class*=gap-xxxxl] > *,
[class*=grid-gap-] > *,
[class*=flex-gap-] > * {
  margin-bottom: 0.75em;
  margin-left: 0.75em;
}

@supports (--css: variables) {
  [class*=gap-xxxxs],
  [class*=gap-xxxs],
  [class*=gap-xxs],
  [class*=gap-xs],
  [class*=gap-sm],
  [class*=gap-md],
  [class*=gap-lg],
  [class*=gap-xl],
  [class*=gap-xxl],
  [class*=gap-xxxl],
  [class*=gap-xxxxl],
  [class*=grid-gap-],
  [class*=flex-gap-] {
    --gap: 0px;
    --offset: var(--gap);
    margin-bottom: calc(-1 * var(--gap));
    margin-left: calc(-1 * var(--gap));
  }

  [class*=gap-xxxxs] > *,
  [class*=gap-xxxs] > *,
  [class*=gap-xxs] > *,
  [class*=gap-xs] > *,
  [class*=gap-sm] > *,
  [class*=gap-md] > *,
  [class*=gap-lg] > *,
  [class*=gap-xl] > *,
  [class*=gap-xxl] > *,
  [class*=gap-xxxl] > *,
  [class*=gap-xxxxl] > *,
  [class*=grid-gap-] > *,
  [class*=flex-gap-] > * {
    margin-bottom: var(--gap);
    margin-left: var(--offset);
  }

  .gap-xxxxs,
  .grid-gap-xxxxs,
  .flex-gap-xxxxs {
    --gap: var(--space-xxxxs);
  }

  .gap-xxxs,
  .grid-gap-xxxs,
  .flex-gap-xxxs {
    --gap: var(--space-xxxs);
  }

  .gap-xxs,
  .grid-gap-xxs,
  .flex-gap-xxs {
    --gap: var(--space-xxs);
  }

  .gap-xs,
  .grid-gap-xs,
  .flex-gap-xs {
    --gap: var(--space-xs);
  }

  .gap-sm,
  .grid-gap-sm,
  .flex-gap-sm {
    --gap: var(--space-sm);
  }

  .gap-md,
  .grid-gap-md,
  .flex-gap-md {
    --gap: var(--space-md);
  }

  .gap-lg,
  .grid-gap-lg,
  .flex-gap-lg {
    --gap: var(--space-lg);
  }

  .gap-xl,
  .grid-gap-xl,
  .flex-gap-xl {
    --gap: var(--space-xl);
  }

  .gap-xxl,
  .grid-gap-xxl,
  .flex-gap-xxl {
    --gap: var(--space-xxl);
  }

  .gap-xxxl,
  .grid-gap-xxxl,
  .flex-gap-xxxl {
    --gap: var(--space-xxxl);
  }

  .gap-xxxxl,
  .grid-gap-xxxxl,
  .flex-gap-xxxxl {
    --gap: var(--space-xxxxl);
  }
}

.col {
  flex-grow: 1;
  flex-basis: 0;
  max-width: 100%;
}

.col-1 {
  flex-basis: calc( 8.33% - 0.01px - var(--gap, 0.75em));
  max-width: calc( 8.33% - 0.01px - var(--gap, 0.75em));
}

.col-2 {
  flex-basis: calc( 16.66% - 0.01px - var(--gap, 0.75em));
  max-width: calc( 16.66% - 0.01px - var(--gap, 0.75em));
}

.col-3 {
  flex-basis: calc( 25% - 0.01px - var(--gap, 0.75em));
  max-width: calc( 25% - 0.01px - var(--gap, 0.75em));
}

.col-4 {
  flex-basis: calc( 33.33% - 0.01px - var(--gap, 0.75em));
  max-width: calc( 33.33% - 0.01px - var(--gap, 0.75em));
}

.col-5 {
  flex-basis: calc( 41.66% - 0.01px - var(--gap, 0.75em));
  max-width: calc( 41.66% - 0.01px - var(--gap, 0.75em));
}

.col-6 {
  flex-basis: calc( 50% - 0.01px - var(--gap, 0.75em));
  max-width: calc( 50% - 0.01px - var(--gap, 0.75em));
}

.col-7 {
  flex-basis: calc( 58.33% - 0.01px - var(--gap, 0.75em));
  max-width: calc( 58.33% - 0.01px - var(--gap, 0.75em));
}

.col-8 {
  flex-basis: calc( 66.66% - 0.01px - var(--gap, 0.75em));
  max-width: calc( 66.66% - 0.01px - var(--gap, 0.75em));
}

.col-9 {
  flex-basis: calc( 75% - 0.01px - var(--gap, 0.75em));
  max-width: calc( 75% - 0.01px - var(--gap, 0.75em));
}

.col-10 {
  flex-basis: calc( 83.33% - 0.01px - var(--gap, 0.75em));
  max-width: calc( 83.33% - 0.01px - var(--gap, 0.75em));
}

.col-11 {
  flex-basis: calc( 91.66% - 0.01px - var(--gap, 0.75em));
  max-width: calc( 91.66% - 0.01px - var(--gap, 0.75em));
}

.col-12 {
  flex-basis: calc( 100% - 0.01px - var(--gap, 0.75em));
  max-width: calc( 100% - 0.01px - var(--gap, 0.75em));
}

.col-content {
  flex-grow: 0;
  flex-basis: initial;
  max-width: initial;
}

.offset-1 {
  --offset: calc(8.33% + var(--gap, 0.75em));
}

.offset-2 {
  --offset: calc(16.66% + var(--gap, 0.75em));
}

.offset-3 {
  --offset: calc(25% + var(--gap, 0.75em));
}

.offset-4 {
  --offset: calc(33.33% + var(--gap, 0.75em));
}

.offset-5 {
  --offset: calc(41.66% + var(--gap, 0.75em));
}

.offset-6 {
  --offset: calc(50% + var(--gap, 0.75em));
}

.offset-7 {
  --offset: calc(58.33% + var(--gap, 0.75em));
}

.offset-8 {
  --offset: calc(66.66% + var(--gap, 0.75em));
}

.offset-9 {
  --offset: calc(75% + var(--gap, 0.75em));
}

.offset-10 {
  --offset: calc(83.33% + var(--gap, 0.75em));
}

.offset-11 {
  --offset: calc(91.66% + var(--gap, 0.75em));
}

@media (min-width: 32rem) {
  .col\@xs {
    flex-grow: 1;
    flex-basis: 0;
    max-width: 100%;
  }

  .col-1\@xs {
    flex-basis: calc( 8.33% - 0.01px - var(--gap, 0.75em));
    max-width: calc( 8.33% - 0.01px - var(--gap, 0.75em));
  }

  .col-2\@xs {
    flex-basis: calc( 16.66% - 0.01px - var(--gap, 0.75em));
    max-width: calc( 16.66% - 0.01px - var(--gap, 0.75em));
  }

  .col-3\@xs {
    flex-basis: calc( 25% - 0.01px - var(--gap, 0.75em));
    max-width: calc( 25% - 0.01px - var(--gap, 0.75em));
  }

  .col-4\@xs {
    flex-basis: calc( 33.33% - 0.01px - var(--gap, 0.75em));
    max-width: calc( 33.33% - 0.01px - var(--gap, 0.75em));
  }

  .col-5\@xs {
    flex-basis: calc( 41.66% - 0.01px - var(--gap, 0.75em));
    max-width: calc( 41.66% - 0.01px - var(--gap, 0.75em));
  }

  .col-6\@xs {
    flex-basis: calc( 50% - 0.01px - var(--gap, 0.75em));
    max-width: calc( 50% - 0.01px - var(--gap, 0.75em));
  }

  .col-7\@xs {
    flex-basis: calc( 58.33% - 0.01px - var(--gap, 0.75em));
    max-width: calc( 58.33% - 0.01px - var(--gap, 0.75em));
  }

  .col-8\@xs {
    flex-basis: calc( 66.66% - 0.01px - var(--gap, 0.75em));
    max-width: calc( 66.66% - 0.01px - var(--gap, 0.75em));
  }

  .col-9\@xs {
    flex-basis: calc( 75% - 0.01px - var(--gap, 0.75em));
    max-width: calc( 75% - 0.01px - var(--gap, 0.75em));
  }

  .col-10\@xs {
    flex-basis: calc( 83.33% - 0.01px - var(--gap, 0.75em));
    max-width: calc( 83.33% - 0.01px - var(--gap, 0.75em));
  }

  .col-11\@xs {
    flex-basis: calc( 91.66% - 0.01px - var(--gap, 0.75em));
    max-width: calc( 91.66% - 0.01px - var(--gap, 0.75em));
  }

  .col-12\@xs {
    flex-basis: calc( 100% - 0.01px - var(--gap, 0.75em));
    max-width: calc( 100% - 0.01px - var(--gap, 0.75em));
  }

  .col-content\@xs {
    flex-grow: 0;
    flex-basis: initial;
    max-width: initial;
  }

  .offset-1\@xs {
    --offset: calc(8.33% + var(--gap, 0.75em));
  }

  .offset-2\@xs {
    --offset: calc(16.66% + var(--gap, 0.75em));
  }

  .offset-3\@xs {
    --offset: calc(25% + var(--gap, 0.75em));
  }

  .offset-4\@xs {
    --offset: calc(33.33% + var(--gap, 0.75em));
  }

  .offset-5\@xs {
    --offset: calc(41.66% + var(--gap, 0.75em));
  }

  .offset-6\@xs {
    --offset: calc(50% + var(--gap, 0.75em));
  }

  .offset-7\@xs {
    --offset: calc(58.33% + var(--gap, 0.75em));
  }

  .offset-8\@xs {
    --offset: calc(66.66% + var(--gap, 0.75em));
  }

  .offset-9\@xs {
    --offset: calc(75% + var(--gap, 0.75em));
  }

  .offset-10\@xs {
    --offset: calc(83.33% + var(--gap, 0.75em));
  }

  .offset-11\@xs {
    --offset: calc(91.66% + var(--gap, 0.75em));
  }

@supports (--css: variables) {
    .gap-xxxxs\@xs {
      --gap: var(--space-xxxxs);
    }

    .gap-xxxs\@xs {
      --gap: var(--space-xxxs);
    }

    .gap-xxs\@xs {
      --gap: var(--space-xxs);
    }

    .gap-xs\@xs {
      --gap: var(--space-xs);
    }

    .gap-sm\@xs {
      --gap: var(--space-sm);
    }

    .gap-md\@xs {
      --gap: var(--space-md);
    }

    .gap-lg\@xs {
      --gap: var(--space-lg);
    }

    .gap-xl\@xs {
      --gap: var(--space-xl);
    }

    .gap-xxl\@xs {
      --gap: var(--space-xxl);
    }

    .gap-xxxl\@xs {
      --gap: var(--space-xxxl);
    }

    .gap-xxxxl\@xs {
      --gap: var(--space-xxxxl);
    }

    .gap-0\@xs {
      --gap: 0px;
    }
}
}

@media (min-width: 48rem) {
  .col\@sm {
    flex-grow: 1;
    flex-basis: 0;
    max-width: 100%;
  }

  .col-1\@sm {
    flex-basis: calc( 8.33% - 0.01px - var(--gap, 0.75em));
    max-width: calc( 8.33% - 0.01px - var(--gap, 0.75em));
  }

  .col-2\@sm {
    flex-basis: calc( 16.66% - 0.01px - var(--gap, 0.75em));
    max-width: calc( 16.66% - 0.01px - var(--gap, 0.75em));
  }

  .col-3\@sm {
    flex-basis: calc( 25% - 0.01px - var(--gap, 0.75em));
    max-width: calc( 25% - 0.01px - var(--gap, 0.75em));
  }

  .col-4\@sm {
    flex-basis: calc( 33.33% - 0.01px - var(--gap, 0.75em));
    max-width: calc( 33.33% - 0.01px - var(--gap, 0.75em));
  }

  .col-5\@sm {
    flex-basis: calc( 41.66% - 0.01px - var(--gap, 0.75em));
    max-width: calc( 41.66% - 0.01px - var(--gap, 0.75em));
  }

  .col-6\@sm {
    flex-basis: calc( 50% - 0.01px - var(--gap, 0.75em));
    max-width: calc( 50% - 0.01px - var(--gap, 0.75em));
  }

  .col-7\@sm {
    flex-basis: calc( 58.33% - 0.01px - var(--gap, 0.75em));
    max-width: calc( 58.33% - 0.01px - var(--gap, 0.75em));
  }

  .col-8\@sm {
    flex-basis: calc( 66.66% - 0.01px - var(--gap, 0.75em));
    max-width: calc( 66.66% - 0.01px - var(--gap, 0.75em));
  }

  .col-9\@sm {
    flex-basis: calc( 75% - 0.01px - var(--gap, 0.75em));
    max-width: calc( 75% - 0.01px - var(--gap, 0.75em));
  }

  .col-10\@sm {
    flex-basis: calc( 83.33% - 0.01px - var(--gap, 0.75em));
    max-width: calc( 83.33% - 0.01px - var(--gap, 0.75em));
  }

  .col-11\@sm {
    flex-basis: calc( 91.66% - 0.01px - var(--gap, 0.75em));
    max-width: calc( 91.66% - 0.01px - var(--gap, 0.75em));
  }

  .col-12\@sm {
    flex-basis: calc( 100% - 0.01px - var(--gap, 0.75em));
    max-width: calc( 100% - 0.01px - var(--gap, 0.75em));
  }

  .col-content\@sm {
    flex-grow: 0;
    flex-basis: initial;
    max-width: initial;
  }

  .offset-1\@sm {
    --offset: calc(8.33% + var(--gap, 0.75em));
  }

  .offset-2\@sm {
    --offset: calc(16.66% + var(--gap, 0.75em));
  }

  .offset-3\@sm {
    --offset: calc(25% + var(--gap, 0.75em));
  }

  .offset-4\@sm {
    --offset: calc(33.33% + var(--gap, 0.75em));
  }

  .offset-5\@sm {
    --offset: calc(41.66% + var(--gap, 0.75em));
  }

  .offset-6\@sm {
    --offset: calc(50% + var(--gap, 0.75em));
  }

  .offset-7\@sm {
    --offset: calc(58.33% + var(--gap, 0.75em));
  }

  .offset-8\@sm {
    --offset: calc(66.66% + var(--gap, 0.75em));
  }

  .offset-9\@sm {
    --offset: calc(75% + var(--gap, 0.75em));
  }

  .offset-10\@sm {
    --offset: calc(83.33% + var(--gap, 0.75em));
  }

  .offset-11\@sm {
    --offset: calc(91.66% + var(--gap, 0.75em));
  }

@supports (--css: variables) {
    .gap-xxxxs\@sm {
      --gap: var(--space-xxxxs);
    }

    .gap-xxxs\@sm {
      --gap: var(--space-xxxs);
    }

    .gap-xxs\@sm {
      --gap: var(--space-xxs);
    }

    .gap-xs\@sm {
      --gap: var(--space-xs);
    }

    .gap-sm\@sm {
      --gap: var(--space-sm);
    }

    .gap-md\@sm {
      --gap: var(--space-md);
    }

    .gap-lg\@sm {
      --gap: var(--space-lg);
    }

    .gap-xl\@sm {
      --gap: var(--space-xl);
    }

    .gap-xxl\@sm {
      --gap: var(--space-xxl);
    }

    .gap-xxxl\@sm {
      --gap: var(--space-xxxl);
    }

    .gap-xxxxl\@sm {
      --gap: var(--space-xxxxl);
    }

    .gap-0\@sm {
      --gap: 0px;
    }
}
}

@media (min-width: 64rem) {
  .col\@md {
    flex-grow: 1;
    flex-basis: 0;
    max-width: 100%;
  }

  .col-1\@md {
    flex-basis: calc( 8.33% - 0.01px - var(--gap, 0.75em));
    max-width: calc( 8.33% - 0.01px - var(--gap, 0.75em));
  }

  .col-2\@md {
    flex-basis: calc( 16.66% - 0.01px - var(--gap, 0.75em));
    max-width: calc( 16.66% - 0.01px - var(--gap, 0.75em));
  }

  .col-3\@md {
    flex-basis: calc( 25% - 0.01px - var(--gap, 0.75em));
    max-width: calc( 25% - 0.01px - var(--gap, 0.75em));
  }

  .col-4\@md {
    flex-basis: calc( 33.33% - 0.01px - var(--gap, 0.75em));
    max-width: calc( 33.33% - 0.01px - var(--gap, 0.75em));
  }

  .col-5\@md {
    flex-basis: calc( 41.66% - 0.01px - var(--gap, 0.75em));
    max-width: calc( 41.66% - 0.01px - var(--gap, 0.75em));
  }

  .col-6\@md {
    flex-basis: calc( 50% - 0.01px - var(--gap, 0.75em));
    max-width: calc( 50% - 0.01px - var(--gap, 0.75em));
  }

  .col-7\@md {
    flex-basis: calc( 58.33% - 0.01px - var(--gap, 0.75em));
    max-width: calc( 58.33% - 0.01px - var(--gap, 0.75em));
  }

  .col-8\@md {
    flex-basis: calc( 66.66% - 0.01px - var(--gap, 0.75em));
    max-width: calc( 66.66% - 0.01px - var(--gap, 0.75em));
  }

  .col-9\@md {
    flex-basis: calc( 75% - 0.01px - var(--gap, 0.75em));
    max-width: calc( 75% - 0.01px - var(--gap, 0.75em));
  }

  .col-10\@md {
    flex-basis: calc( 83.33% - 0.01px - var(--gap, 0.75em));
    max-width: calc( 83.33% - 0.01px - var(--gap, 0.75em));
  }

  .col-11\@md {
    flex-basis: calc( 91.66% - 0.01px - var(--gap, 0.75em));
    max-width: calc( 91.66% - 0.01px - var(--gap, 0.75em));
  }

  .col-12\@md {
    flex-basis: calc( 100% - 0.01px - var(--gap, 0.75em));
    max-width: calc( 100% - 0.01px - var(--gap, 0.75em));
  }

  .col-content\@md {
    flex-grow: 0;
    flex-basis: initial;
    max-width: initial;
  }

  .offset-1\@md {
    --offset: calc(8.33% + var(--gap, 0.75em));
  }

  .offset-2\@md {
    --offset: calc(16.66% + var(--gap, 0.75em));
  }

  .offset-3\@md {
    --offset: calc(25% + var(--gap, 0.75em));
  }

  .offset-4\@md {
    --offset: calc(33.33% + var(--gap, 0.75em));
  }

  .offset-5\@md {
    --offset: calc(41.66% + var(--gap, 0.75em));
  }

  .offset-6\@md {
    --offset: calc(50% + var(--gap, 0.75em));
  }

  .offset-7\@md {
    --offset: calc(58.33% + var(--gap, 0.75em));
  }

  .offset-8\@md {
    --offset: calc(66.66% + var(--gap, 0.75em));
  }

  .offset-9\@md {
    --offset: calc(75% + var(--gap, 0.75em));
  }

  .offset-10\@md {
    --offset: calc(83.33% + var(--gap, 0.75em));
  }

  .offset-11\@md {
    --offset: calc(91.66% + var(--gap, 0.75em));
  }

@supports (--css: variables) {
    .gap-xxxxs\@md {
      --gap: var(--space-xxxxs);
    }

    .gap-xxxs\@md {
      --gap: var(--space-xxxs);
    }

    .gap-xxs\@md {
      --gap: var(--space-xxs);
    }

    .gap-xs\@md {
      --gap: var(--space-xs);
    }

    .gap-sm\@md {
      --gap: var(--space-sm);
    }

    .gap-md\@md {
      --gap: var(--space-md);
    }

    .gap-lg\@md {
      --gap: var(--space-lg);
    }

    .gap-xl\@md {
      --gap: var(--space-xl);
    }

    .gap-xxl\@md {
      --gap: var(--space-xxl);
    }

    .gap-xxxl\@md {
      --gap: var(--space-xxxl);
    }

    .gap-xxxxl\@md {
      --gap: var(--space-xxxxl);
    }

    .gap-0\@md {
      --gap: 0px;
    }
}
}

@media (min-width: 80rem) {
  .col\@lg {
    flex-grow: 1;
    flex-basis: 0;
    max-width: 100%;
  }

  .col-1\@lg {
    flex-basis: calc( 8.33% - 0.01px - var(--gap, 0.75em));
    max-width: calc( 8.33% - 0.01px - var(--gap, 0.75em));
  }

  .col-2\@lg {
    flex-basis: calc( 16.66% - 0.01px - var(--gap, 0.75em));
    max-width: calc( 16.66% - 0.01px - var(--gap, 0.75em));
  }

  .col-3\@lg {
    flex-basis: calc( 25% - 0.01px - var(--gap, 0.75em));
    max-width: calc( 25% - 0.01px - var(--gap, 0.75em));
  }

  .col-4\@lg {
    flex-basis: calc( 33.33% - 0.01px - var(--gap, 0.75em));
    max-width: calc( 33.33% - 0.01px - var(--gap, 0.75em));
  }

  .col-5\@lg {
    flex-basis: calc( 41.66% - 0.01px - var(--gap, 0.75em));
    max-width: calc( 41.66% - 0.01px - var(--gap, 0.75em));
  }

  .col-6\@lg {
    flex-basis: calc( 50% - 0.01px - var(--gap, 0.75em));
    max-width: calc( 50% - 0.01px - var(--gap, 0.75em));
  }

  .col-7\@lg {
    flex-basis: calc( 58.33% - 0.01px - var(--gap, 0.75em));
    max-width: calc( 58.33% - 0.01px - var(--gap, 0.75em));
  }

  .col-8\@lg {
    flex-basis: calc( 66.66% - 0.01px - var(--gap, 0.75em));
    max-width: calc( 66.66% - 0.01px - var(--gap, 0.75em));
  }

  .col-9\@lg {
    flex-basis: calc( 75% - 0.01px - var(--gap, 0.75em));
    max-width: calc( 75% - 0.01px - var(--gap, 0.75em));
  }

  .col-10\@lg {
    flex-basis: calc( 83.33% - 0.01px - var(--gap, 0.75em));
    max-width: calc( 83.33% - 0.01px - var(--gap, 0.75em));
  }

  .col-11\@lg {
    flex-basis: calc( 91.66% - 0.01px - var(--gap, 0.75em));
    max-width: calc( 91.66% - 0.01px - var(--gap, 0.75em));
  }

  .col-12\@lg {
    flex-basis: calc( 100% - 0.01px - var(--gap, 0.75em));
    max-width: calc( 100% - 0.01px - var(--gap, 0.75em));
  }

  .col-content\@lg {
    flex-grow: 0;
    flex-basis: initial;
    max-width: initial;
  }

  .offset-1\@lg {
    --offset: calc(8.33% + var(--gap, 0.75em));
  }

  .offset-2\@lg {
    --offset: calc(16.66% + var(--gap, 0.75em));
  }

  .offset-3\@lg {
    --offset: calc(25% + var(--gap, 0.75em));
  }

  .offset-4\@lg {
    --offset: calc(33.33% + var(--gap, 0.75em));
  }

  .offset-5\@lg {
    --offset: calc(41.66% + var(--gap, 0.75em));
  }

  .offset-6\@lg {
    --offset: calc(50% + var(--gap, 0.75em));
  }

  .offset-7\@lg {
    --offset: calc(58.33% + var(--gap, 0.75em));
  }

  .offset-8\@lg {
    --offset: calc(66.66% + var(--gap, 0.75em));
  }

  .offset-9\@lg {
    --offset: calc(75% + var(--gap, 0.75em));
  }

  .offset-10\@lg {
    --offset: calc(83.33% + var(--gap, 0.75em));
  }

  .offset-11\@lg {
    --offset: calc(91.66% + var(--gap, 0.75em));
  }

@supports (--css: variables) {
    .gap-xxxxs\@lg {
      --gap: var(--space-xxxxs);
    }

    .gap-xxxs\@lg {
      --gap: var(--space-xxxs);
    }

    .gap-xxs\@lg {
      --gap: var(--space-xxs);
    }

    .gap-xs\@lg {
      --gap: var(--space-xs);
    }

    .gap-sm\@lg {
      --gap: var(--space-sm);
    }

    .gap-md\@lg {
      --gap: var(--space-md);
    }

    .gap-lg\@lg {
      --gap: var(--space-lg);
    }

    .gap-xl\@lg {
      --gap: var(--space-xl);
    }

    .gap-xxl\@lg {
      --gap: var(--space-xxl);
    }

    .gap-xxxl\@lg {
      --gap: var(--space-xxxl);
    }

    .gap-xxxxl\@lg {
      --gap: var(--space-xxxxl);
    }

    .gap-0\@lg {
      --gap: 0px;
    }
}
}

@media (min-width: 90rem) {
  .col\@xl {
    flex-grow: 1;
    flex-basis: 0;
    max-width: 100%;
  }

  .col-1\@xl {
    flex-basis: calc( 8.33% - 0.01px - var(--gap, 0.75em));
    max-width: calc( 8.33% - 0.01px - var(--gap, 0.75em));
  }

  .col-2\@xl {
    flex-basis: calc( 16.66% - 0.01px - var(--gap, 0.75em));
    max-width: calc( 16.66% - 0.01px - var(--gap, 0.75em));
  }

  .col-3\@xl {
    flex-basis: calc( 25% - 0.01px - var(--gap, 0.75em));
    max-width: calc( 25% - 0.01px - var(--gap, 0.75em));
  }

  .col-4\@xl {
    flex-basis: calc( 33.33% - 0.01px - var(--gap, 0.75em));
    max-width: calc( 33.33% - 0.01px - var(--gap, 0.75em));
  }

  .col-5\@xl {
    flex-basis: calc( 41.66% - 0.01px - var(--gap, 0.75em));
    max-width: calc( 41.66% - 0.01px - var(--gap, 0.75em));
  }

  .col-6\@xl {
    flex-basis: calc( 50% - 0.01px - var(--gap, 0.75em));
    max-width: calc( 50% - 0.01px - var(--gap, 0.75em));
  }

  .col-7\@xl {
    flex-basis: calc( 58.33% - 0.01px - var(--gap, 0.75em));
    max-width: calc( 58.33% - 0.01px - var(--gap, 0.75em));
  }

  .col-8\@xl {
    flex-basis: calc( 66.66% - 0.01px - var(--gap, 0.75em));
    max-width: calc( 66.66% - 0.01px - var(--gap, 0.75em));
  }

  .col-9\@xl {
    flex-basis: calc( 75% - 0.01px - var(--gap, 0.75em));
    max-width: calc( 75% - 0.01px - var(--gap, 0.75em));
  }

  .col-10\@xl {
    flex-basis: calc( 83.33% - 0.01px - var(--gap, 0.75em));
    max-width: calc( 83.33% - 0.01px - var(--gap, 0.75em));
  }

  .col-11\@xl {
    flex-basis: calc( 91.66% - 0.01px - var(--gap, 0.75em));
    max-width: calc( 91.66% - 0.01px - var(--gap, 0.75em));
  }

  .col-12\@xl {
    flex-basis: calc( 100% - 0.01px - var(--gap, 0.75em));
    max-width: calc( 100% - 0.01px - var(--gap, 0.75em));
  }

  .col-content\@xl {
    flex-grow: 0;
    flex-basis: initial;
    max-width: initial;
  }

  .offset-1\@xl {
    --offset: calc(8.33% + var(--gap, 0.75em));
  }

  .offset-2\@xl {
    --offset: calc(16.66% + var(--gap, 0.75em));
  }

  .offset-3\@xl {
    --offset: calc(25% + var(--gap, 0.75em));
  }

  .offset-4\@xl {
    --offset: calc(33.33% + var(--gap, 0.75em));
  }

  .offset-5\@xl {
    --offset: calc(41.66% + var(--gap, 0.75em));
  }

  .offset-6\@xl {
    --offset: calc(50% + var(--gap, 0.75em));
  }

  .offset-7\@xl {
    --offset: calc(58.33% + var(--gap, 0.75em));
  }

  .offset-8\@xl {
    --offset: calc(66.66% + var(--gap, 0.75em));
  }

  .offset-9\@xl {
    --offset: calc(75% + var(--gap, 0.75em));
  }

  .offset-10\@xl {
    --offset: calc(83.33% + var(--gap, 0.75em));
  }

  .offset-11\@xl {
    --offset: calc(91.66% + var(--gap, 0.75em));
  }

@supports (--css: variables) {
    .gap-xxxxs\@xl {
      --gap: var(--space-xxxxs);
    }

    .gap-xxxs\@xl {
      --gap: var(--space-xxxs);
    }

    .gap-xxs\@xl {
      --gap: var(--space-xxs);
    }

    .gap-xs\@xl {
      --gap: var(--space-xs);
    }

    .gap-sm\@xl {
      --gap: var(--space-sm);
    }

    .gap-md\@xl {
      --gap: var(--space-md);
    }

    .gap-lg\@xl {
      --gap: var(--space-lg);
    }

    .gap-xl\@xl {
      --gap: var(--space-xl);
    }

    .gap-xxl\@xl {
      --gap: var(--space-xxl);
    }

    .gap-xxxl\@xl {
      --gap: var(--space-xxxl);
    }

    .gap-xxxxl\@xl {
      --gap: var(--space-xxxxl);
    }

    .gap-0\@xl {
      --gap: 0px;
    }
}
}

:root {
  --radius-sm: calc(var(--radius, 0.25em)/2);
  --radius-md: var(--radius, 0.25em);
  --radius-lg: calc(var(--radius, 0.25em)*2);
  --shadow-xs: 0 0.1px 0.3px rgba(0, 0, 0, 0.06),
                0 1px 2px rgba(0, 0, 0, 0.12);
  --shadow-sm: 0 0.3px 0.4px rgba(0, 0, 0, 0.025),
                0 0.9px 1.5px rgba(0, 0, 0, 0.05),
                0 3.5px 6px rgba(0, 0, 0, 0.1);
  --shadow-md: 0 0.9px 1.5px rgba(0, 0, 0, 0.03),
                0 3.1px 5.5px rgba(0, 0, 0, 0.08),
                0 14px 25px rgba(0, 0, 0, 0.12);
  --shadow-lg: 0 1.2px 1.9px -1px rgba(0, 0, 0, 0.014),
                0 3.3px 5.3px -1px rgba(0, 0, 0, 0.038),
                0 8.5px 12.7px -1px rgba(0, 0, 0, 0.085),
                0 30px 42px -1px rgba(0, 0, 0, 0.15);
  --shadow-xl: 0 1.5px 2.1px -6px rgba(0, 0, 0, 0.012),
                0 3.6px 5.2px -6px rgba(0, 0, 0, 0.035),
                0 7.3px 10.6px -6px rgba(0, 0, 0, 0.07),
                0 16.2px 21.9px -6px rgba(0, 0, 0, 0.117),
                0 46px 60px -6px rgba(0, 0, 0, 0.2);
  --bounce: cubic-bezier(0.175, 0.885, 0.32, 1.275);
  --ease-in-out: cubic-bezier(0.645, 0.045, 0.355, 1);
  --ease-in: cubic-bezier(0.55, 0.055, 0.675, 0.19);
  --ease-out: cubic-bezier(0.215, 0.61, 0.355, 1);
  --ease-out-back: cubic-bezier(0.34, 1.56, 0.64, 1);
}

:root {
  --heading-line-height: 1.2;
  --body-line-height: 1.4;
}

body {
  font-size: var(--text-base-size, 1em);
  font-family: var(--font-primary, sans-serif);
  color: var(--color-contrast-high, #313135);
}

h1,
h2,
h3,
h4 {
  color: var(--color-contrast-higher, #1c1c21);
  line-height: var(--heading-line-height, 1.2);
}

h1 {
  font-size: var(--text-xxl, 2.074em);
}

h2 {
  font-size: var(--text-xl, 1.728em);
}

h3 {
  font-size: var(--text-lg, 1.44em);
}

h4 {
  font-size: var(--text-md, 1.2em);
}

small {
  font-size: var(--text-sm, 0.833em);
}

a,
.link {
  color: var(--color-primary, #2a6df4);
  text-decoration: underline;
}

strong {
  font-weight: bold;
}

s {
  text-decoration: line-through;
}

u {
  text-decoration: underline;
}

.text-component h1,
.text-component h2,
.text-component h3,
.text-component h4 {
  line-height: calc(var(--heading-line-height) * var(--line-height-multiplier, 1));
  margin-bottom: calc(var(--space-unit) * 0.25 * var(--text-vspace-multiplier, 1));
}

.text-component h2,
.text-component h3,
.text-component h4 {
  margin-top: calc(var(--space-unit) * 0.75 * var(--text-vspace-multiplier, 1));
}

.text-component p,
.text-component blockquote,
.text-component ul li,
.text-component ol li {
  line-height: calc(var(--body-line-height) * var(--line-height-multiplier, 1));
}

.text-component ul,
.text-component ol,
.text-component p,
.text-component blockquote,
.text-component .text-component__block {
  margin-bottom: calc(var(--space-unit) * 0.75 * var(--text-vspace-multiplier, 1));
}

.text-component ul,
.text-component ol {
  list-style-position: inside;
}

.text-component ul {
  list-style-type: disc;
}

.text-component ol {
  list-style-type: decimal;
}

.text-component img {
  display: block;
  margin: 0 auto;
}

.text-component figcaption {
  text-align: center;
  margin-top: calc(var(--space-unit) * 0.5);
}

.text-component em {
  font-style: italic;
}

.text-component hr {
  margin-top: calc(var(--space-unit) * var(--text-vspace-multiplier, 1));
  margin-bottom: calc(var(--space-unit) * var(--text-vspace-multiplier, 1));
  margin-left: auto;
  margin-right: auto;
}

.text-component > *:first-child {
  margin-top: 0;
}

.text-component > *:last-child {
  margin-bottom: 0;
}

.text-component__block--full-width {
  width: 100vw;
  margin-left: calc(50% - 50vw);
}

@media (min-width: 48rem) {
  .text-component__block--left,
  .text-component__block--right {
    width: 45%;
  }

  .text-component__block--left img,
  .text-component__block--right img {
    width: 100%;
  }

  .text-component__block--left {
    float: left;
    margin-right: calc(var(--space-unit) * 0.75 * var(--text-vspace-multiplier, 1));
  }

  .text-component__block--right {
    float: right;
    margin-left: calc(var(--space-unit) * 0.75 * var(--text-vspace-multiplier, 1));
  }
}

@media (min-width: 90rem) {
  .text-component__block--outset {
    width: calc(100% + 10.5 * var(--space-unit));
  }

  .text-component__block--outset img {
    width: 100%;
  }

  .text-component__block--outset:not(.text-component__block--right) {
    margin-left: calc(-5.25 * var(--space-unit));
  }

  .text-component__block--left,
  .text-component__block--right {
    width: 50%;
  }

  .text-component__block--right.text-component__block--outset {
    margin-right: calc(-5.25 * var(--space-unit));
  }
}

:root {
  --icon-xxxs: 8px;
  --icon-xxs: 12px;
  --icon-xs: 16px;
  --icon-sm: 24px;
  --icon-md: 32px;
  --icon-lg: 48px;
  --icon-xl: 64px;
  --icon-xxl: 96px;
  --icon-xxxl: 128px;
}

.icon {
  display: inline-block;
  color: inherit;
  fill: currentColor;
  height: 1em;
  width: 1em;
  line-height: 1;
  flex-shrink: 0;
  max-width: initial;
}

.icon--xxxs {
  width: var(--icon-xxxs);
  height: var(--icon-xxxs);
}

.icon--xxs {
  width: var(--icon-xxs);
  height: var(--icon-xxs);
}

.icon--xs {
  width: var(--icon-xs);
  height: var(--icon-xs);
}

.icon--sm {
  width: var(--icon-sm);
  height: var(--icon-sm);
}

.icon--md {
  width: var(--icon-md);
  height: var(--icon-md);
}

.icon--lg {
  width: var(--icon-lg);
  height: var(--icon-lg);
}

.icon--xl {
  width: var(--icon-xl);
  height: var(--icon-xl);
}

.icon--xxl {
  width: var(--icon-xxl);
  height: var(--icon-xxl);
}

.icon--xxxl {
  width: var(--icon-xxxl);
  height: var(--icon-xxxl);
}

.icon--is-spinning {
  animation: icon-spin 1s infinite linear;
}

@keyframes icon-spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.icon use {
  color: inherit;
  fill: currentColor;
}

.btn {
  position: relative;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  white-space: nowrap;
  text-decoration: none;
  line-height: 1;
  font-size: var(--btn-font-size, 1em);
  padding-top: var(--btn-padding-y, 0.5em);
  padding-bottom: var(--btn-padding-y, 0.5em);
  padding-left: var(--btn-padding-x, 0.75em);
  padding-right: var(--btn-padding-x, 0.75em);
  border-radius: var(--btn-radius, 0.25em);
}

.btn--sm {
  font-size: var(--btn-font-size-sm, 0.8em);
}

.btn--md {
  font-size: var(--btn-font-size-md, 1.2em);
}

.btn--lg {
  font-size: var(--btn-font-size-lg, 1.4em);
}

.btn--icon {
  padding: var(--btn-padding-y, 0.5em);
}

.form-control {
  font-size: var(--form-control-font-size, 1em);
  padding-top: var(--form-control-padding-y, 0.5em);
  padding-bottom: var(--form-control-padding-y, 0.5em);
  padding-left: var(--form-control-padding-x, 0.75em);
  padding-right: var(--form-control-padding-x, 0.75em);
  border-radius: var(--form-control-radius, 0.25em);
}

.form-legend {
  color: var(--color-contrast-higher, #1c1c21);
  line-height: var(--heading-line-height, 1.2);
  font-size: var(--text-md, 1.2em);
  margin-bottom: var(--space-xxs);
}

.form-label {
  display: inline-block;
}

.form__msg-error,
.form-error-msg {
  color: var(--color-error, #e02447);
  font-size: var(--text-sm, 0.833em);
  margin-top: var(--space-xxs);
  position: absolute;
  clip: rect(1px, 1px, 1px, 1px);
}

.form__msg-error--is-visible,
.form-error-msg--is-visible {
  position: relative;
  clip: auto;
}

.radio-list > *,
.checkbox-list > * {
  position: relative;
  display: flex;
  align-items: baseline;
}

.radio-list label,
.checkbox-list label {
  line-height: var(--body-line-height);
}

.radio-list input,
.checkbox-list input {
  margin-right: var(--space-xxxs);
  flex-shrink: 0;
}

:root {
  --zindex-header: 3;
  --zindex-popover: 5;
  --zindex-fixed-element: 10;
  --zindex-overlay: 15;
}

:root {
  --display: block;
}

.is-visible {
  display: var(--display) !important;
}

.is-hidden {
  display: none !important;
}

html:not(.js) .no-js\:is-hidden {
  display: none !important;
}

@media print {
  .print\:is-hidden {
    display: none !important;
  }
}

.sr-only {
  position: absolute;
  clip: rect(1px, 1px, 1px, 1px);
  clip-path: inset(50%);
  width: 1px;
  height: 1px;
  overflow: hidden;
  padding: 0;
  border: 0;
  white-space: nowrap;
}

.flex {
  display: flex;
}

.inline-flex {
  display: inline-flex;
}

.flex-wrap {
  flex-wrap: wrap;
}

.flex-column {
  flex-direction: column;
}

.flex-column-reverse {
  flex-direction: column-reverse;
}

.flex-row {
  flex-direction: row;
}

.flex-row-reverse {
  flex-direction: row-reverse;
}

.flex-center {
  justify-content: center;
  align-items: center;
}

.flex-grow {
  flex-grow: 1;
}

.flex-shrink-0 {
  flex-shrink: 0;
}

.flex-basis-0 {
  flex-basis: 0;
}

.justify-start {
  justify-content: flex-start;
}

.justify-end {
  justify-content: flex-end;
}

.justify-center {
  justify-content: center;
}

.justify-between {
  justify-content: space-between;
}

.items-center {
  align-items: center;
}

.items-start {
  align-items: flex-start;
}

.items-end {
  align-items: flex-end;
}

.items-baseline {
  align-items: baseline;
}

.order-1 {
  order: 1;
}

.order-2 {
  order: 2;
}

.order-3 {
  order: 3;
}

.block {
  display: block;
}

.inline-block {
  display: inline-block;
}

.inline {
  display: inline;
}

.margin-xxxxs {
  margin: var(--space-xxxxs);
}

.margin-xxxs {
  margin: var(--space-xxxs);
}

.margin-xxs {
  margin: var(--space-xxs);
}

.margin-xs {
  margin: var(--space-xs);
}

.margin-sm {
  margin: var(--space-sm);
}

.margin-md {
  margin: var(--space-md);
}

.margin-lg {
  margin: var(--space-lg);
}

.margin-xl {
  margin: var(--space-xl);
}

.margin-xxl {
  margin: var(--space-xxl);
}

.margin-xxxl {
  margin: var(--space-xxxl);
}

.margin-xxxxl {
  margin: var(--space-xxxxl);
}

.margin-auto {
  margin: auto;
}

.margin-0 {
  margin: 0;
}

.margin-top-xxxxs {
  margin-top: var(--space-xxxxs);
}

.margin-top-xxxs {
  margin-top: var(--space-xxxs);
}

.margin-top-xxs {
  margin-top: var(--space-xxs);
}

.margin-top-xs {
  margin-top: var(--space-xs);
}

.margin-top-sm {
  margin-top: var(--space-sm);
}

.margin-top-md {
  margin-top: var(--space-md);
}

.margin-top-lg {
  margin-top: var(--space-lg);
}

.margin-top-xl {
  margin-top: var(--space-xl);
}

.margin-top-xxl {
  margin-top: var(--space-xxl);
}

.margin-top-xxxl {
  margin-top: var(--space-xxxl);
}

.margin-top-xxxxl {
  margin-top: var(--space-xxxxl);
}

.margin-top-auto {
  margin-top: auto;
}

.margin-top-0 {
  margin-top: 0;
}

.margin-bottom-xxxxs {
  margin-bottom: var(--space-xxxxs);
}

.margin-bottom-xxxs {
  margin-bottom: var(--space-xxxs);
}

.margin-bottom-xxs {
  margin-bottom: var(--space-xxs);
}

.margin-bottom-xs {
  margin-bottom: var(--space-xs);
}

.margin-bottom-sm {
  margin-bottom: var(--space-sm);
}

.margin-bottom-md {
  margin-bottom: var(--space-md);
}

.margin-bottom-lg {
  margin-bottom: var(--space-lg);
}

.margin-bottom-xl {
  margin-bottom: var(--space-xl);
}

.margin-bottom-xxl {
  margin-bottom: var(--space-xxl);
}

.margin-bottom-xxxl {
  margin-bottom: var(--space-xxxl);
}

.margin-bottom-xxxxl {
  margin-bottom: var(--space-xxxxl);
}

.margin-bottom-auto {
  margin-bottom: auto;
}

.margin-bottom-0 {
  margin-bottom: 0;
}

.margin-right-xxxxs {
  margin-right: var(--space-xxxxs);
}

.margin-right-xxxs {
  margin-right: var(--space-xxxs);
}

.margin-right-xxs {
  margin-right: var(--space-xxs);
}

.margin-right-xs {
  margin-right: var(--space-xs);
}

.margin-right-sm {
  margin-right: var(--space-sm);
}

.margin-right-md {
  margin-right: var(--space-md);
}

.margin-right-lg {
  margin-right: var(--space-lg);
}

.margin-right-xl {
  margin-right: var(--space-xl);
}

.margin-right-xxl {
  margin-right: var(--space-xxl);
}

.margin-right-xxxl {
  margin-right: var(--space-xxxl);
}

.margin-right-xxxxl {
  margin-right: var(--space-xxxxl);
}

.margin-right-auto {
  margin-right: auto;
}

.margin-right-0 {
  margin-right: 0;
}

.margin-left-xxxxs {
  margin-left: var(--space-xxxxs);
}

.margin-left-xxxs {
  margin-left: var(--space-xxxs);
}

.margin-left-xxs {
  margin-left: var(--space-xxs);
}

.margin-left-xs {
  margin-left: var(--space-xs);
}

.margin-left-sm {
  margin-left: var(--space-sm);
}

.margin-left-md {
  margin-left: var(--space-md);
}

.margin-left-lg {
  margin-left: var(--space-lg);
}

.margin-left-xl {
  margin-left: var(--space-xl);
}

.margin-left-xxl {
  margin-left: var(--space-xxl);
}

.margin-left-xxxl {
  margin-left: var(--space-xxxl);
}

.margin-left-xxxxl {
  margin-left: var(--space-xxxxl);
}

.margin-left-auto {
  margin-left: auto;
}

.margin-left-0 {
  margin-left: 0;
}

.margin-x-xxxxs {
  margin-left: var(--space-xxxxs);
  margin-right: var(--space-xxxxs);
}

.margin-x-xxxs {
  margin-left: var(--space-xxxs);
  margin-right: var(--space-xxxs);
}

.margin-x-xxs {
  margin-left: var(--space-xxs);
  margin-right: var(--space-xxs);
}

.margin-x-xs {
  margin-left: var(--space-xs);
  margin-right: var(--space-xs);
}

.margin-x-sm {
  margin-left: var(--space-sm);
  margin-right: var(--space-sm);
}

.margin-x-md {
  margin-left: var(--space-md);
  margin-right: var(--space-md);
}

.margin-x-lg {
  margin-left: var(--space-lg);
  margin-right: var(--space-lg);
}

.margin-x-xl {
  margin-left: var(--space-xl);
  margin-right: var(--space-xl);
}

.margin-x-xxl {
  margin-left: var(--space-xxl);
  margin-right: var(--space-xxl);
}

.margin-x-xxxl {
  margin-left: var(--space-xxxl);
  margin-right: var(--space-xxxl);
}

.margin-x-xxxxl {
  margin-left: var(--space-xxxxl);
  margin-right: var(--space-xxxxl);
}

.margin-x-auto {
  margin-left: auto;
  margin-right: auto;
}

.margin-x-0 {
  margin-left: 0;
  margin-right: 0;
}

.margin-y-xxxxs {
  margin-top: var(--space-xxxxs);
  margin-bottom: var(--space-xxxxs);
}

.margin-y-xxxs {
  margin-top: var(--space-xxxs);
  margin-bottom: var(--space-xxxs);
}

.margin-y-xxs {
  margin-top: var(--space-xxs);
  margin-bottom: var(--space-xxs);
}

.margin-y-xs {
  margin-top: var(--space-xs);
  margin-bottom: var(--space-xs);
}

.margin-y-sm {
  margin-top: var(--space-sm);
  margin-bottom: var(--space-sm);
}

.margin-y-md {
  margin-top: var(--space-md);
  margin-bottom: var(--space-md);
}

.margin-y-lg {
  margin-top: var(--space-lg);
  margin-bottom: var(--space-lg);
}

.margin-y-xl {
  margin-top: var(--space-xl);
  margin-bottom: var(--space-xl);
}

.margin-y-xxl {
  margin-top: var(--space-xxl);
  margin-bottom: var(--space-xxl);
}

.margin-y-xxxl {
  margin-top: var(--space-xxxl);
  margin-bottom: var(--space-xxxl);
}

.margin-y-xxxxl {
  margin-top: var(--space-xxxxl);
  margin-bottom: var(--space-xxxxl);
}

.margin-y-auto {
  margin-top: auto;
  margin-bottom: auto;
}

.margin-y-0 {
  margin-top: 0;
  margin-bottom: 0;
}

.padding-xxxxs {
  padding: var(--space-xxxxs);
}

.padding-xxxs {
  padding: var(--space-xxxs);
}

.padding-xxs {
  padding: var(--space-xxs);
}

.padding-xs {
  padding: var(--space-xs);
}

.padding-sm {
  padding: var(--space-sm);
}

.padding-md {
  padding: var(--space-md);
}

.padding-lg {
  padding: var(--space-lg);
}

.padding-xl {
  padding: var(--space-xl);
}

.padding-xxl {
  padding: var(--space-xxl);
}

.padding-xxxl {
  padding: var(--space-xxxl);
}

.padding-xxxxl {
  padding: var(--space-xxxxl);
}

.padding-0 {
  padding: 0;
}

.padding-component {
  padding: var(--component-padding);
}

.padding-top-xxxxs {
  padding-top: var(--space-xxxxs);
}

.padding-top-xxxs {
  padding-top: var(--space-xxxs);
}

.padding-top-xxs {
  padding-top: var(--space-xxs);
}

.padding-top-xs {
  padding-top: var(--space-xs);
}

.padding-top-sm {
  padding-top: var(--space-sm);
}

.padding-top-md {
  padding-top: var(--space-md);
}

.padding-top-lg {
  padding-top: var(--space-lg);
}

.padding-top-xl {
  padding-top: var(--space-xl);
}

.padding-top-xxl {
  padding-top: var(--space-xxl);
}

.padding-top-xxxl {
  padding-top: var(--space-xxxl);
}

.padding-top-xxxxl {
  padding-top: var(--space-xxxxl);
}

.padding-top-0 {
  padding-top: 0;
}

.padding-top-component {
  padding-top: var(--component-padding);
}

.padding-bottom-xxxxs {
  padding-bottom: var(--space-xxxxs);
}

.padding-bottom-xxxs {
  padding-bottom: var(--space-xxxs);
}

.padding-bottom-xxs {
  padding-bottom: var(--space-xxs);
}

.padding-bottom-xs {
  padding-bottom: var(--space-xs);
}

.padding-bottom-sm {
  padding-bottom: var(--space-sm);
}

.padding-bottom-md {
  padding-bottom: var(--space-md);
}

.padding-bottom-lg {
  padding-bottom: var(--space-lg);
}

.padding-bottom-xl {
  padding-bottom: var(--space-xl);
}

.padding-bottom-xxl {
  padding-bottom: var(--space-xxl);
}

.padding-bottom-xxxl {
  padding-bottom: var(--space-xxxl);
}

.padding-bottom-xxxxl {
  padding-bottom: var(--space-xxxxl);
}

.padding-bottom-0 {
  padding-bottom: 0;
}

.padding-bottom-component {
  padding-bottom: var(--component-padding);
}

.padding-right-xxxxs {
  padding-right: var(--space-xxxxs);
}

.padding-right-xxxs {
  padding-right: var(--space-xxxs);
}

.padding-right-xxs {
  padding-right: var(--space-xxs);
}

.padding-right-xs {
  padding-right: var(--space-xs);
}

.padding-right-sm {
  padding-right: var(--space-sm);
}

.padding-right-md {
  padding-right: var(--space-md);
}

.padding-right-lg {
  padding-right: var(--space-lg);
}

.padding-right-xl {
  padding-right: var(--space-xl);
}

.padding-right-xxl {
  padding-right: var(--space-xxl);
}

.padding-right-xxxl {
  padding-right: var(--space-xxxl);
}

.padding-right-xxxxl {
  padding-right: var(--space-xxxxl);
}

.padding-right-0 {
  padding-right: 0;
}

.padding-right-component {
  padding-right: var(--component-padding);
}

.padding-left-xxxxs {
  padding-left: var(--space-xxxxs);
}

.padding-left-xxxs {
  padding-left: var(--space-xxxs);
}

.padding-left-xxs {
  padding-left: var(--space-xxs);
}

.padding-left-xs {
  padding-left: var(--space-xs);
}

.padding-left-sm {
  padding-left: var(--space-sm);
}

.padding-left-md {
  padding-left: var(--space-md);
}

.padding-left-lg {
  padding-left: var(--space-lg);
}

.padding-left-xl {
  padding-left: var(--space-xl);
}

.padding-left-xxl {
  padding-left: var(--space-xxl);
}

.padding-left-xxxl {
  padding-left: var(--space-xxxl);
}

.padding-left-xxxxl {
  padding-left: var(--space-xxxxl);
}

.padding-left-0 {
  padding-left: 0;
}

.padding-left-component {
  padding-left: var(--component-padding);
}

.padding-x-xxxxs {
  padding-left: var(--space-xxxxs);
  padding-right: var(--space-xxxxs);
}

.padding-x-xxxs {
  padding-left: var(--space-xxxs);
  padding-right: var(--space-xxxs);
}

.padding-x-xxs {
  padding-left: var(--space-xxs);
  padding-right: var(--space-xxs);
}

.padding-x-xs {
  padding-left: var(--space-xs);
  padding-right: var(--space-xs);
}

.padding-x-sm {
  padding-left: var(--space-sm);
  padding-right: var(--space-sm);
}

.padding-x-md {
  padding-left: var(--space-md);
  padding-right: var(--space-md);
}

.padding-x-lg {
  padding-left: var(--space-lg);
  padding-right: var(--space-lg);
}

.padding-x-xl {
  padding-left: var(--space-xl);
  padding-right: var(--space-xl);
}

.padding-x-xxl {
  padding-left: var(--space-xxl);
  padding-right: var(--space-xxl);
}

.padding-x-xxxl {
  padding-left: var(--space-xxxl);
  padding-right: var(--space-xxxl);
}

.padding-x-xxxxl {
  padding-left: var(--space-xxxxl);
  padding-right: var(--space-xxxxl);
}

.padding-x-0 {
  padding-left: 0;
  padding-right: 0;
}

.padding-x-component {
  padding-left: var(--component-padding);
  padding-right: var(--component-padding);
}

.padding-y-xxxxs {
  padding-top: var(--space-xxxxs);
  padding-bottom: var(--space-xxxxs);
}

.padding-y-xxxs {
  padding-top: var(--space-xxxs);
  padding-bottom: var(--space-xxxs);
}

.padding-y-xxs {
  padding-top: var(--space-xxs);
  padding-bottom: var(--space-xxs);
}

.padding-y-xs {
  padding-top: var(--space-xs);
  padding-bottom: var(--space-xs);
}

.padding-y-sm {
  padding-top: var(--space-sm);
  padding-bottom: var(--space-sm);
}

.padding-y-md {
  padding-top: var(--space-md);
  padding-bottom: var(--space-md);
}

.padding-y-lg {
  padding-top: var(--space-lg);
  padding-bottom: var(--space-lg);
}

.padding-y-xl {
  padding-top: var(--space-xl);
  padding-bottom: var(--space-xl);
}

.padding-y-xxl {
  padding-top: var(--space-xxl);
  padding-bottom: var(--space-xxl);
}

.padding-y-xxxl {
  padding-top: var(--space-xxxl);
  padding-bottom: var(--space-xxxl);
}

.padding-y-xxxxl {
  padding-top: var(--space-xxxxl);
  padding-bottom: var(--space-xxxxl);
}

.padding-y-0 {
  padding-top: 0;
  padding-bottom: 0;
}

.padding-y-component {
  padding-top: var(--component-padding);
  padding-bottom: var(--component-padding);
}

.align-baseline {
  vertical-align: baseline;
}

.align-top {
  vertical-align: top;
}

.align-middle {
  vertical-align: middle;
}

.align-bottom {
  vertical-align: bottom;
}

.truncate,
.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.text-replace {
  overflow: hidden;
  color: transparent;
  text-indent: 100%;
  white-space: nowrap;
}

.text-xs {
  font-size: var(--text-xs, 0.694em);
}

.text-sm {
  font-size: var(--text-sm, 0.833em);
}

.text-base {
  font-size: 1em;
}

.text-md {
  font-size: var(--text-md, 1.2em);
}

.text-lg {
  font-size: var(--text-lg, 1.44em);
}

.text-xl {
  font-size: var(--text-xl, 1.728em);
}

.text-xxl {
  font-size: var(--text-xxl, 2.074em);
}

.text-xxxl {
  font-size: var(--text-xxxl, 2.488em);
}

.text-xxxxl {
  font-size: var(--text-xxxxl, 2.985em);
}

.text-uppercase {
  text-transform: uppercase;
}

.text-capitalize {
  text-transform: capitalize;
}

.letter-spacing-sm {
  letter-spacing: -0.05em;
}

.letter-spacing-md {
  letter-spacing: 0.05em;
}

.letter-spacing-lg {
  letter-spacing: 0.1em;
}

.font-light {
  font-weight: 300;
}

.font-normal {
  font-weight: 400;
}

.font-medium {
  font-weight: 500;
}

.font-semibold {
  font-weight: 600;
}

.font-bold,
.text-bold {
  font-weight: 700;
}

.font-italic {
  font-style: italic;
}

.font-smooth {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.font-primary {
  font-family: var(--font-primary);
}

.text-center {
  text-align: center;
}

.text-left {
  text-align: left;
}

.text-right {
  text-align: right;
}

.text-justify {
  text-align: justify;
}

.text-line-through {
  text-decoration: line-through;
}

.text-underline {
  text-decoration: underline;
}

.text-decoration-none {
  text-decoration: none;
}

.v-space-xxs {
  --text-vspace-multiplier: 0.25 !important;
}

.v-space-xs {
  --text-vspace-multiplier: 0.5 !important;
}

.v-space-sm {
  --text-vspace-multiplier: 0.75 !important;
}

.v-space-md {
  --text-vspace-multiplier: 1.25 !important;
}

.v-space-lg {
  --text-vspace-multiplier: 1.5 !important;
}

.v-space-xl {
  --text-vspace-multiplier: 1.75 !important;
}

.v-space-xxl {
  --text-vspace-multiplier: 2 !important;
}

.line-height-xs {
  --heading-line-height: 1;
  --body-line-height: 1;
}

.line-height-xs:not(.text-component) {
  line-height: 1;
}

.line-height-sm {
  --heading-line-height: 1.1;
  --body-line-height: 1.2;
}

.line-height-sm:not(.text-component) {
  line-height: 1.2;
}

.line-height-md {
  --heading-line-height: 1.15;
  --body-line-height: 1.4;
}

.line-height-md:not(.text-component) {
  line-height: 1.4;
}

.line-height-lg {
  --heading-line-height: 1.22;
  --body-line-height: 1.58;
}

.line-height-lg:not(.text-component) {
  line-height: 1.58;
}

.line-height-xl {
  --heading-line-height: 1.3;
  --body-line-height: 1.72;
}

.line-height-xl:not(.text-component) {
  line-height: 1.72;
}

.line-height-body {
  line-height: var(--body-line-height);
}

.line-height-heading {
  line-height: var(--heading-line-height);
}

.ws-nowrap,
.text-nowrap {
  white-space: nowrap;
}

.cursor-pointer {
  cursor: pointer;
}

.cursor-default {
  cursor: default;
}

.pointer-events-auto {
  pointer-events: auto;
}

.pointer-events-none {
  pointer-events: none;
}

.user-select-none {
  user-select: none;
}

.user-select-all {
  user-select: all;
}

[class*=color-] {
  --color-o: 1;
}

.color-inherit {
  color: inherit;
}

.color-bg {
  color: hsla(var(--color-bg-h), var(--color-bg-s), var(--color-bg-l), var(--color-o, 1));
}

.color-contrast-lower {
  color: hsla(var(--color-contrast-lower-h), var(--color-contrast-lower-s), var(--color-contrast-lower-l), var(--color-o, 1));
}

.color-contrast-low {
  color: hsla(var(--color-contrast-low-h), var(--color-contrast-low-s), var(--color-contrast-low-l), var(--color-o, 1));
}

.color-contrast-medium {
  color: hsla(var(--color-contrast-medium-h), var(--color-contrast-medium-s), var(--color-contrast-medium-l), var(--color-o, 1));
}

.color-contrast-high {
  color: hsla(var(--color-contrast-high-h), var(--color-contrast-high-s), var(--color-contrast-high-l), var(--color-o, 1));
}

.color-contrast-higher {
  color: hsla(var(--color-contrast-higher-h), var(--color-contrast-higher-s), var(--color-contrast-higher-l), var(--color-o, 1));
}

.color-primary-darker {
  color: hsla(var(--color-primary-darker-h), var(--color-primary-darker-s), var(--color-primary-darker-l), var(--color-o, 1));
}

.color-primary-dark {
  color: hsla(var(--color-primary-dark-h), var(--color-primary-dark-s), var(--color-primary-dark-l), var(--color-o, 1));
}

.color-primary {
  color: hsla(var(--color-primary-h), var(--color-primary-s), var(--color-primary-l), var(--color-o, 1));
}

.color-primary-light {
  color: hsla(var(--color-primary-light-h), var(--color-primary-light-s), var(--color-primary-light-l), var(--color-o, 1));
}

.color-primary-lighter {
  color: hsla(var(--color-primary-lighter-h), var(--color-primary-lighter-s), var(--color-primary-lighter-l), var(--color-o, 1));
}

.color-accent-darker {
  color: hsla(var(--color-accent-darker-h), var(--color-accent-darker-s), var(--color-accent-darker-l), var(--color-o, 1));
}

.color-accent-dark {
  color: hsla(var(--color-accent-dark-h), var(--color-accent-dark-s), var(--color-accent-dark-l), var(--color-o, 1));
}

.color-accent {
  color: hsla(var(--color-accent-h), var(--color-accent-s), var(--color-accent-l), var(--color-o, 1));
}

.color-accent-light {
  color: hsla(var(--color-accent-light-h), var(--color-accent-light-s), var(--color-accent-light-l), var(--color-o, 1));
}

.color-accent-lighter {
  color: hsla(var(--color-accent-lighter-h), var(--color-accent-lighter-s), var(--color-accent-lighter-l), var(--color-o, 1));
}

.color-success-darker {
  color: hsla(var(--color-success-darker-h), var(--color-success-darker-s), var(--color-success-darker-l), var(--color-o, 1));
}

.color-success-dark {
  color: hsla(var(--color-success-dark-h), var(--color-success-dark-s), var(--color-success-dark-l), var(--color-o, 1));
}

.color-success {
  color: hsla(var(--color-success-h), var(--color-success-s), var(--color-success-l), var(--color-o, 1));
}

.color-success-light {
  color: hsla(var(--color-success-light-h), var(--color-success-light-s), var(--color-success-light-l), var(--color-o, 1));
}

.color-success-lighter {
  color: hsla(var(--color-success-lighter-h), var(--color-success-lighter-s), var(--color-success-lighter-l), var(--color-o, 1));
}

.color-warning-darker {
  color: hsla(var(--color-warning-darker-h), var(--color-warning-darker-s), var(--color-warning-darker-l), var(--color-o, 1));
}

.color-warning-dark {
  color: hsla(var(--color-warning-dark-h), var(--color-warning-dark-s), var(--color-warning-dark-l), var(--color-o, 1));
}

.color-warning {
  color: hsla(var(--color-warning-h), var(--color-warning-s), var(--color-warning-l), var(--color-o, 1));
}

.color-warning-light {
  color: hsla(var(--color-warning-light-h), var(--color-warning-light-s), var(--color-warning-light-l), var(--color-o, 1));
}

.color-warning-lighter {
  color: hsla(var(--color-warning-lighter-h), var(--color-warning-lighter-s), var(--color-warning-lighter-l), var(--color-o, 1));
}

.color-error-darker {
  color: hsla(var(--color-error-darker-h), var(--color-error-darker-s), var(--color-error-darker-l), var(--color-o, 1));
}

.color-error-dark {
  color: hsla(var(--color-error-dark-h), var(--color-error-dark-s), var(--color-error-dark-l), var(--color-o, 1));
}

.color-error {
  color: hsla(var(--color-error-h), var(--color-error-s), var(--color-error-l), var(--color-o, 1));
}

.color-error-light {
  color: hsla(var(--color-error-light-h), var(--color-error-light-s), var(--color-error-light-l), var(--color-o, 1));
}

.color-error-lighter {
  color: hsla(var(--color-error-lighter-h), var(--color-error-lighter-s), var(--color-error-lighter-l), var(--color-o, 1));
}

.color-white {
  color: hsla(var(--color-white-h), var(--color-white-s), var(--color-white-l), var(--color-o, 1));
}

.color-black {
  color: hsla(var(--color-black-h), var(--color-black-s), var(--color-black-l), var(--color-o, 1));
}

@supports (--css: variables) {
  .color-opacity-0 {
    --color-o: 0;
  }

  .color-opacity-10\% {
    --color-o: 0.1;
  }

  .color-opacity-20\% {
    --color-o: 0.2;
  }

  .color-opacity-30\% {
    --color-o: 0.3;
  }

  .color-opacity-40\% {
    --color-o: 0.4;
  }

  .color-opacity-50\% {
    --color-o: 0.5;
  }

  .color-opacity-60\% {
    --color-o: 0.6;
  }

  .color-opacity-70\% {
    --color-o: 0.7;
  }

  .color-opacity-80\% {
    --color-o: 0.8;
  }

  .color-opacity-90\% {
    --color-o: 0.9;
  }
}

.width-xxxxs {
  width: var(--size-xxxxs, 0.25rem);
}

.width-xxxs {
  width: var(--size-xxxs, 0.5rem);
}

.width-xxs {
  width: var(--size-xxs, 0.75rem);
}

.width-xs {
  width: var(--size-xs, 1rem);
}

.width-sm {
  width: var(--size-sm, 1.5rem);
}

.width-md {
  width: var(--size-md, 2rem);
}

.width-lg {
  width: var(--size-lg, 3rem);
}

.width-xl {
  width: var(--size-xl, 4rem);
}

.width-xxl {
  width: var(--size-xxl, 6rem);
}

.width-xxxl {
  width: var(--size-xxxl, 8rem);
}

.width-xxxxl {
  width: var(--size-xxxxl, 16rem);
}

.width-0 {
  width: 0;
}

.width-10\% {
  width: 10%;
}

.width-20\% {
  width: 20%;
}

.width-25\% {
  width: 25%;
}

.width-30\% {
  width: 30%;
}

.width-33\% {
  width: calc(100% / 3);
}

.width-40\% {
  width: 40%;
}

.width-50\% {
  width: 50%;
}

.width-60\% {
  width: 60%;
}

.width-66\% {
  width: calc(100% / 1.5);
}

.width-70\% {
  width: 70%;
}

.width-75\% {
  width: 75%;
}

.width-80\% {
  width: 80%;
}

.width-90\% {
  width: 90%;
}

.width-100\% {
  width: 100%;
}

.width-100vw {
  width: 100vw;
}

.width-auto {
  width: auto;
}

.height-xxxxs {
  height: var(--size-xxxxs, 0.25rem);
}

.height-xxxs {
  height: var(--size-xxxs, 0.5rem);
}

.height-xxs {
  height: var(--size-xxs, 0.75rem);
}

.height-xs {
  height: var(--size-xs, 1rem);
}

.height-sm {
  height: var(--size-sm, 1.5rem);
}

.height-md {
  height: var(--size-md, 2rem);
}

.height-lg {
  height: var(--size-lg, 3rem);
}

.height-xl {
  height: var(--size-xl, 4rem);
}

.height-xxl {
  height: var(--size-xxl, 6rem);
}

.height-xxxl {
  height: var(--size-xxxl, 8rem);
}

.height-xxxxl {
  height: var(--size-xxxxl, 16rem);
}

.height-0 {
  height: 0;
}

.height-10\% {
  height: 10%;
}

.height-20\% {
  height: 20%;
}

.height-25\% {
  height: 25%;
}

.height-30\% {
  height: 30%;
}

.height-33\% {
  height: calc(100% / 3);
}

.height-40\% {
  height: 40%;
}

.height-50\% {
  height: 50%;
}

.height-60\% {
  height: 60%;
}

.height-66\% {
  height: calc(100% / 1.5);
}

.height-70\% {
  height: 70%;
}

.height-75\% {
  height: 75%;
}

.height-80\% {
  height: 80%;
}

.height-90\% {
  height: 90%;
}

.height-100\% {
  height: 100%;
}

.height-100vh {
  height: 100vh;
}

.height-auto {
  height: auto;
}

.min-width-0 {
  min-width: 0;
}

.min-width-25\% {
  min-width: 25%;
}

.min-width-33\% {
  min-width: calc(100% / 3);
}

.min-width-50\% {
  min-width: 50%;
}

.min-width-66\% {
  min-width: calc(100% / 1.5);
}

.min-width-75\% {
  min-width: 75%;
}

.min-width-100\% {
  min-width: 100%;
}

.min-width-100vw {
  min-width: 100vw;
}

.min-height-100\% {
  min-height: 100%;
}

.min-height-100vh {
  min-height: 100vh;
}

:root {
  --max-width-xxxxs: 20rem;
  --max-width-xxxs: 26rem;
  --max-width-xxs: 32rem;
  --max-width-xs: 38rem;
  --max-width-sm: 48rem;
  --max-width-md: 64rem;
  --max-width-lg: 80rem;
  --max-width-xl: 90rem;
  --max-width-xxl: 100rem;
  --max-width-xxxl: 120rem;
  --max-width-xxxxl: 150rem;
}

.max-width-xxxxs {
  max-width: var(--max-width-xxxxs);
}

.max-width-xxxs {
  max-width: var(--max-width-xxxs);
}

.max-width-xxs {
  max-width: var(--max-width-xxs);
}

.max-width-xs {
  max-width: var(--max-width-xs);
}

.max-width-sm {
  max-width: var(--max-width-sm);
}

.max-width-md {
  max-width: var(--max-width-md);
}

.max-width-lg {
  max-width: var(--max-width-lg);
}

.max-width-xl {
  max-width: var(--max-width-xl);
}

.max-width-xxl {
  max-width: var(--max-width-xxl);
}

.max-width-xxxl {
  max-width: var(--max-width-xxxl);
}

.max-width-xxxxl {
  max-width: var(--max-width-xxxxl);
}

.max-width-100\% {
  max-width: 100%;
}

[class*=max-width-adaptive] {
  max-width: 32rem;
}

@media (min-width: 48rem) {
  .max-width-adaptive-sm,
  .max-width-adaptive-md,
  .max-width-adaptive-lg,
  .max-width-adaptive-xl,
  .max-width-adaptive {
    max-width: 48rem;
  }
}

@media (min-width: 64rem) {
  .max-width-adaptive-md,
  .max-width-adaptive-lg,
  .max-width-adaptive-xl,
  .max-width-adaptive {
    max-width: 64rem;
  }
}

@media (min-width: 80rem) {
  .max-width-adaptive-lg,
  .max-width-adaptive-xl,
  .max-width-adaptive {
    max-width: 80rem;
  }
}

@media (min-width: 90rem) {
  .max-width-adaptive-xl {
    max-width: 90rem;
  }
}

.max-height-100\% {
  max-height: 100%;
}

.max-height-100vh {
  max-height: 100vh;
}

.position-relative {
  position: relative;
}

.position-absolute {
  position: absolute;
}

.position-fixed {
  position: fixed;
}

.position-sticky {
  position: sticky;
}

.top-0 {
  top: 0;
}

.bottom-0 {
  bottom: 0;
}

.left-0 {
  left: 0;
}

.right-0 {
  right: 0;
}

.z-index-header {
  z-index: var(--zindex-header);
}

.z-index-popover {
  z-index: var(--zindex-popover);
}

.z-index-fixed-element {
  z-index: var(--zindex-fixed-element);
}

.z-index-overlay {
  z-index: var(--zindex-overlay);
}

.zindex-1,
.z-index-1 {
  z-index: 1;
}

.zindex-2,
.z-index-2 {
  z-index: 2;
}

.zindex-3,
.z-index-3 {
  z-index: 3;
}

.overflow-hidden {
  overflow: hidden;
}

.overflow-auto {
  overflow: auto;
}

.momentum-scrolling {
  -webkit-overflow-scrolling: touch;
}

.overscroll-contain {
  overscroll-behavior: contain;
}

.opacity-0 {
  opacity: 0;
}

.opacity-10\% {
  opacity: 0.1;
}

.opacity-20\% {
  opacity: 0.2;
}

.opacity-30\% {
  opacity: 0.3;
}

.opacity-40\% {
  opacity: 0.4;
}

.opacity-50\% {
  opacity: 0.5;
}

.opacity-60\% {
  opacity: 0.6;
}

.opacity-70\% {
  opacity: 0.7;
}

.opacity-80\% {
  opacity: 0.8;
}

.opacity-90\% {
  opacity: 0.9;
}

.media-wrapper {
  position: relative;
  height: 0;
  padding-bottom: 56.25%;
}

.media-wrapper > * {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.media-wrapper--4\:3 {
  padding-bottom: calc((3 / 4) * 100%);
}

.media-wrapper--1\:1 {
  padding-bottom: calc((1 / 1) * 100%);
}

.float-left {
  float: left;
}

.float-right {
  float: right;
}

.clearfix::after {
  content: "";
  display: table;
  clear: both;
}

[class*=border-] {
  --border-o: 1;
}

.border {
  border: 1px solid hsla(var(--color-contrast-low-h), var(--color-contrast-low-s), var(--color-contrast-low-l), var(--border-o, 1));
}

.border-top {
  border-top: 1px solid hsla(var(--color-contrast-low-h), var(--color-contrast-low-s), var(--color-contrast-low-l), var(--border-o, 1));
}

.border-bottom {
  border-bottom: 1px solid hsla(var(--color-contrast-low-h), var(--color-contrast-low-s), var(--color-contrast-low-l), var(--border-o, 1));
}

.border-left {
  border-left: 1px solid hsla(var(--color-contrast-low-h), var(--color-contrast-low-s), var(--color-contrast-low-l), var(--border-o, 1));
}

.border-right {
  border-right: 1px solid hsla(var(--color-contrast-low-h), var(--color-contrast-low-s), var(--color-contrast-low-l), var(--border-o, 1));
}

.border-2 {
  border-width: 2px;
}

.border-3 {
  border-width: 3px;
}

.border-4 {
  border-width: 4px;
}

.border-bg {
  border-color: hsla(var(--color-bg-h), var(--color-bg-s), var(--color-bg-l), var(--border-o, 1));
}

.border-contrast-lower {
  border-color: hsla(var(--color-contrast-lower-h), var(--color-contrast-lower-s), var(--color-contrast-lower-l), var(--border-o, 1));
}

.border-contrast-low {
  border-color: hsla(var(--color-contrast-low-h), var(--color-contrast-low-s), var(--color-contrast-low-l), var(--border-o, 1));
}

.border-contrast-medium {
  border-color: hsla(var(--color-contrast-medium-h), var(--color-contrast-medium-s), var(--color-contrast-medium-l), var(--border-o, 1));
}

.border-contrast-high {
  border-color: hsla(var(--color-contrast-high-h), var(--color-contrast-high-s), var(--color-contrast-high-l), var(--border-o, 1));
}

.border-contrast-higher {
  border-color: hsla(var(--color-contrast-higher-h), var(--color-contrast-higher-s), var(--color-contrast-higher-l), var(--border-o, 1));
}

.border-primary-darker {
  border-color: hsla(var(--color-primary-darker-h), var(--color-primary-darker-s), var(--color-primary-darker-l), var(--border-o, 1));
}

.border-primary-dark {
  border-color: hsla(var(--color-primary-dark-h), var(--color-primary-dark-s), var(--color-primary-dark-l), var(--border-o, 1));
}

.border-primary {
  border-color: hsla(var(--color-primary-h), var(--color-primary-s), var(--color-primary-l), var(--border-o, 1));
}

.border-primary-light {
  border-color: hsla(var(--color-primary-light-h), var(--color-primary-light-s), var(--color-primary-light-l), var(--border-o, 1));
}

.border-primary-lighter {
  border-color: hsla(var(--color-primary-lighter-h), var(--color-primary-lighter-s), var(--color-primary-lighter-l), var(--border-o, 1));
}

.border-accent-darker {
  border-color: hsla(var(--color-accent-darker-h), var(--color-accent-darker-s), var(--color-accent-darker-l), var(--border-o, 1));
}

.border-accent-dark {
  border-color: hsla(var(--color-accent-dark-h), var(--color-accent-dark-s), var(--color-accent-dark-l), var(--border-o, 1));
}

.border-accent {
  border-color: hsla(var(--color-accent-h), var(--color-accent-s), var(--color-accent-l), var(--border-o, 1));
}

.border-accent-light {
  border-color: hsla(var(--color-accent-light-h), var(--color-accent-light-s), var(--color-accent-light-l), var(--border-o, 1));
}

.border-accent-lighter {
  border-color: hsla(var(--color-accent-lighter-h), var(--color-accent-lighter-s), var(--color-accent-lighter-l), var(--border-o, 1));
}

.border-success-darker {
  border-color: hsla(var(--color-success-darker-h), var(--color-success-darker-s), var(--color-success-darker-l), var(--border-o, 1));
}

.border-success-dark {
  border-color: hsla(var(--color-success-dark-h), var(--color-success-dark-s), var(--color-success-dark-l), var(--border-o, 1));
}

.border-success {
  border-color: hsla(var(--color-success-h), var(--color-success-s), var(--color-success-l), var(--border-o, 1));
}

.border-success-light {
  border-color: hsla(var(--color-success-light-h), var(--color-success-light-s), var(--color-success-light-l), var(--border-o, 1));
}

.border-success-lighter {
  border-color: hsla(var(--color-success-lighter-h), var(--color-success-lighter-s), var(--color-success-lighter-l), var(--border-o, 1));
}

.border-warning-darker {
  border-color: hsla(var(--color-warning-darker-h), var(--color-warning-darker-s), var(--color-warning-darker-l), var(--border-o, 1));
}

.border-warning-dark {
  border-color: hsla(var(--color-warning-dark-h), var(--color-warning-dark-s), var(--color-warning-dark-l), var(--border-o, 1));
}

.border-warning {
  border-color: hsla(var(--color-warning-h), var(--color-warning-s), var(--color-warning-l), var(--border-o, 1));
}

.border-warning-light {
  border-color: hsla(var(--color-warning-light-h), var(--color-warning-light-s), var(--color-warning-light-l), var(--border-o, 1));
}

.border-warning-lighter {
  border-color: hsla(var(--color-warning-lighter-h), var(--color-warning-lighter-s), var(--color-warning-lighter-l), var(--border-o, 1));
}

.border-error-darker {
  border-color: hsla(var(--color-error-darker-h), var(--color-error-darker-s), var(--color-error-darker-l), var(--border-o, 1));
}

.border-error-dark {
  border-color: hsla(var(--color-error-dark-h), var(--color-error-dark-s), var(--color-error-dark-l), var(--border-o, 1));
}

.border-error {
  border-color: hsla(var(--color-error-h), var(--color-error-s), var(--color-error-l), var(--border-o, 1));
}

.border-error-light {
  border-color: hsla(var(--color-error-light-h), var(--color-error-light-s), var(--color-error-light-l), var(--border-o, 1));
}

.border-error-lighter {
  border-color: hsla(var(--color-error-lighter-h), var(--color-error-lighter-s), var(--color-error-lighter-l), var(--border-o, 1));
}

.border-white {
  border-color: hsla(var(--color-white-h), var(--color-white-s), var(--color-white-l), var(--border-o, 1));
}

.border-black {
  border-color: hsla(var(--color-black-h), var(--color-black-s), var(--color-black-l), var(--border-o, 1));
}

@supports (--css: variables) {
  .border-opacity-0 {
    --border-o: 0;
  }

  .border-opacity-10\% {
    --border-o: 0.1;
  }

  .border-opacity-20\% {
    --border-o: 0.2;
  }

  .border-opacity-30\% {
    --border-o: 0.3;
  }

  .border-opacity-40\% {
    --border-o: 0.4;
  }

  .border-opacity-50\% {
    --border-o: 0.5;
  }

  .border-opacity-60\% {
    --border-o: 0.6;
  }

  .border-opacity-70\% {
    --border-o: 0.7;
  }

  .border-opacity-80\% {
    --border-o: 0.8;
  }

  .border-opacity-90\% {
    --border-o: 0.9;
  }
}

.radius-sm {
  border-radius: var(--radius-sm);
}

.radius-md {
  border-radius: var(--radius-md);
}

.radius-lg {
  border-radius: var(--radius-lg);
}

.radius-50\% {
  border-radius: 50%;
}

.radius-full {
  border-radius: 50em;
}

.radius-top-left-0 {
  border-top-left-radius: 0;
}

.radius-top-right-0 {
  border-top-right-radius: 0;
}

.radius-bottom-right-0 {
  border-bottom-right-radius: 0;
}

.radius-bottom-left-0 {
  border-bottom-left-radius: 0;
}

.shadow-xs {
  box-shadow: var(--shadow-xs);
}

.shadow-sm {
  box-shadow: var(--shadow-sm);
}

.shadow-md {
  box-shadow: var(--shadow-md);
}

.shadow-lg {
  box-shadow: var(--shadow-lg);
}

.shadow-xl {
  box-shadow: var(--shadow-xl);
}

.bg,
[class*=bg-] {
  --bg-o: 1;
}

.bg-transparent {
  background-color: transparent;
}

.bg-inherit {
  background-color: inherit;
}

.bg {
  background-color: hsla(var(--color-bg-h), var(--color-bg-s), var(--color-bg-l), var(--bg-o));
}

.bg-contrast-lower {
  background-color: hsla(var(--color-contrast-lower-h), var(--color-contrast-lower-s), var(--color-contrast-lower-l), var(--bg-o, 1));
}

.bg-contrast-low {
  background-color: hsla(var(--color-contrast-low-h), var(--color-contrast-low-s), var(--color-contrast-low-l), var(--bg-o, 1));
}

.bg-contrast-medium {
  background-color: hsla(var(--color-contrast-medium-h), var(--color-contrast-medium-s), var(--color-contrast-medium-l), var(--bg-o, 1));
}

.bg-contrast-high {
  background-color: hsla(var(--color-contrast-high-h), var(--color-contrast-high-s), var(--color-contrast-high-l), var(--bg-o, 1));
}

.bg-contrast-higher {
  background-color: hsla(var(--color-contrast-higher-h), var(--color-contrast-higher-s), var(--color-contrast-higher-l), var(--bg-o, 1));
}

.bg-primary-darker {
  background-color: hsla(var(--color-primary-darker-h), var(--color-primary-darker-s), var(--color-primary-darker-l), var(--bg-o, 1));
}

.bg-primary-dark {
  background-color: hsla(var(--color-primary-dark-h), var(--color-primary-dark-s), var(--color-primary-dark-l), var(--bg-o, 1));
}

.bg-primary {
  background-color: hsla(var(--color-primary-h), var(--color-primary-s), var(--color-primary-l), var(--bg-o, 1));
}

.bg-primary-light {
  background-color: hsla(var(--color-primary-light-h), var(--color-primary-light-s), var(--color-primary-light-l), var(--bg-o, 1));
}

.bg-primary-lighter {
  background-color: hsla(var(--color-primary-lighter-h), var(--color-primary-lighter-s), var(--color-primary-lighter-l), var(--bg-o, 1));
}

.bg-accent-darker {
  background-color: hsla(var(--color-accent-darker-h), var(--color-accent-darker-s), var(--color-accent-darker-l), var(--bg-o, 1));
}

.bg-accent-dark {
  background-color: hsla(var(--color-accent-dark-h), var(--color-accent-dark-s), var(--color-accent-dark-l), var(--bg-o, 1));
}

.bg-accent {
  background-color: hsla(var(--color-accent-h), var(--color-accent-s), var(--color-accent-l), var(--bg-o, 1));
}

.bg-accent-light {
  background-color: hsla(var(--color-accent-light-h), var(--color-accent-light-s), var(--color-accent-light-l), var(--bg-o, 1));
}

.bg-accent-lighter {
  background-color: hsla(var(--color-accent-lighter-h), var(--color-accent-lighter-s), var(--color-accent-lighter-l), var(--bg-o, 1));
}

.bg-success-darker {
  background-color: hsla(var(--color-success-darker-h), var(--color-success-darker-s), var(--color-success-darker-l), var(--bg-o, 1));
}

.bg-success-dark {
  background-color: hsla(var(--color-success-dark-h), var(--color-success-dark-s), var(--color-success-dark-l), var(--bg-o, 1));
}

.bg-success {
  background-color: hsla(var(--color-success-h), var(--color-success-s), var(--color-success-l), var(--bg-o, 1));
}

.bg-success-light {
  background-color: hsla(var(--color-success-light-h), var(--color-success-light-s), var(--color-success-light-l), var(--bg-o, 1));
}

.bg-success-lighter {
  background-color: hsla(var(--color-success-lighter-h), var(--color-success-lighter-s), var(--color-success-lighter-l), var(--bg-o, 1));
}

.bg-warning-darker {
  background-color: hsla(var(--color-warning-darker-h), var(--color-warning-darker-s), var(--color-warning-darker-l), var(--bg-o, 1));
}

.bg-warning-dark {
  background-color: hsla(var(--color-warning-dark-h), var(--color-warning-dark-s), var(--color-warning-dark-l), var(--bg-o, 1));
}

.bg-warning {
  background-color: hsla(var(--color-warning-h), var(--color-warning-s), var(--color-warning-l), var(--bg-o, 1));
}

.bg-warning-light {
  background-color: hsla(var(--color-warning-light-h), var(--color-warning-light-s), var(--color-warning-light-l), var(--bg-o, 1));
}

.bg-warning-lighter {
  background-color: hsla(var(--color-warning-lighter-h), var(--color-warning-lighter-s), var(--color-warning-lighter-l), var(--bg-o, 1));
}

.bg-error-darker {
  background-color: hsla(var(--color-error-darker-h), var(--color-error-darker-s), var(--color-error-darker-l), var(--bg-o, 1));
}

.bg-error-dark {
  background-color: hsla(var(--color-error-dark-h), var(--color-error-dark-s), var(--color-error-dark-l), var(--bg-o, 1));
}

.bg-error {
  background-color: hsla(var(--color-error-h), var(--color-error-s), var(--color-error-l), var(--bg-o, 1));
}

.bg-error-light {
  background-color: hsla(var(--color-error-light-h), var(--color-error-light-s), var(--color-error-light-l), var(--bg-o, 1));
}

.bg-error-lighter {
  background-color: hsla(var(--color-error-lighter-h), var(--color-error-lighter-s), var(--color-error-lighter-l), var(--bg-o, 1));
}

.bg-white {
  background-color: hsla(var(--color-white-h), var(--color-white-s), var(--color-white-l), var(--bg-o, 1));
}

.bg-black {
  background-color: hsla(var(--color-black-h), var(--color-black-s), var(--color-black-l), var(--bg-o, 1));
}

@supports (--css: variables) {
  .bg-opacity-0 {
    --bg-o: 0;
  }

  .bg-opacity-10\% {
    --bg-o: 0.1;
  }

  .bg-opacity-20\% {
    --bg-o: 0.2;
  }

  .bg-opacity-30\% {
    --bg-o: 0.3;
  }

  .bg-opacity-40\% {
    --bg-o: 0.4;
  }

  .bg-opacity-50\% {
    --bg-o: 0.5;
  }

  .bg-opacity-60\% {
    --bg-o: 0.6;
  }

  .bg-opacity-70\% {
    --bg-o: 0.7;
  }

  .bg-opacity-80\% {
    --bg-o: 0.8;
  }

  .bg-opacity-90\% {
    --bg-o: 0.9;
  }
}

.bg-cover {
  background-size: cover;
}

.bg-center {
  background-position: center;
}

.bg-no-repeat {
  background-repeat: no-repeat;
}

.backdrop-blur-10 {
  backdrop-filter: blur(10px);
}

.backdrop-blur-20 {
  backdrop-filter: blur(20px);
}

.isolate {
  isolation: isolate;
}

.blend-multiply {
  mix-blend-mode: multiply;
}

.blend-overlay {
  mix-blend-mode: overlay;
}

.object-contain {
  object-fit: contain;
}

.object-cover {
  object-fit: cover;
}

.flip {
  transform: scale(-1);
}

.flip-x {
  transform: scaleX(-1);
}

.flip-y {
  transform: scaleY(-1);
}

@media (min-width: 32rem) {
  .flex\@xs {
    display: flex;
  }

  .inline-flex\@xs {
    display: inline-flex;
  }

  .flex-wrap\@xs {
    flex-wrap: wrap;
  }

  .flex-column\@xs {
    flex-direction: column;
  }

  .flex-column-reverse\@xs {
    flex-direction: column-reverse;
  }

  .flex-row\@xs {
    flex-direction: row;
  }

  .flex-row-reverse\@xs {
    flex-direction: row-reverse;
  }

  .flex-center\@xs {
    justify-content: center;
    align-items: center;
  }

  .flex-grow-0\@xs {
    flex-grow: 0;
  }

  .flex-grow\@xs {
    flex-grow: 1;
  }

  .flex-shrink-0\@xs {
    flex-shrink: 0;
  }

  .flex-shrink\@xs {
    flex-shrink: 1;
  }

  .justify-start\@xs {
    justify-content: flex-start;
  }

  .justify-end\@xs {
    justify-content: flex-end;
  }

  .justify-center\@xs {
    justify-content: center;
  }

  .justify-between\@xs {
    justify-content: space-between;
  }

  .items-center\@xs {
    align-items: center;
  }

  .items-start\@xs {
    align-items: flex-start;
  }

  .items-end\@xs {
    align-items: flex-end;
  }

  .items-baseline\@xs {
    align-items: baseline;
  }

  .order-1\@xs {
    order: 1;
  }

  .order-2\@xs {
    order: 2;
  }

  .order-3\@xs {
    order: 3;
  }

  .block\@xs {
    display: block;
  }

  .inline-block\@xs {
    display: inline-block;
  }

  .inline\@xs {
    display: inline;
  }

@supports (--css: variables) {
    .margin-xxxxs\@xs {
      margin: var(--space-xxxxs);
    }

    .margin-xxxs\@xs {
      margin: var(--space-xxxs);
    }

    .margin-xxs\@xs {
      margin: var(--space-xxs);
    }

    .margin-xs\@xs {
      margin: var(--space-xs);
    }

    .margin-sm\@xs {
      margin: var(--space-sm);
    }

    .margin-md\@xs {
      margin: var(--space-md);
    }

    .margin-lg\@xs {
      margin: var(--space-lg);
    }

    .margin-xl\@xs {
      margin: var(--space-xl);
    }

    .margin-xxl\@xs {
      margin: var(--space-xxl);
    }

    .margin-xxxl\@xs {
      margin: var(--space-xxxl);
    }

    .margin-xxxxl\@xs {
      margin: var(--space-xxxxl);
    }

    .margin-auto\@xs {
      margin: auto;
    }

    .margin-0\@xs {
      margin: 0;
    }

    .margin-top-xxxxs\@xs {
      margin-top: var(--space-xxxxs);
    }

    .margin-top-xxxs\@xs {
      margin-top: var(--space-xxxs);
    }

    .margin-top-xxs\@xs {
      margin-top: var(--space-xxs);
    }

    .margin-top-xs\@xs {
      margin-top: var(--space-xs);
    }

    .margin-top-sm\@xs {
      margin-top: var(--space-sm);
    }

    .margin-top-md\@xs {
      margin-top: var(--space-md);
    }

    .margin-top-lg\@xs {
      margin-top: var(--space-lg);
    }

    .margin-top-xl\@xs {
      margin-top: var(--space-xl);
    }

    .margin-top-xxl\@xs {
      margin-top: var(--space-xxl);
    }

    .margin-top-xxxl\@xs {
      margin-top: var(--space-xxxl);
    }

    .margin-top-xxxxl\@xs {
      margin-top: var(--space-xxxxl);
    }

    .margin-top-auto\@xs {
      margin-top: auto;
    }

    .margin-top-0\@xs {
      margin-top: 0;
    }

    .margin-bottom-xxxxs\@xs {
      margin-bottom: var(--space-xxxxs);
    }

    .margin-bottom-xxxs\@xs {
      margin-bottom: var(--space-xxxs);
    }

    .margin-bottom-xxs\@xs {
      margin-bottom: var(--space-xxs);
    }

    .margin-bottom-xs\@xs {
      margin-bottom: var(--space-xs);
    }

    .margin-bottom-sm\@xs {
      margin-bottom: var(--space-sm);
    }

    .margin-bottom-md\@xs {
      margin-bottom: var(--space-md);
    }

    .margin-bottom-lg\@xs {
      margin-bottom: var(--space-lg);
    }

    .margin-bottom-xl\@xs {
      margin-bottom: var(--space-xl);
    }

    .margin-bottom-xxl\@xs {
      margin-bottom: var(--space-xxl);
    }

    .margin-bottom-xxxl\@xs {
      margin-bottom: var(--space-xxxl);
    }

    .margin-bottom-xxxxl\@xs {
      margin-bottom: var(--space-xxxxl);
    }

    .margin-bottom-auto\@xs {
      margin-bottom: auto;
    }

    .margin-bottom-0\@xs {
      margin-bottom: 0;
    }

    .margin-right-xxxxs\@xs {
      margin-right: var(--space-xxxxs);
    }

    .margin-right-xxxs\@xs {
      margin-right: var(--space-xxxs);
    }

    .margin-right-xxs\@xs {
      margin-right: var(--space-xxs);
    }

    .margin-right-xs\@xs {
      margin-right: var(--space-xs);
    }

    .margin-right-sm\@xs {
      margin-right: var(--space-sm);
    }

    .margin-right-md\@xs {
      margin-right: var(--space-md);
    }

    .margin-right-lg\@xs {
      margin-right: var(--space-lg);
    }

    .margin-right-xl\@xs {
      margin-right: var(--space-xl);
    }

    .margin-right-xxl\@xs {
      margin-right: var(--space-xxl);
    }

    .margin-right-xxxl\@xs {
      margin-right: var(--space-xxxl);
    }

    .margin-right-xxxxl\@xs {
      margin-right: var(--space-xxxxl);
    }

    .margin-right-auto\@xs {
      margin-right: auto;
    }

    .margin-right-0\@xs {
      margin-right: 0;
    }

    .margin-left-xxxxs\@xs {
      margin-left: var(--space-xxxxs);
    }

    .margin-left-xxxs\@xs {
      margin-left: var(--space-xxxs);
    }

    .margin-left-xxs\@xs {
      margin-left: var(--space-xxs);
    }

    .margin-left-xs\@xs {
      margin-left: var(--space-xs);
    }

    .margin-left-sm\@xs {
      margin-left: var(--space-sm);
    }

    .margin-left-md\@xs {
      margin-left: var(--space-md);
    }

    .margin-left-lg\@xs {
      margin-left: var(--space-lg);
    }

    .margin-left-xl\@xs {
      margin-left: var(--space-xl);
    }

    .margin-left-xxl\@xs {
      margin-left: var(--space-xxl);
    }

    .margin-left-xxxl\@xs {
      margin-left: var(--space-xxxl);
    }

    .margin-left-xxxxl\@xs {
      margin-left: var(--space-xxxxl);
    }

    .margin-left-auto\@xs {
      margin-left: auto;
    }

    .margin-left-0\@xs {
      margin-left: 0;
    }

    .margin-x-xxxxs\@xs {
      margin-left: var(--space-xxxxs);
      margin-right: var(--space-xxxxs);
    }

    .margin-x-xxxs\@xs {
      margin-left: var(--space-xxxs);
      margin-right: var(--space-xxxs);
    }

    .margin-x-xxs\@xs {
      margin-left: var(--space-xxs);
      margin-right: var(--space-xxs);
    }

    .margin-x-xs\@xs {
      margin-left: var(--space-xs);
      margin-right: var(--space-xs);
    }

    .margin-x-sm\@xs {
      margin-left: var(--space-sm);
      margin-right: var(--space-sm);
    }

    .margin-x-md\@xs {
      margin-left: var(--space-md);
      margin-right: var(--space-md);
    }

    .margin-x-lg\@xs {
      margin-left: var(--space-lg);
      margin-right: var(--space-lg);
    }

    .margin-x-xl\@xs {
      margin-left: var(--space-xl);
      margin-right: var(--space-xl);
    }

    .margin-x-xxl\@xs {
      margin-left: var(--space-xxl);
      margin-right: var(--space-xxl);
    }

    .margin-x-xxxl\@xs {
      margin-left: var(--space-xxxl);
      margin-right: var(--space-xxxl);
    }

    .margin-x-xxxxl\@xs {
      margin-left: var(--space-xxxxl);
      margin-right: var(--space-xxxxl);
    }

    .margin-x-auto\@xs {
      margin-left: auto;
      margin-right: auto;
    }

    .margin-x-0\@xs {
      margin-left: 0;
      margin-right: 0;
    }

    .margin-y-xxxxs\@xs {
      margin-top: var(--space-xxxxs);
      margin-bottom: var(--space-xxxxs);
    }

    .margin-y-xxxs\@xs {
      margin-top: var(--space-xxxs);
      margin-bottom: var(--space-xxxs);
    }

    .margin-y-xxs\@xs {
      margin-top: var(--space-xxs);
      margin-bottom: var(--space-xxs);
    }

    .margin-y-xs\@xs {
      margin-top: var(--space-xs);
      margin-bottom: var(--space-xs);
    }

    .margin-y-sm\@xs {
      margin-top: var(--space-sm);
      margin-bottom: var(--space-sm);
    }

    .margin-y-md\@xs {
      margin-top: var(--space-md);
      margin-bottom: var(--space-md);
    }

    .margin-y-lg\@xs {
      margin-top: var(--space-lg);
      margin-bottom: var(--space-lg);
    }

    .margin-y-xl\@xs {
      margin-top: var(--space-xl);
      margin-bottom: var(--space-xl);
    }

    .margin-y-xxl\@xs {
      margin-top: var(--space-xxl);
      margin-bottom: var(--space-xxl);
    }

    .margin-y-xxxl\@xs {
      margin-top: var(--space-xxxl);
      margin-bottom: var(--space-xxxl);
    }

    .margin-y-xxxxl\@xs {
      margin-top: var(--space-xxxxl);
      margin-bottom: var(--space-xxxxl);
    }

    .margin-y-auto\@xs {
      margin-top: auto;
      margin-bottom: auto;
    }

    .margin-y-0\@xs {
      margin-top: 0;
      margin-bottom: 0;
    }
}

@supports (--css: variables) {
    .padding-xxxxs\@xs {
      padding: var(--space-xxxxs);
    }

    .padding-xxxs\@xs {
      padding: var(--space-xxxs);
    }

    .padding-xxs\@xs {
      padding: var(--space-xxs);
    }

    .padding-xs\@xs {
      padding: var(--space-xs);
    }

    .padding-sm\@xs {
      padding: var(--space-sm);
    }

    .padding-md\@xs {
      padding: var(--space-md);
    }

    .padding-lg\@xs {
      padding: var(--space-lg);
    }

    .padding-xl\@xs {
      padding: var(--space-xl);
    }

    .padding-xxl\@xs {
      padding: var(--space-xxl);
    }

    .padding-xxxl\@xs {
      padding: var(--space-xxxl);
    }

    .padding-xxxxl\@xs {
      padding: var(--space-xxxxl);
    }

    .padding-0\@xs {
      padding: 0;
    }

    .padding-component\@xs {
      padding: var(--component-padding);
    }

    .padding-top-xxxxs\@xs {
      padding-top: var(--space-xxxxs);
    }

    .padding-top-xxxs\@xs {
      padding-top: var(--space-xxxs);
    }

    .padding-top-xxs\@xs {
      padding-top: var(--space-xxs);
    }

    .padding-top-xs\@xs {
      padding-top: var(--space-xs);
    }

    .padding-top-sm\@xs {
      padding-top: var(--space-sm);
    }

    .padding-top-md\@xs {
      padding-top: var(--space-md);
    }

    .padding-top-lg\@xs {
      padding-top: var(--space-lg);
    }

    .padding-top-xl\@xs {
      padding-top: var(--space-xl);
    }

    .padding-top-xxl\@xs {
      padding-top: var(--space-xxl);
    }

    .padding-top-xxxl\@xs {
      padding-top: var(--space-xxxl);
    }

    .padding-top-xxxxl\@xs {
      padding-top: var(--space-xxxxl);
    }

    .padding-top-0\@xs {
      padding-top: 0;
    }

    .padding-top-component\@xs {
      padding-top: var(--component-padding);
    }

    .padding-bottom-xxxxs\@xs {
      padding-bottom: var(--space-xxxxs);
    }

    .padding-bottom-xxxs\@xs {
      padding-bottom: var(--space-xxxs);
    }

    .padding-bottom-xxs\@xs {
      padding-bottom: var(--space-xxs);
    }

    .padding-bottom-xs\@xs {
      padding-bottom: var(--space-xs);
    }

    .padding-bottom-sm\@xs {
      padding-bottom: var(--space-sm);
    }

    .padding-bottom-md\@xs {
      padding-bottom: var(--space-md);
    }

    .padding-bottom-lg\@xs {
      padding-bottom: var(--space-lg);
    }

    .padding-bottom-xl\@xs {
      padding-bottom: var(--space-xl);
    }

    .padding-bottom-xxl\@xs {
      padding-bottom: var(--space-xxl);
    }

    .padding-bottom-xxxl\@xs {
      padding-bottom: var(--space-xxxl);
    }

    .padding-bottom-xxxxl\@xs {
      padding-bottom: var(--space-xxxxl);
    }

    .padding-bottom-0\@xs {
      padding-bottom: 0;
    }

    .padding-bottom-component\@xs {
      padding-bottom: var(--component-padding);
    }

    .padding-right-xxxxs\@xs {
      padding-right: var(--space-xxxxs);
    }

    .padding-right-xxxs\@xs {
      padding-right: var(--space-xxxs);
    }

    .padding-right-xxs\@xs {
      padding-right: var(--space-xxs);
    }

    .padding-right-xs\@xs {
      padding-right: var(--space-xs);
    }

    .padding-right-sm\@xs {
      padding-right: var(--space-sm);
    }

    .padding-right-md\@xs {
      padding-right: var(--space-md);
    }

    .padding-right-lg\@xs {
      padding-right: var(--space-lg);
    }

    .padding-right-xl\@xs {
      padding-right: var(--space-xl);
    }

    .padding-right-xxl\@xs {
      padding-right: var(--space-xxl);
    }

    .padding-right-xxxl\@xs {
      padding-right: var(--space-xxxl);
    }

    .padding-right-xxxxl\@xs {
      padding-right: var(--space-xxxxl);
    }

    .padding-right-0\@xs {
      padding-right: 0;
    }

    .padding-right-component\@xs {
      padding-right: var(--component-padding);
    }

    .padding-left-xxxxs\@xs {
      padding-left: var(--space-xxxxs);
    }

    .padding-left-xxxs\@xs {
      padding-left: var(--space-xxxs);
    }

    .padding-left-xxs\@xs {
      padding-left: var(--space-xxs);
    }

    .padding-left-xs\@xs {
      padding-left: var(--space-xs);
    }

    .padding-left-sm\@xs {
      padding-left: var(--space-sm);
    }

    .padding-left-md\@xs {
      padding-left: var(--space-md);
    }

    .padding-left-lg\@xs {
      padding-left: var(--space-lg);
    }

    .padding-left-xl\@xs {
      padding-left: var(--space-xl);
    }

    .padding-left-xxl\@xs {
      padding-left: var(--space-xxl);
    }

    .padding-left-xxxl\@xs {
      padding-left: var(--space-xxxl);
    }

    .padding-left-xxxxl\@xs {
      padding-left: var(--space-xxxxl);
    }

    .padding-left-0\@xs {
      padding-left: 0;
    }

    .padding-left-component\@xs {
      padding-left: var(--component-padding);
    }

    .padding-x-xxxxs\@xs {
      padding-left: var(--space-xxxxs);
      padding-right: var(--space-xxxxs);
    }

    .padding-x-xxxs\@xs {
      padding-left: var(--space-xxxs);
      padding-right: var(--space-xxxs);
    }

    .padding-x-xxs\@xs {
      padding-left: var(--space-xxs);
      padding-right: var(--space-xxs);
    }

    .padding-x-xs\@xs {
      padding-left: var(--space-xs);
      padding-right: var(--space-xs);
    }

    .padding-x-sm\@xs {
      padding-left: var(--space-sm);
      padding-right: var(--space-sm);
    }

    .padding-x-md\@xs {
      padding-left: var(--space-md);
      padding-right: var(--space-md);
    }

    .padding-x-lg\@xs {
      padding-left: var(--space-lg);
      padding-right: var(--space-lg);
    }

    .padding-x-xl\@xs {
      padding-left: var(--space-xl);
      padding-right: var(--space-xl);
    }

    .padding-x-xxl\@xs {
      padding-left: var(--space-xxl);
      padding-right: var(--space-xxl);
    }

    .padding-x-xxxl\@xs {
      padding-left: var(--space-xxxl);
      padding-right: var(--space-xxxl);
    }

    .padding-x-xxxxl\@xs {
      padding-left: var(--space-xxxxl);
      padding-right: var(--space-xxxxl);
    }

    .padding-x-0\@xs {
      padding-left: 0;
      padding-right: 0;
    }

    .padding-x-component\@xs {
      padding-left: var(--component-padding);
      padding-right: var(--component-padding);
    }

    .padding-y-xxxxs\@xs {
      padding-top: var(--space-xxxxs);
      padding-bottom: var(--space-xxxxs);
    }

    .padding-y-xxxs\@xs {
      padding-top: var(--space-xxxs);
      padding-bottom: var(--space-xxxs);
    }

    .padding-y-xxs\@xs {
      padding-top: var(--space-xxs);
      padding-bottom: var(--space-xxs);
    }

    .padding-y-xs\@xs {
      padding-top: var(--space-xs);
      padding-bottom: var(--space-xs);
    }

    .padding-y-sm\@xs {
      padding-top: var(--space-sm);
      padding-bottom: var(--space-sm);
    }

    .padding-y-md\@xs {
      padding-top: var(--space-md);
      padding-bottom: var(--space-md);
    }

    .padding-y-lg\@xs {
      padding-top: var(--space-lg);
      padding-bottom: var(--space-lg);
    }

    .padding-y-xl\@xs {
      padding-top: var(--space-xl);
      padding-bottom: var(--space-xl);
    }

    .padding-y-xxl\@xs {
      padding-top: var(--space-xxl);
      padding-bottom: var(--space-xxl);
    }

    .padding-y-xxxl\@xs {
      padding-top: var(--space-xxxl);
      padding-bottom: var(--space-xxxl);
    }

    .padding-y-xxxxl\@xs {
      padding-top: var(--space-xxxxl);
      padding-bottom: var(--space-xxxxl);
    }

    .padding-y-0\@xs {
      padding-top: 0;
      padding-bottom: 0;
    }

    .padding-y-component\@xs {
      padding-top: var(--component-padding);
      padding-bottom: var(--component-padding);
    }
}

  .text-center\@xs {
    text-align: center;
  }

  .text-left\@xs {
    text-align: left;
  }

  .text-right\@xs {
    text-align: right;
  }

  .text-justify\@xs {
    text-align: justify;
  }

@supports (--css: variables) {
    .text-xs\@xs {
      font-size: var(--text-xs, 0.694em);
    }

    .text-sm\@xs {
      font-size: var(--text-sm, 0.833em);
    }

    .text-base\@xs {
      font-size: 1em;
    }

    .text-md\@xs {
      font-size: var(--text-md, 1.2em);
    }

    .text-lg\@xs {
      font-size: var(--text-lg, 1.44em);
    }

    .text-xl\@xs {
      font-size: var(--text-xl, 1.728em);
    }

    .text-xxl\@xs {
      font-size: var(--text-xxl, 2.074em);
    }

    .text-xxxl\@xs {
      font-size: var(--text-xxxl, 2.488em);
    }

    .text-xxxxl\@xs {
      font-size: var(--text-xxxxl, 2.985em);
    }
}

  .hide\@xs {
    display: none !important;
  }
}

@media not all and (min-width: 32rem) {
  .has-margin\@xs {
    margin: 0 !important;
  }

  .has-padding\@xs {
    padding: 0 !important;
  }

  .display\@xs {
    display: none !important;
  }
}

@media (min-width: 48rem) {
  .flex\@sm {
    display: flex;
  }

  .inline-flex\@sm {
    display: inline-flex;
  }

  .flex-wrap\@sm {
    flex-wrap: wrap;
  }

  .flex-column\@sm {
    flex-direction: column;
  }

  .flex-column-reverse\@sm {
    flex-direction: column-reverse;
  }

  .flex-row\@sm {
    flex-direction: row;
  }

  .flex-row-reverse\@sm {
    flex-direction: row-reverse;
  }

  .flex-center\@sm {
    justify-content: center;
    align-items: center;
  }

  .flex-grow-0\@sm {
    flex-grow: 0;
  }

  .flex-grow\@sm {
    flex-grow: 1;
  }

  .flex-shrink-0\@sm {
    flex-shrink: 0;
  }

  .flex-shrink\@sm {
    flex-shrink: 1;
  }

  .justify-start\@sm {
    justify-content: flex-start;
  }

  .justify-end\@sm {
    justify-content: flex-end;
  }

  .justify-center\@sm {
    justify-content: center;
  }

  .justify-between\@sm {
    justify-content: space-between;
  }

  .items-center\@sm {
    align-items: center;
  }

  .items-start\@sm {
    align-items: flex-start;
  }

  .items-end\@sm {
    align-items: flex-end;
  }

  .items-baseline\@sm {
    align-items: baseline;
  }

  .order-1\@sm {
    order: 1;
  }

  .order-2\@sm {
    order: 2;
  }

  .order-3\@sm {
    order: 3;
  }

  .block\@sm {
    display: block;
  }

  .inline-block\@sm {
    display: inline-block;
  }

  .inline\@sm {
    display: inline;
  }

@supports (--css: variables) {
    .margin-xxxxs\@sm {
      margin: var(--space-xxxxs);
    }

    .margin-xxxs\@sm {
      margin: var(--space-xxxs);
    }

    .margin-xxs\@sm {
      margin: var(--space-xxs);
    }

    .margin-xs\@sm {
      margin: var(--space-xs);
    }

    .margin-sm\@sm {
      margin: var(--space-sm);
    }

    .margin-md\@sm {
      margin: var(--space-md);
    }

    .margin-lg\@sm {
      margin: var(--space-lg);
    }

    .margin-xl\@sm {
      margin: var(--space-xl);
    }

    .margin-xxl\@sm {
      margin: var(--space-xxl);
    }

    .margin-xxxl\@sm {
      margin: var(--space-xxxl);
    }

    .margin-xxxxl\@sm {
      margin: var(--space-xxxxl);
    }

    .margin-auto\@sm {
      margin: auto;
    }

    .margin-0\@sm {
      margin: 0;
    }

    .margin-top-xxxxs\@sm {
      margin-top: var(--space-xxxxs);
    }

    .margin-top-xxxs\@sm {
      margin-top: var(--space-xxxs);
    }

    .margin-top-xxs\@sm {
      margin-top: var(--space-xxs);
    }

    .margin-top-xs\@sm {
      margin-top: var(--space-xs);
    }

    .margin-top-sm\@sm {
      margin-top: var(--space-sm);
    }

    .margin-top-md\@sm {
      margin-top: var(--space-md);
    }

    .margin-top-lg\@sm {
      margin-top: var(--space-lg);
    }

    .margin-top-xl\@sm {
      margin-top: var(--space-xl);
    }

    .margin-top-xxl\@sm {
      margin-top: var(--space-xxl);
    }

    .margin-top-xxxl\@sm {
      margin-top: var(--space-xxxl);
    }

    .margin-top-xxxxl\@sm {
      margin-top: var(--space-xxxxl);
    }

    .margin-top-auto\@sm {
      margin-top: auto;
    }

    .margin-top-0\@sm {
      margin-top: 0;
    }

    .margin-bottom-xxxxs\@sm {
      margin-bottom: var(--space-xxxxs);
    }

    .margin-bottom-xxxs\@sm {
      margin-bottom: var(--space-xxxs);
    }

    .margin-bottom-xxs\@sm {
      margin-bottom: var(--space-xxs);
    }

    .margin-bottom-xs\@sm {
      margin-bottom: var(--space-xs);
    }

    .margin-bottom-sm\@sm {
      margin-bottom: var(--space-sm);
    }

    .margin-bottom-md\@sm {
      margin-bottom: var(--space-md);
    }

    .margin-bottom-lg\@sm {
      margin-bottom: var(--space-lg);
    }

    .margin-bottom-xl\@sm {
      margin-bottom: var(--space-xl);
    }

    .margin-bottom-xxl\@sm {
      margin-bottom: var(--space-xxl);
    }

    .margin-bottom-xxxl\@sm {
      margin-bottom: var(--space-xxxl);
    }

    .margin-bottom-xxxxl\@sm {
      margin-bottom: var(--space-xxxxl);
    }

    .margin-bottom-auto\@sm {
      margin-bottom: auto;
    }

    .margin-bottom-0\@sm {
      margin-bottom: 0;
    }

    .margin-right-xxxxs\@sm {
      margin-right: var(--space-xxxxs);
    }

    .margin-right-xxxs\@sm {
      margin-right: var(--space-xxxs);
    }

    .margin-right-xxs\@sm {
      margin-right: var(--space-xxs);
    }

    .margin-right-xs\@sm {
      margin-right: var(--space-xs);
    }

    .margin-right-sm\@sm {
      margin-right: var(--space-sm);
    }

    .margin-right-md\@sm {
      margin-right: var(--space-md);
    }

    .margin-right-lg\@sm {
      margin-right: var(--space-lg);
    }

    .margin-right-xl\@sm {
      margin-right: var(--space-xl);
    }

    .margin-right-xxl\@sm {
      margin-right: var(--space-xxl);
    }

    .margin-right-xxxl\@sm {
      margin-right: var(--space-xxxl);
    }

    .margin-right-xxxxl\@sm {
      margin-right: var(--space-xxxxl);
    }

    .margin-right-auto\@sm {
      margin-right: auto;
    }

    .margin-right-0\@sm {
      margin-right: 0;
    }

    .margin-left-xxxxs\@sm {
      margin-left: var(--space-xxxxs);
    }

    .margin-left-xxxs\@sm {
      margin-left: var(--space-xxxs);
    }

    .margin-left-xxs\@sm {
      margin-left: var(--space-xxs);
    }

    .margin-left-xs\@sm {
      margin-left: var(--space-xs);
    }

    .margin-left-sm\@sm {
      margin-left: var(--space-sm);
    }

    .margin-left-md\@sm {
      margin-left: var(--space-md);
    }

    .margin-left-lg\@sm {
      margin-left: var(--space-lg);
    }

    .margin-left-xl\@sm {
      margin-left: var(--space-xl);
    }

    .margin-left-xxl\@sm {
      margin-left: var(--space-xxl);
    }

    .margin-left-xxxl\@sm {
      margin-left: var(--space-xxxl);
    }

    .margin-left-xxxxl\@sm {
      margin-left: var(--space-xxxxl);
    }

    .margin-left-auto\@sm {
      margin-left: auto;
    }

    .margin-left-0\@sm {
      margin-left: 0;
    }

    .margin-x-xxxxs\@sm {
      margin-left: var(--space-xxxxs);
      margin-right: var(--space-xxxxs);
    }

    .margin-x-xxxs\@sm {
      margin-left: var(--space-xxxs);
      margin-right: var(--space-xxxs);
    }

    .margin-x-xxs\@sm {
      margin-left: var(--space-xxs);
      margin-right: var(--space-xxs);
    }

    .margin-x-xs\@sm {
      margin-left: var(--space-xs);
      margin-right: var(--space-xs);
    }

    .margin-x-sm\@sm {
      margin-left: var(--space-sm);
      margin-right: var(--space-sm);
    }

    .margin-x-md\@sm {
      margin-left: var(--space-md);
      margin-right: var(--space-md);
    }

    .margin-x-lg\@sm {
      margin-left: var(--space-lg);
      margin-right: var(--space-lg);
    }

    .margin-x-xl\@sm {
      margin-left: var(--space-xl);
      margin-right: var(--space-xl);
    }

    .margin-x-xxl\@sm {
      margin-left: var(--space-xxl);
      margin-right: var(--space-xxl);
    }

    .margin-x-xxxl\@sm {
      margin-left: var(--space-xxxl);
      margin-right: var(--space-xxxl);
    }

    .margin-x-xxxxl\@sm {
      margin-left: var(--space-xxxxl);
      margin-right: var(--space-xxxxl);
    }

    .margin-x-auto\@sm {
      margin-left: auto;
      margin-right: auto;
    }

    .margin-x-0\@sm {
      margin-left: 0;
      margin-right: 0;
    }

    .margin-y-xxxxs\@sm {
      margin-top: var(--space-xxxxs);
      margin-bottom: var(--space-xxxxs);
    }

    .margin-y-xxxs\@sm {
      margin-top: var(--space-xxxs);
      margin-bottom: var(--space-xxxs);
    }

    .margin-y-xxs\@sm {
      margin-top: var(--space-xxs);
      margin-bottom: var(--space-xxs);
    }

    .margin-y-xs\@sm {
      margin-top: var(--space-xs);
      margin-bottom: var(--space-xs);
    }

    .margin-y-sm\@sm {
      margin-top: var(--space-sm);
      margin-bottom: var(--space-sm);
    }

    .margin-y-md\@sm {
      margin-top: var(--space-md);
      margin-bottom: var(--space-md);
    }

    .margin-y-lg\@sm {
      margin-top: var(--space-lg);
      margin-bottom: var(--space-lg);
    }

    .margin-y-xl\@sm {
      margin-top: var(--space-xl);
      margin-bottom: var(--space-xl);
    }

    .margin-y-xxl\@sm {
      margin-top: var(--space-xxl);
      margin-bottom: var(--space-xxl);
    }

    .margin-y-xxxl\@sm {
      margin-top: var(--space-xxxl);
      margin-bottom: var(--space-xxxl);
    }

    .margin-y-xxxxl\@sm {
      margin-top: var(--space-xxxxl);
      margin-bottom: var(--space-xxxxl);
    }

    .margin-y-auto\@sm {
      margin-top: auto;
      margin-bottom: auto;
    }

    .margin-y-0\@sm {
      margin-top: 0;
      margin-bottom: 0;
    }
}

@supports (--css: variables) {
    .padding-xxxxs\@sm {
      padding: var(--space-xxxxs);
    }

    .padding-xxxs\@sm {
      padding: var(--space-xxxs);
    }

    .padding-xxs\@sm {
      padding: var(--space-xxs);
    }

    .padding-xs\@sm {
      padding: var(--space-xs);
    }

    .padding-sm\@sm {
      padding: var(--space-sm);
    }

    .padding-md\@sm {
      padding: var(--space-md);
    }

    .padding-lg\@sm {
      padding: var(--space-lg);
    }

    .padding-xl\@sm {
      padding: var(--space-xl);
    }

    .padding-xxl\@sm {
      padding: var(--space-xxl);
    }

    .padding-xxxl\@sm {
      padding: var(--space-xxxl);
    }

    .padding-xxxxl\@sm {
      padding: var(--space-xxxxl);
    }

    .padding-0\@sm {
      padding: 0;
    }

    .padding-component\@sm {
      padding: var(--component-padding);
    }

    .padding-top-xxxxs\@sm {
      padding-top: var(--space-xxxxs);
    }

    .padding-top-xxxs\@sm {
      padding-top: var(--space-xxxs);
    }

    .padding-top-xxs\@sm {
      padding-top: var(--space-xxs);
    }

    .padding-top-xs\@sm {
      padding-top: var(--space-xs);
    }

    .padding-top-sm\@sm {
      padding-top: var(--space-sm);
    }

    .padding-top-md\@sm {
      padding-top: var(--space-md);
    }

    .padding-top-lg\@sm {
      padding-top: var(--space-lg);
    }

    .padding-top-xl\@sm {
      padding-top: var(--space-xl);
    }

    .padding-top-xxl\@sm {
      padding-top: var(--space-xxl);
    }

    .padding-top-xxxl\@sm {
      padding-top: var(--space-xxxl);
    }

    .padding-top-xxxxl\@sm {
      padding-top: var(--space-xxxxl);
    }

    .padding-top-0\@sm {
      padding-top: 0;
    }

    .padding-top-component\@sm {
      padding-top: var(--component-padding);
    }

    .padding-bottom-xxxxs\@sm {
      padding-bottom: var(--space-xxxxs);
    }

    .padding-bottom-xxxs\@sm {
      padding-bottom: var(--space-xxxs);
    }

    .padding-bottom-xxs\@sm {
      padding-bottom: var(--space-xxs);
    }

    .padding-bottom-xs\@sm {
      padding-bottom: var(--space-xs);
    }

    .padding-bottom-sm\@sm {
      padding-bottom: var(--space-sm);
    }

    .padding-bottom-md\@sm {
      padding-bottom: var(--space-md);
    }

    .padding-bottom-lg\@sm {
      padding-bottom: var(--space-lg);
    }

    .padding-bottom-xl\@sm {
      padding-bottom: var(--space-xl);
    }

    .padding-bottom-xxl\@sm {
      padding-bottom: var(--space-xxl);
    }

    .padding-bottom-xxxl\@sm {
      padding-bottom: var(--space-xxxl);
    }

    .padding-bottom-xxxxl\@sm {
      padding-bottom: var(--space-xxxxl);
    }

    .padding-bottom-0\@sm {
      padding-bottom: 0;
    }

    .padding-bottom-component\@sm {
      padding-bottom: var(--component-padding);
    }

    .padding-right-xxxxs\@sm {
      padding-right: var(--space-xxxxs);
    }

    .padding-right-xxxs\@sm {
      padding-right: var(--space-xxxs);
    }

    .padding-right-xxs\@sm {
      padding-right: var(--space-xxs);
    }

    .padding-right-xs\@sm {
      padding-right: var(--space-xs);
    }

    .padding-right-sm\@sm {
      padding-right: var(--space-sm);
    }

    .padding-right-md\@sm {
      padding-right: var(--space-md);
    }

    .padding-right-lg\@sm {
      padding-right: var(--space-lg);
    }

    .padding-right-xl\@sm {
      padding-right: var(--space-xl);
    }

    .padding-right-xxl\@sm {
      padding-right: var(--space-xxl);
    }

    .padding-right-xxxl\@sm {
      padding-right: var(--space-xxxl);
    }

    .padding-right-xxxxl\@sm {
      padding-right: var(--space-xxxxl);
    }

    .padding-right-0\@sm {
      padding-right: 0;
    }

    .padding-right-component\@sm {
      padding-right: var(--component-padding);
    }

    .padding-left-xxxxs\@sm {
      padding-left: var(--space-xxxxs);
    }

    .padding-left-xxxs\@sm {
      padding-left: var(--space-xxxs);
    }

    .padding-left-xxs\@sm {
      padding-left: var(--space-xxs);
    }

    .padding-left-xs\@sm {
      padding-left: var(--space-xs);
    }

    .padding-left-sm\@sm {
      padding-left: var(--space-sm);
    }

    .padding-left-md\@sm {
      padding-left: var(--space-md);
    }

    .padding-left-lg\@sm {
      padding-left: var(--space-lg);
    }

    .padding-left-xl\@sm {
      padding-left: var(--space-xl);
    }

    .padding-left-xxl\@sm {
      padding-left: var(--space-xxl);
    }

    .padding-left-xxxl\@sm {
      padding-left: var(--space-xxxl);
    }

    .padding-left-xxxxl\@sm {
      padding-left: var(--space-xxxxl);
    }

    .padding-left-0\@sm {
      padding-left: 0;
    }

    .padding-left-component\@sm {
      padding-left: var(--component-padding);
    }

    .padding-x-xxxxs\@sm {
      padding-left: var(--space-xxxxs);
      padding-right: var(--space-xxxxs);
    }

    .padding-x-xxxs\@sm {
      padding-left: var(--space-xxxs);
      padding-right: var(--space-xxxs);
    }

    .padding-x-xxs\@sm {
      padding-left: var(--space-xxs);
      padding-right: var(--space-xxs);
    }

    .padding-x-xs\@sm {
      padding-left: var(--space-xs);
      padding-right: var(--space-xs);
    }

    .padding-x-sm\@sm {
      padding-left: var(--space-sm);
      padding-right: var(--space-sm);
    }

    .padding-x-md\@sm {
      padding-left: var(--space-md);
      padding-right: var(--space-md);
    }

    .padding-x-lg\@sm {
      padding-left: var(--space-lg);
      padding-right: var(--space-lg);
    }

    .padding-x-xl\@sm {
      padding-left: var(--space-xl);
      padding-right: var(--space-xl);
    }

    .padding-x-xxl\@sm {
      padding-left: var(--space-xxl);
      padding-right: var(--space-xxl);
    }

    .padding-x-xxxl\@sm {
      padding-left: var(--space-xxxl);
      padding-right: var(--space-xxxl);
    }

    .padding-x-xxxxl\@sm {
      padding-left: var(--space-xxxxl);
      padding-right: var(--space-xxxxl);
    }

    .padding-x-0\@sm {
      padding-left: 0;
      padding-right: 0;
    }

    .padding-x-component\@sm {
      padding-left: var(--component-padding);
      padding-right: var(--component-padding);
    }

    .padding-y-xxxxs\@sm {
      padding-top: var(--space-xxxxs);
      padding-bottom: var(--space-xxxxs);
    }

    .padding-y-xxxs\@sm {
      padding-top: var(--space-xxxs);
      padding-bottom: var(--space-xxxs);
    }

    .padding-y-xxs\@sm {
      padding-top: var(--space-xxs);
      padding-bottom: var(--space-xxs);
    }

    .padding-y-xs\@sm {
      padding-top: var(--space-xs);
      padding-bottom: var(--space-xs);
    }

    .padding-y-sm\@sm {
      padding-top: var(--space-sm);
      padding-bottom: var(--space-sm);
    }

    .padding-y-md\@sm {
      padding-top: var(--space-md);
      padding-bottom: var(--space-md);
    }

    .padding-y-lg\@sm {
      padding-top: var(--space-lg);
      padding-bottom: var(--space-lg);
    }

    .padding-y-xl\@sm {
      padding-top: var(--space-xl);
      padding-bottom: var(--space-xl);
    }

    .padding-y-xxl\@sm {
      padding-top: var(--space-xxl);
      padding-bottom: var(--space-xxl);
    }

    .padding-y-xxxl\@sm {
      padding-top: var(--space-xxxl);
      padding-bottom: var(--space-xxxl);
    }

    .padding-y-xxxxl\@sm {
      padding-top: var(--space-xxxxl);
      padding-bottom: var(--space-xxxxl);
    }

    .padding-y-0\@sm {
      padding-top: 0;
      padding-bottom: 0;
    }

    .padding-y-component\@sm {
      padding-top: var(--component-padding);
      padding-bottom: var(--component-padding);
    }
}

  .text-center\@sm {
    text-align: center;
  }

  .text-left\@sm {
    text-align: left;
  }

  .text-right\@sm {
    text-align: right;
  }

  .text-justify\@sm {
    text-align: justify;
  }

@supports (--css: variables) {
    .text-xs\@sm {
      font-size: var(--text-xs, 0.694em);
    }

    .text-sm\@sm {
      font-size: var(--text-sm, 0.833em);
    }

    .text-base\@sm {
      font-size: 1em;
    }

    .text-md\@sm {
      font-size: var(--text-md, 1.2em);
    }

    .text-lg\@sm {
      font-size: var(--text-lg, 1.44em);
    }

    .text-xl\@sm {
      font-size: var(--text-xl, 1.728em);
    }

    .text-xxl\@sm {
      font-size: var(--text-xxl, 2.074em);
    }

    .text-xxxl\@sm {
      font-size: var(--text-xxxl, 2.488em);
    }

    .text-xxxxl\@sm {
      font-size: var(--text-xxxxl, 2.985em);
    }
}

  .hide\@sm {
    display: none !important;
  }
}

@media not all and (min-width: 48rem) {
  .has-margin\@sm {
    margin: 0 !important;
  }

  .has-padding\@sm {
    padding: 0 !important;
  }

  .display\@sm {
    display: none !important;
  }
}

@media (min-width: 64rem) {
  .flex\@md {
    display: flex;
  }

  .inline-flex\@md {
    display: inline-flex;
  }

  .flex-wrap\@md {
    flex-wrap: wrap;
  }

  .flex-column\@md {
    flex-direction: column;
  }

  .flex-column-reverse\@md {
    flex-direction: column-reverse;
  }

  .flex-row\@md {
    flex-direction: row;
  }

  .flex-row-reverse\@md {
    flex-direction: row-reverse;
  }

  .flex-center\@md {
    justify-content: center;
    align-items: center;
  }

  .flex-grow-0\@md {
    flex-grow: 0;
  }

  .flex-grow\@md {
    flex-grow: 1;
  }

  .flex-shrink-0\@md {
    flex-shrink: 0;
  }

  .flex-shrink\@md {
    flex-shrink: 1;
  }

  .justify-start\@md {
    justify-content: flex-start;
  }

  .justify-end\@md {
    justify-content: flex-end;
  }

  .justify-center\@md {
    justify-content: center;
  }

  .justify-between\@md {
    justify-content: space-between;
  }

  .items-center\@md {
    align-items: center;
  }

  .items-start\@md {
    align-items: flex-start;
  }

  .items-end\@md {
    align-items: flex-end;
  }

  .items-baseline\@md {
    align-items: baseline;
  }

  .order-1\@md {
    order: 1;
  }

  .order-2\@md {
    order: 2;
  }

  .order-3\@md {
    order: 3;
  }

  .block\@md {
    display: block;
  }

  .inline-block\@md {
    display: inline-block;
  }

  .inline\@md {
    display: inline;
  }

@supports (--css: variables) {
    .margin-xxxxs\@md {
      margin: var(--space-xxxxs);
    }

    .margin-xxxs\@md {
      margin: var(--space-xxxs);
    }

    .margin-xxs\@md {
      margin: var(--space-xxs);
    }

    .margin-xs\@md {
      margin: var(--space-xs);
    }

    .margin-sm\@md {
      margin: var(--space-sm);
    }

    .margin-md\@md {
      margin: var(--space-md);
    }

    .margin-lg\@md {
      margin: var(--space-lg);
    }

    .margin-xl\@md {
      margin: var(--space-xl);
    }

    .margin-xxl\@md {
      margin: var(--space-xxl);
    }

    .margin-xxxl\@md {
      margin: var(--space-xxxl);
    }

    .margin-xxxxl\@md {
      margin: var(--space-xxxxl);
    }

    .margin-auto\@md {
      margin: auto;
    }

    .margin-0\@md {
      margin: 0;
    }

    .margin-top-xxxxs\@md {
      margin-top: var(--space-xxxxs);
    }

    .margin-top-xxxs\@md {
      margin-top: var(--space-xxxs);
    }

    .margin-top-xxs\@md {
      margin-top: var(--space-xxs);
    }

    .margin-top-xs\@md {
      margin-top: var(--space-xs);
    }

    .margin-top-sm\@md {
      margin-top: var(--space-sm);
    }

    .margin-top-md\@md {
      margin-top: var(--space-md);
    }

    .margin-top-lg\@md {
      margin-top: var(--space-lg);
    }

    .margin-top-xl\@md {
      margin-top: var(--space-xl);
    }

    .margin-top-xxl\@md {
      margin-top: var(--space-xxl);
    }

    .margin-top-xxxl\@md {
      margin-top: var(--space-xxxl);
    }

    .margin-top-xxxxl\@md {
      margin-top: var(--space-xxxxl);
    }

    .margin-top-auto\@md {
      margin-top: auto;
    }

    .margin-top-0\@md {
      margin-top: 0;
    }

    .margin-bottom-xxxxs\@md {
      margin-bottom: var(--space-xxxxs);
    }

    .margin-bottom-xxxs\@md {
      margin-bottom: var(--space-xxxs);
    }

    .margin-bottom-xxs\@md {
      margin-bottom: var(--space-xxs);
    }

    .margin-bottom-xs\@md {
      margin-bottom: var(--space-xs);
    }

    .margin-bottom-sm\@md {
      margin-bottom: var(--space-sm);
    }

    .margin-bottom-md\@md {
      margin-bottom: var(--space-md);
    }

    .margin-bottom-lg\@md {
      margin-bottom: var(--space-lg);
    }

    .margin-bottom-xl\@md {
      margin-bottom: var(--space-xl);
    }

    .margin-bottom-xxl\@md {
      margin-bottom: var(--space-xxl);
    }

    .margin-bottom-xxxl\@md {
      margin-bottom: var(--space-xxxl);
    }

    .margin-bottom-xxxxl\@md {
      margin-bottom: var(--space-xxxxl);
    }

    .margin-bottom-auto\@md {
      margin-bottom: auto;
    }

    .margin-bottom-0\@md {
      margin-bottom: 0;
    }

    .margin-right-xxxxs\@md {
      margin-right: var(--space-xxxxs);
    }

    .margin-right-xxxs\@md {
      margin-right: var(--space-xxxs);
    }

    .margin-right-xxs\@md {
      margin-right: var(--space-xxs);
    }

    .margin-right-xs\@md {
      margin-right: var(--space-xs);
    }

    .margin-right-sm\@md {
      margin-right: var(--space-sm);
    }

    .margin-right-md\@md {
      margin-right: var(--space-md);
    }

    .margin-right-lg\@md {
      margin-right: var(--space-lg);
    }

    .margin-right-xl\@md {
      margin-right: var(--space-xl);
    }

    .margin-right-xxl\@md {
      margin-right: var(--space-xxl);
    }

    .margin-right-xxxl\@md {
      margin-right: var(--space-xxxl);
    }

    .margin-right-xxxxl\@md {
      margin-right: var(--space-xxxxl);
    }

    .margin-right-auto\@md {
      margin-right: auto;
    }

    .margin-right-0\@md {
      margin-right: 0;
    }

    .margin-left-xxxxs\@md {
      margin-left: var(--space-xxxxs);
    }

    .margin-left-xxxs\@md {
      margin-left: var(--space-xxxs);
    }

    .margin-left-xxs\@md {
      margin-left: var(--space-xxs);
    }

    .margin-left-xs\@md {
      margin-left: var(--space-xs);
    }

    .margin-left-sm\@md {
      margin-left: var(--space-sm);
    }

    .margin-left-md\@md {
      margin-left: var(--space-md);
    }

    .margin-left-lg\@md {
      margin-left: var(--space-lg);
    }

    .margin-left-xl\@md {
      margin-left: var(--space-xl);
    }

    .margin-left-xxl\@md {
      margin-left: var(--space-xxl);
    }

    .margin-left-xxxl\@md {
      margin-left: var(--space-xxxl);
    }

    .margin-left-xxxxl\@md {
      margin-left: var(--space-xxxxl);
    }

    .margin-left-auto\@md {
      margin-left: auto;
    }

    .margin-left-0\@md {
      margin-left: 0;
    }

    .margin-x-xxxxs\@md {
      margin-left: var(--space-xxxxs);
      margin-right: var(--space-xxxxs);
    }

    .margin-x-xxxs\@md {
      margin-left: var(--space-xxxs);
      margin-right: var(--space-xxxs);
    }

    .margin-x-xxs\@md {
      margin-left: var(--space-xxs);
      margin-right: var(--space-xxs);
    }

    .margin-x-xs\@md {
      margin-left: var(--space-xs);
      margin-right: var(--space-xs);
    }

    .margin-x-sm\@md {
      margin-left: var(--space-sm);
      margin-right: var(--space-sm);
    }

    .margin-x-md\@md {
      margin-left: var(--space-md);
      margin-right: var(--space-md);
    }

    .margin-x-lg\@md {
      margin-left: var(--space-lg);
      margin-right: var(--space-lg);
    }

    .margin-x-xl\@md {
      margin-left: var(--space-xl);
      margin-right: var(--space-xl);
    }

    .margin-x-xxl\@md {
      margin-left: var(--space-xxl);
      margin-right: var(--space-xxl);
    }

    .margin-x-xxxl\@md {
      margin-left: var(--space-xxxl);
      margin-right: var(--space-xxxl);
    }

    .margin-x-xxxxl\@md {
      margin-left: var(--space-xxxxl);
      margin-right: var(--space-xxxxl);
    }

    .margin-x-auto\@md {
      margin-left: auto;
      margin-right: auto;
    }

    .margin-x-0\@md {
      margin-left: 0;
      margin-right: 0;
    }

    .margin-y-xxxxs\@md {
      margin-top: var(--space-xxxxs);
      margin-bottom: var(--space-xxxxs);
    }

    .margin-y-xxxs\@md {
      margin-top: var(--space-xxxs);
      margin-bottom: var(--space-xxxs);
    }

    .margin-y-xxs\@md {
      margin-top: var(--space-xxs);
      margin-bottom: var(--space-xxs);
    }

    .margin-y-xs\@md {
      margin-top: var(--space-xs);
      margin-bottom: var(--space-xs);
    }

    .margin-y-sm\@md {
      margin-top: var(--space-sm);
      margin-bottom: var(--space-sm);
    }

    .margin-y-md\@md {
      margin-top: var(--space-md);
      margin-bottom: var(--space-md);
    }

    .margin-y-lg\@md {
      margin-top: var(--space-lg);
      margin-bottom: var(--space-lg);
    }

    .margin-y-xl\@md {
      margin-top: var(--space-xl);
      margin-bottom: var(--space-xl);
    }

    .margin-y-xxl\@md {
      margin-top: var(--space-xxl);
      margin-bottom: var(--space-xxl);
    }

    .margin-y-xxxl\@md {
      margin-top: var(--space-xxxl);
      margin-bottom: var(--space-xxxl);
    }

    .margin-y-xxxxl\@md {
      margin-top: var(--space-xxxxl);
      margin-bottom: var(--space-xxxxl);
    }

    .margin-y-auto\@md {
      margin-top: auto;
      margin-bottom: auto;
    }

    .margin-y-0\@md {
      margin-top: 0;
      margin-bottom: 0;
    }
}

@supports (--css: variables) {
    .padding-xxxxs\@md {
      padding: var(--space-xxxxs);
    }

    .padding-xxxs\@md {
      padding: var(--space-xxxs);
    }

    .padding-xxs\@md {
      padding: var(--space-xxs);
    }

    .padding-xs\@md {
      padding: var(--space-xs);
    }

    .padding-sm\@md {
      padding: var(--space-sm);
    }

    .padding-md\@md {
      padding: var(--space-md);
    }

    .padding-lg\@md {
      padding: var(--space-lg);
    }

    .padding-xl\@md {
      padding: var(--space-xl);
    }

    .padding-xxl\@md {
      padding: var(--space-xxl);
    }

    .padding-xxxl\@md {
      padding: var(--space-xxxl);
    }

    .padding-xxxxl\@md {
      padding: var(--space-xxxxl);
    }

    .padding-0\@md {
      padding: 0;
    }

    .padding-component\@md {
      padding: var(--component-padding);
    }

    .padding-top-xxxxs\@md {
      padding-top: var(--space-xxxxs);
    }

    .padding-top-xxxs\@md {
      padding-top: var(--space-xxxs);
    }

    .padding-top-xxs\@md {
      padding-top: var(--space-xxs);
    }

    .padding-top-xs\@md {
      padding-top: var(--space-xs);
    }

    .padding-top-sm\@md {
      padding-top: var(--space-sm);
    }

    .padding-top-md\@md {
      padding-top: var(--space-md);
    }

    .padding-top-lg\@md {
      padding-top: var(--space-lg);
    }

    .padding-top-xl\@md {
      padding-top: var(--space-xl);
    }

    .padding-top-xxl\@md {
      padding-top: var(--space-xxl);
    }

    .padding-top-xxxl\@md {
      padding-top: var(--space-xxxl);
    }

    .padding-top-xxxxl\@md {
      padding-top: var(--space-xxxxl);
    }

    .padding-top-0\@md {
      padding-top: 0;
    }

    .padding-top-component\@md {
      padding-top: var(--component-padding);
    }

    .padding-bottom-xxxxs\@md {
      padding-bottom: var(--space-xxxxs);
    }

    .padding-bottom-xxxs\@md {
      padding-bottom: var(--space-xxxs);
    }

    .padding-bottom-xxs\@md {
      padding-bottom: var(--space-xxs);
    }

    .padding-bottom-xs\@md {
      padding-bottom: var(--space-xs);
    }

    .padding-bottom-sm\@md {
      padding-bottom: var(--space-sm);
    }

    .padding-bottom-md\@md {
      padding-bottom: var(--space-md);
    }

    .padding-bottom-lg\@md {
      padding-bottom: var(--space-lg);
    }

    .padding-bottom-xl\@md {
      padding-bottom: var(--space-xl);
    }

    .padding-bottom-xxl\@md {
      padding-bottom: var(--space-xxl);
    }

    .padding-bottom-xxxl\@md {
      padding-bottom: var(--space-xxxl);
    }

    .padding-bottom-xxxxl\@md {
      padding-bottom: var(--space-xxxxl);
    }

    .padding-bottom-0\@md {
      padding-bottom: 0;
    }

    .padding-bottom-component\@md {
      padding-bottom: var(--component-padding);
    }

    .padding-right-xxxxs\@md {
      padding-right: var(--space-xxxxs);
    }

    .padding-right-xxxs\@md {
      padding-right: var(--space-xxxs);
    }

    .padding-right-xxs\@md {
      padding-right: var(--space-xxs);
    }

    .padding-right-xs\@md {
      padding-right: var(--space-xs);
    }

    .padding-right-sm\@md {
      padding-right: var(--space-sm);
    }

    .padding-right-md\@md {
      padding-right: var(--space-md);
    }

    .padding-right-lg\@md {
      padding-right: var(--space-lg);
    }

    .padding-right-xl\@md {
      padding-right: var(--space-xl);
    }

    .padding-right-xxl\@md {
      padding-right: var(--space-xxl);
    }

    .padding-right-xxxl\@md {
      padding-right: var(--space-xxxl);
    }

    .padding-right-xxxxl\@md {
      padding-right: var(--space-xxxxl);
    }

    .padding-right-0\@md {
      padding-right: 0;
    }

    .padding-right-component\@md {
      padding-right: var(--component-padding);
    }

    .padding-left-xxxxs\@md {
      padding-left: var(--space-xxxxs);
    }

    .padding-left-xxxs\@md {
      padding-left: var(--space-xxxs);
    }

    .padding-left-xxs\@md {
      padding-left: var(--space-xxs);
    }

    .padding-left-xs\@md {
      padding-left: var(--space-xs);
    }

    .padding-left-sm\@md {
      padding-left: var(--space-sm);
    }

    .padding-left-md\@md {
      padding-left: var(--space-md);
    }

    .padding-left-lg\@md {
      padding-left: var(--space-lg);
    }

    .padding-left-xl\@md {
      padding-left: var(--space-xl);
    }

    .padding-left-xxl\@md {
      padding-left: var(--space-xxl);
    }

    .padding-left-xxxl\@md {
      padding-left: var(--space-xxxl);
    }

    .padding-left-xxxxl\@md {
      padding-left: var(--space-xxxxl);
    }

    .padding-left-0\@md {
      padding-left: 0;
    }

    .padding-left-component\@md {
      padding-left: var(--component-padding);
    }

    .padding-x-xxxxs\@md {
      padding-left: var(--space-xxxxs);
      padding-right: var(--space-xxxxs);
    }

    .padding-x-xxxs\@md {
      padding-left: var(--space-xxxs);
      padding-right: var(--space-xxxs);
    }

    .padding-x-xxs\@md {
      padding-left: var(--space-xxs);
      padding-right: var(--space-xxs);
    }

    .padding-x-xs\@md {
      padding-left: var(--space-xs);
      padding-right: var(--space-xs);
    }

    .padding-x-sm\@md {
      padding-left: var(--space-sm);
      padding-right: var(--space-sm);
    }

    .padding-x-md\@md {
      padding-left: var(--space-md);
      padding-right: var(--space-md);
    }

    .padding-x-lg\@md {
      padding-left: var(--space-lg);
      padding-right: var(--space-lg);
    }

    .padding-x-xl\@md {
      padding-left: var(--space-xl);
      padding-right: var(--space-xl);
    }

    .padding-x-xxl\@md {
      padding-left: var(--space-xxl);
      padding-right: var(--space-xxl);
    }

    .padding-x-xxxl\@md {
      padding-left: var(--space-xxxl);
      padding-right: var(--space-xxxl);
    }

    .padding-x-xxxxl\@md {
      padding-left: var(--space-xxxxl);
      padding-right: var(--space-xxxxl);
    }

    .padding-x-0\@md {
      padding-left: 0;
      padding-right: 0;
    }

    .padding-x-component\@md {
      padding-left: var(--component-padding);
      padding-right: var(--component-padding);
    }

    .padding-y-xxxxs\@md {
      padding-top: var(--space-xxxxs);
      padding-bottom: var(--space-xxxxs);
    }

    .padding-y-xxxs\@md {
      padding-top: var(--space-xxxs);
      padding-bottom: var(--space-xxxs);
    }

    .padding-y-xxs\@md {
      padding-top: var(--space-xxs);
      padding-bottom: var(--space-xxs);
    }

    .padding-y-xs\@md {
      padding-top: var(--space-xs);
      padding-bottom: var(--space-xs);
    }

    .padding-y-sm\@md {
      padding-top: var(--space-sm);
      padding-bottom: var(--space-sm);
    }

    .padding-y-md\@md {
      padding-top: var(--space-md);
      padding-bottom: var(--space-md);
    }

    .padding-y-lg\@md {
      padding-top: var(--space-lg);
      padding-bottom: var(--space-lg);
    }

    .padding-y-xl\@md {
      padding-top: var(--space-xl);
      padding-bottom: var(--space-xl);
    }

    .padding-y-xxl\@md {
      padding-top: var(--space-xxl);
      padding-bottom: var(--space-xxl);
    }

    .padding-y-xxxl\@md {
      padding-top: var(--space-xxxl);
      padding-bottom: var(--space-xxxl);
    }

    .padding-y-xxxxl\@md {
      padding-top: var(--space-xxxxl);
      padding-bottom: var(--space-xxxxl);
    }

    .padding-y-0\@md {
      padding-top: 0;
      padding-bottom: 0;
    }

    .padding-y-component\@md {
      padding-top: var(--component-padding);
      padding-bottom: var(--component-padding);
    }
}

  .text-center\@md {
    text-align: center;
  }

  .text-left\@md {
    text-align: left;
  }

  .text-right\@md {
    text-align: right;
  }

  .text-justify\@md {
    text-align: justify;
  }

@supports (--css: variables) {
    .text-xs\@md {
      font-size: var(--text-xs, 0.694em);
    }

    .text-sm\@md {
      font-size: var(--text-sm, 0.833em);
    }

    .text-base\@md {
      font-size: 1em;
    }

    .text-md\@md {
      font-size: var(--text-md, 1.2em);
    }

    .text-lg\@md {
      font-size: var(--text-lg, 1.44em);
    }

    .text-xl\@md {
      font-size: var(--text-xl, 1.728em);
    }

    .text-xxl\@md {
      font-size: var(--text-xxl, 2.074em);
    }

    .text-xxxl\@md {
      font-size: var(--text-xxxl, 2.488em);
    }

    .text-xxxxl\@md {
      font-size: var(--text-xxxxl, 2.985em);
    }
}

  .hide\@md {
    display: none !important;
  }
}

@media not all and (min-width: 64rem) {
  .has-margin\@md {
    margin: 0 !important;
  }

  .has-padding\@md {
    padding: 0 !important;
  }

  .display\@md {
    display: none !important;
  }
}

@media (min-width: 80rem) {
  .flex\@lg {
    display: flex;
  }

  .inline-flex\@lg {
    display: inline-flex;
  }

  .flex-wrap\@lg {
    flex-wrap: wrap;
  }

  .flex-column\@lg {
    flex-direction: column;
  }

  .flex-column-reverse\@lg {
    flex-direction: column-reverse;
  }

  .flex-row\@lg {
    flex-direction: row;
  }

  .flex-row-reverse\@lg {
    flex-direction: row-reverse;
  }

  .flex-center\@lg {
    justify-content: center;
    align-items: center;
  }

  .flex-grow-0\@lg {
    flex-grow: 0;
  }

  .flex-grow\@lg {
    flex-grow: 1;
  }

  .flex-shrink-0\@lg {
    flex-shrink: 0;
  }

  .flex-shrink\@lg {
    flex-shrink: 1;
  }

  .justify-start\@lg {
    justify-content: flex-start;
  }

  .justify-end\@lg {
    justify-content: flex-end;
  }

  .justify-center\@lg {
    justify-content: center;
  }

  .justify-between\@lg {
    justify-content: space-between;
  }

  .items-center\@lg {
    align-items: center;
  }

  .items-start\@lg {
    align-items: flex-start;
  }

  .items-end\@lg {
    align-items: flex-end;
  }

  .items-baseline\@lg {
    align-items: baseline;
  }

  .order-1\@lg {
    order: 1;
  }

  .order-2\@lg {
    order: 2;
  }

  .order-3\@lg {
    order: 3;
  }

  .block\@lg {
    display: block;
  }

  .inline-block\@lg {
    display: inline-block;
  }

  .inline\@lg {
    display: inline;
  }

@supports (--css: variables) {
    .margin-xxxxs\@lg {
      margin: var(--space-xxxxs);
    }

    .margin-xxxs\@lg {
      margin: var(--space-xxxs);
    }

    .margin-xxs\@lg {
      margin: var(--space-xxs);
    }

    .margin-xs\@lg {
      margin: var(--space-xs);
    }

    .margin-sm\@lg {
      margin: var(--space-sm);
    }

    .margin-md\@lg {
      margin: var(--space-md);
    }

    .margin-lg\@lg {
      margin: var(--space-lg);
    }

    .margin-xl\@lg {
      margin: var(--space-xl);
    }

    .margin-xxl\@lg {
      margin: var(--space-xxl);
    }

    .margin-xxxl\@lg {
      margin: var(--space-xxxl);
    }

    .margin-xxxxl\@lg {
      margin: var(--space-xxxxl);
    }

    .margin-auto\@lg {
      margin: auto;
    }

    .margin-0\@lg {
      margin: 0;
    }

    .margin-top-xxxxs\@lg {
      margin-top: var(--space-xxxxs);
    }

    .margin-top-xxxs\@lg {
      margin-top: var(--space-xxxs);
    }

    .margin-top-xxs\@lg {
      margin-top: var(--space-xxs);
    }

    .margin-top-xs\@lg {
      margin-top: var(--space-xs);
    }

    .margin-top-sm\@lg {
      margin-top: var(--space-sm);
    }

    .margin-top-md\@lg {
      margin-top: var(--space-md);
    }

    .margin-top-lg\@lg {
      margin-top: var(--space-lg);
    }

    .margin-top-xl\@lg {
      margin-top: var(--space-xl);
    }

    .margin-top-xxl\@lg {
      margin-top: var(--space-xxl);
    }

    .margin-top-xxxl\@lg {
      margin-top: var(--space-xxxl);
    }

    .margin-top-xxxxl\@lg {
      margin-top: var(--space-xxxxl);
    }

    .margin-top-auto\@lg {
      margin-top: auto;
    }

    .margin-top-0\@lg {
      margin-top: 0;
    }

    .margin-bottom-xxxxs\@lg {
      margin-bottom: var(--space-xxxxs);
    }

    .margin-bottom-xxxs\@lg {
      margin-bottom: var(--space-xxxs);
    }

    .margin-bottom-xxs\@lg {
      margin-bottom: var(--space-xxs);
    }

    .margin-bottom-xs\@lg {
      margin-bottom: var(--space-xs);
    }

    .margin-bottom-sm\@lg {
      margin-bottom: var(--space-sm);
    }

    .margin-bottom-md\@lg {
      margin-bottom: var(--space-md);
    }

    .margin-bottom-lg\@lg {
      margin-bottom: var(--space-lg);
    }

    .margin-bottom-xl\@lg {
      margin-bottom: var(--space-xl);
    }

    .margin-bottom-xxl\@lg {
      margin-bottom: var(--space-xxl);
    }

    .margin-bottom-xxxl\@lg {
      margin-bottom: var(--space-xxxl);
    }

    .margin-bottom-xxxxl\@lg {
      margin-bottom: var(--space-xxxxl);
    }

    .margin-bottom-auto\@lg {
      margin-bottom: auto;
    }

    .margin-bottom-0\@lg {
      margin-bottom: 0;
    }

    .margin-right-xxxxs\@lg {
      margin-right: var(--space-xxxxs);
    }

    .margin-right-xxxs\@lg {
      margin-right: var(--space-xxxs);
    }

    .margin-right-xxs\@lg {
      margin-right: var(--space-xxs);
    }

    .margin-right-xs\@lg {
      margin-right: var(--space-xs);
    }

    .margin-right-sm\@lg {
      margin-right: var(--space-sm);
    }

    .margin-right-md\@lg {
      margin-right: var(--space-md);
    }

    .margin-right-lg\@lg {
      margin-right: var(--space-lg);
    }

    .margin-right-xl\@lg {
      margin-right: var(--space-xl);
    }

    .margin-right-xxl\@lg {
      margin-right: var(--space-xxl);
    }

    .margin-right-xxxl\@lg {
      margin-right: var(--space-xxxl);
    }

    .margin-right-xxxxl\@lg {
      margin-right: var(--space-xxxxl);
    }

    .margin-right-auto\@lg {
      margin-right: auto;
    }

    .margin-right-0\@lg {
      margin-right: 0;
    }

    .margin-left-xxxxs\@lg {
      margin-left: var(--space-xxxxs);
    }

    .margin-left-xxxs\@lg {
      margin-left: var(--space-xxxs);
    }

    .margin-left-xxs\@lg {
      margin-left: var(--space-xxs);
    }

    .margin-left-xs\@lg {
      margin-left: var(--space-xs);
    }

    .margin-left-sm\@lg {
      margin-left: var(--space-sm);
    }

    .margin-left-md\@lg {
      margin-left: var(--space-md);
    }

    .margin-left-lg\@lg {
      margin-left: var(--space-lg);
    }

    .margin-left-xl\@lg {
      margin-left: var(--space-xl);
    }

    .margin-left-xxl\@lg {
      margin-left: var(--space-xxl);
    }

    .margin-left-xxxl\@lg {
      margin-left: var(--space-xxxl);
    }

    .margin-left-xxxxl\@lg {
      margin-left: var(--space-xxxxl);
    }

    .margin-left-auto\@lg {
      margin-left: auto;
    }

    .margin-left-0\@lg {
      margin-left: 0;
    }

    .margin-x-xxxxs\@lg {
      margin-left: var(--space-xxxxs);
      margin-right: var(--space-xxxxs);
    }

    .margin-x-xxxs\@lg {
      margin-left: var(--space-xxxs);
      margin-right: var(--space-xxxs);
    }

    .margin-x-xxs\@lg {
      margin-left: var(--space-xxs);
      margin-right: var(--space-xxs);
    }

    .margin-x-xs\@lg {
      margin-left: var(--space-xs);
      margin-right: var(--space-xs);
    }

    .margin-x-sm\@lg {
      margin-left: var(--space-sm);
      margin-right: var(--space-sm);
    }

    .margin-x-md\@lg {
      margin-left: var(--space-md);
      margin-right: var(--space-md);
    }

    .margin-x-lg\@lg {
      margin-left: var(--space-lg);
      margin-right: var(--space-lg);
    }

    .margin-x-xl\@lg {
      margin-left: var(--space-xl);
      margin-right: var(--space-xl);
    }

    .margin-x-xxl\@lg {
      margin-left: var(--space-xxl);
      margin-right: var(--space-xxl);
    }

    .margin-x-xxxl\@lg {
      margin-left: var(--space-xxxl);
      margin-right: var(--space-xxxl);
    }

    .margin-x-xxxxl\@lg {
      margin-left: var(--space-xxxxl);
      margin-right: var(--space-xxxxl);
    }

    .margin-x-auto\@lg {
      margin-left: auto;
      margin-right: auto;
    }

    .margin-x-0\@lg {
      margin-left: 0;
      margin-right: 0;
    }

    .margin-y-xxxxs\@lg {
      margin-top: var(--space-xxxxs);
      margin-bottom: var(--space-xxxxs);
    }

    .margin-y-xxxs\@lg {
      margin-top: var(--space-xxxs);
      margin-bottom: var(--space-xxxs);
    }

    .margin-y-xxs\@lg {
      margin-top: var(--space-xxs);
      margin-bottom: var(--space-xxs);
    }

    .margin-y-xs\@lg {
      margin-top: var(--space-xs);
      margin-bottom: var(--space-xs);
    }

    .margin-y-sm\@lg {
      margin-top: var(--space-sm);
      margin-bottom: var(--space-sm);
    }

    .margin-y-md\@lg {
      margin-top: var(--space-md);
      margin-bottom: var(--space-md);
    }

    .margin-y-lg\@lg {
      margin-top: var(--space-lg);
      margin-bottom: var(--space-lg);
    }

    .margin-y-xl\@lg {
      margin-top: var(--space-xl);
      margin-bottom: var(--space-xl);
    }

    .margin-y-xxl\@lg {
      margin-top: var(--space-xxl);
      margin-bottom: var(--space-xxl);
    }

    .margin-y-xxxl\@lg {
      margin-top: var(--space-xxxl);
      margin-bottom: var(--space-xxxl);
    }

    .margin-y-xxxxl\@lg {
      margin-top: var(--space-xxxxl);
      margin-bottom: var(--space-xxxxl);
    }

    .margin-y-auto\@lg {
      margin-top: auto;
      margin-bottom: auto;
    }

    .margin-y-0\@lg {
      margin-top: 0;
      margin-bottom: 0;
    }
}

@supports (--css: variables) {
    .padding-xxxxs\@lg {
      padding: var(--space-xxxxs);
    }

    .padding-xxxs\@lg {
      padding: var(--space-xxxs);
    }

    .padding-xxs\@lg {
      padding: var(--space-xxs);
    }

    .padding-xs\@lg {
      padding: var(--space-xs);
    }

    .padding-sm\@lg {
      padding: var(--space-sm);
    }

    .padding-md\@lg {
      padding: var(--space-md);
    }

    .padding-lg\@lg {
      padding: var(--space-lg);
    }

    .padding-xl\@lg {
      padding: var(--space-xl);
    }

    .padding-xxl\@lg {
      padding: var(--space-xxl);
    }

    .padding-xxxl\@lg {
      padding: var(--space-xxxl);
    }

    .padding-xxxxl\@lg {
      padding: var(--space-xxxxl);
    }

    .padding-0\@lg {
      padding: 0;
    }

    .padding-component\@lg {
      padding: var(--component-padding);
    }

    .padding-top-xxxxs\@lg {
      padding-top: var(--space-xxxxs);
    }

    .padding-top-xxxs\@lg {
      padding-top: var(--space-xxxs);
    }

    .padding-top-xxs\@lg {
      padding-top: var(--space-xxs);
    }

    .padding-top-xs\@lg {
      padding-top: var(--space-xs);
    }

    .padding-top-sm\@lg {
      padding-top: var(--space-sm);
    }

    .padding-top-md\@lg {
      padding-top: var(--space-md);
    }

    .padding-top-lg\@lg {
      padding-top: var(--space-lg);
    }

    .padding-top-xl\@lg {
      padding-top: var(--space-xl);
    }

    .padding-top-xxl\@lg {
      padding-top: var(--space-xxl);
    }

    .padding-top-xxxl\@lg {
      padding-top: var(--space-xxxl);
    }

    .padding-top-xxxxl\@lg {
      padding-top: var(--space-xxxxl);
    }

    .padding-top-0\@lg {
      padding-top: 0;
    }

    .padding-top-component\@lg {
      padding-top: var(--component-padding);
    }

    .padding-bottom-xxxxs\@lg {
      padding-bottom: var(--space-xxxxs);
    }

    .padding-bottom-xxxs\@lg {
      padding-bottom: var(--space-xxxs);
    }

    .padding-bottom-xxs\@lg {
      padding-bottom: var(--space-xxs);
    }

    .padding-bottom-xs\@lg {
      padding-bottom: var(--space-xs);
    }

    .padding-bottom-sm\@lg {
      padding-bottom: var(--space-sm);
    }

    .padding-bottom-md\@lg {
      padding-bottom: var(--space-md);
    }

    .padding-bottom-lg\@lg {
      padding-bottom: var(--space-lg);
    }

    .padding-bottom-xl\@lg {
      padding-bottom: var(--space-xl);
    }

    .padding-bottom-xxl\@lg {
      padding-bottom: var(--space-xxl);
    }

    .padding-bottom-xxxl\@lg {
      padding-bottom: var(--space-xxxl);
    }

    .padding-bottom-xxxxl\@lg {
      padding-bottom: var(--space-xxxxl);
    }

    .padding-bottom-0\@lg {
      padding-bottom: 0;
    }

    .padding-bottom-component\@lg {
      padding-bottom: var(--component-padding);
    }

    .padding-right-xxxxs\@lg {
      padding-right: var(--space-xxxxs);
    }

    .padding-right-xxxs\@lg {
      padding-right: var(--space-xxxs);
    }

    .padding-right-xxs\@lg {
      padding-right: var(--space-xxs);
    }

    .padding-right-xs\@lg {
      padding-right: var(--space-xs);
    }

    .padding-right-sm\@lg {
      padding-right: var(--space-sm);
    }

    .padding-right-md\@lg {
      padding-right: var(--space-md);
    }

    .padding-right-lg\@lg {
      padding-right: var(--space-lg);
    }

    .padding-right-xl\@lg {
      padding-right: var(--space-xl);
    }

    .padding-right-xxl\@lg {
      padding-right: var(--space-xxl);
    }

    .padding-right-xxxl\@lg {
      padding-right: var(--space-xxxl);
    }

    .padding-right-xxxxl\@lg {
      padding-right: var(--space-xxxxl);
    }

    .padding-right-0\@lg {
      padding-right: 0;
    }

    .padding-right-component\@lg {
      padding-right: var(--component-padding);
    }

    .padding-left-xxxxs\@lg {
      padding-left: var(--space-xxxxs);
    }

    .padding-left-xxxs\@lg {
      padding-left: var(--space-xxxs);
    }

    .padding-left-xxs\@lg {
      padding-left: var(--space-xxs);
    }

    .padding-left-xs\@lg {
      padding-left: var(--space-xs);
    }

    .padding-left-sm\@lg {
      padding-left: var(--space-sm);
    }

    .padding-left-md\@lg {
      padding-left: var(--space-md);
    }

    .padding-left-lg\@lg {
      padding-left: var(--space-lg);
    }

    .padding-left-xl\@lg {
      padding-left: var(--space-xl);
    }

    .padding-left-xxl\@lg {
      padding-left: var(--space-xxl);
    }

    .padding-left-xxxl\@lg {
      padding-left: var(--space-xxxl);
    }

    .padding-left-xxxxl\@lg {
      padding-left: var(--space-xxxxl);
    }

    .padding-left-0\@lg {
      padding-left: 0;
    }

    .padding-left-component\@lg {
      padding-left: var(--component-padding);
    }

    .padding-x-xxxxs\@lg {
      padding-left: var(--space-xxxxs);
      padding-right: var(--space-xxxxs);
    }

    .padding-x-xxxs\@lg {
      padding-left: var(--space-xxxs);
      padding-right: var(--space-xxxs);
    }

    .padding-x-xxs\@lg {
      padding-left: var(--space-xxs);
      padding-right: var(--space-xxs);
    }

    .padding-x-xs\@lg {
      padding-left: var(--space-xs);
      padding-right: var(--space-xs);
    }

    .padding-x-sm\@lg {
      padding-left: var(--space-sm);
      padding-right: var(--space-sm);
    }

    .padding-x-md\@lg {
      padding-left: var(--space-md);
      padding-right: var(--space-md);
    }

    .padding-x-lg\@lg {
      padding-left: var(--space-lg);
      padding-right: var(--space-lg);
    }

    .padding-x-xl\@lg {
      padding-left: var(--space-xl);
      padding-right: var(--space-xl);
    }

    .padding-x-xxl\@lg {
      padding-left: var(--space-xxl);
      padding-right: var(--space-xxl);
    }

    .padding-x-xxxl\@lg {
      padding-left: var(--space-xxxl);
      padding-right: var(--space-xxxl);
    }

    .padding-x-xxxxl\@lg {
      padding-left: var(--space-xxxxl);
      padding-right: var(--space-xxxxl);
    }

    .padding-x-0\@lg {
      padding-left: 0;
      padding-right: 0;
    }

    .padding-x-component\@lg {
      padding-left: var(--component-padding);
      padding-right: var(--component-padding);
    }

    .padding-y-xxxxs\@lg {
      padding-top: var(--space-xxxxs);
      padding-bottom: var(--space-xxxxs);
    }

    .padding-y-xxxs\@lg {
      padding-top: var(--space-xxxs);
      padding-bottom: var(--space-xxxs);
    }

    .padding-y-xxs\@lg {
      padding-top: var(--space-xxs);
      padding-bottom: var(--space-xxs);
    }

    .padding-y-xs\@lg {
      padding-top: var(--space-xs);
      padding-bottom: var(--space-xs);
    }

    .padding-y-sm\@lg {
      padding-top: var(--space-sm);
      padding-bottom: var(--space-sm);
    }

    .padding-y-md\@lg {
      padding-top: var(--space-md);
      padding-bottom: var(--space-md);
    }

    .padding-y-lg\@lg {
      padding-top: var(--space-lg);
      padding-bottom: var(--space-lg);
    }

    .padding-y-xl\@lg {
      padding-top: var(--space-xl);
      padding-bottom: var(--space-xl);
    }

    .padding-y-xxl\@lg {
      padding-top: var(--space-xxl);
      padding-bottom: var(--space-xxl);
    }

    .padding-y-xxxl\@lg {
      padding-top: var(--space-xxxl);
      padding-bottom: var(--space-xxxl);
    }

    .padding-y-xxxxl\@lg {
      padding-top: var(--space-xxxxl);
      padding-bottom: var(--space-xxxxl);
    }

    .padding-y-0\@lg {
      padding-top: 0;
      padding-bottom: 0;
    }

    .padding-y-component\@lg {
      padding-top: var(--component-padding);
      padding-bottom: var(--component-padding);
    }
}

  .text-center\@lg {
    text-align: center;
  }

  .text-left\@lg {
    text-align: left;
  }

  .text-right\@lg {
    text-align: right;
  }

  .text-justify\@lg {
    text-align: justify;
  }

@supports (--css: variables) {
    .text-xs\@lg {
      font-size: var(--text-xs, 0.694em);
    }

    .text-sm\@lg {
      font-size: var(--text-sm, 0.833em);
    }

    .text-base\@lg {
      font-size: 1em;
    }

    .text-md\@lg {
      font-size: var(--text-md, 1.2em);
    }

    .text-lg\@lg {
      font-size: var(--text-lg, 1.44em);
    }

    .text-xl\@lg {
      font-size: var(--text-xl, 1.728em);
    }

    .text-xxl\@lg {
      font-size: var(--text-xxl, 2.074em);
    }

    .text-xxxl\@lg {
      font-size: var(--text-xxxl, 2.488em);
    }

    .text-xxxxl\@lg {
      font-size: var(--text-xxxxl, 2.985em);
    }
}

  .hide\@lg {
    display: none !important;
  }
}

@media not all and (min-width: 80rem) {
  .has-margin\@lg {
    margin: 0 !important;
  }

  .has-padding\@lg {
    padding: 0 !important;
  }

  .display\@lg {
    display: none !important;
  }
}

@media (min-width: 90rem) {
  .flex\@xl {
    display: flex;
  }

  .inline-flex\@xl {
    display: inline-flex;
  }

  .flex-wrap\@xl {
    flex-wrap: wrap;
  }

  .flex-column\@xl {
    flex-direction: column;
  }

  .flex-column-reverse\@xl {
    flex-direction: column-reverse;
  }

  .flex-row\@xl {
    flex-direction: row;
  }

  .flex-row-reverse\@xl {
    flex-direction: row-reverse;
  }

  .flex-center\@xl {
    justify-content: center;
    align-items: center;
  }

  .flex-grow-0\@xl {
    flex-grow: 0;
  }

  .flex-grow\@xl {
    flex-grow: 1;
  }

  .flex-shrink-0\@xl {
    flex-shrink: 0;
  }

  .flex-shrink\@xl {
    flex-shrink: 1;
  }

  .justify-start\@xl {
    justify-content: flex-start;
  }

  .justify-end\@xl {
    justify-content: flex-end;
  }

  .justify-center\@xl {
    justify-content: center;
  }

  .justify-between\@xl {
    justify-content: space-between;
  }

  .items-center\@xl {
    align-items: center;
  }

  .items-start\@xl {
    align-items: flex-start;
  }

  .items-end\@xl {
    align-items: flex-end;
  }

  .items-baseline\@xl {
    align-items: baseline;
  }

  .order-1\@xl {
    order: 1;
  }

  .order-2\@xl {
    order: 2;
  }

  .order-3\@xl {
    order: 3;
  }

  .block\@xl {
    display: block;
  }

  .inline-block\@xl {
    display: inline-block;
  }

  .inline\@xl {
    display: inline;
  }

@supports (--css: variables) {
    .margin-xxxxs\@xl {
      margin: var(--space-xxxxs);
    }

    .margin-xxxs\@xl {
      margin: var(--space-xxxs);
    }

    .margin-xxs\@xl {
      margin: var(--space-xxs);
    }

    .margin-xs\@xl {
      margin: var(--space-xs);
    }

    .margin-sm\@xl {
      margin: var(--space-sm);
    }

    .margin-md\@xl {
      margin: var(--space-md);
    }

    .margin-lg\@xl {
      margin: var(--space-lg);
    }

    .margin-xl\@xl {
      margin: var(--space-xl);
    }

    .margin-xxl\@xl {
      margin: var(--space-xxl);
    }

    .margin-xxxl\@xl {
      margin: var(--space-xxxl);
    }

    .margin-xxxxl\@xl {
      margin: var(--space-xxxxl);
    }

    .margin-auto\@xl {
      margin: auto;
    }

    .margin-0\@xl {
      margin: 0;
    }

    .margin-top-xxxxs\@xl {
      margin-top: var(--space-xxxxs);
    }

    .margin-top-xxxs\@xl {
      margin-top: var(--space-xxxs);
    }

    .margin-top-xxs\@xl {
      margin-top: var(--space-xxs);
    }

    .margin-top-xs\@xl {
      margin-top: var(--space-xs);
    }

    .margin-top-sm\@xl {
      margin-top: var(--space-sm);
    }

    .margin-top-md\@xl {
      margin-top: var(--space-md);
    }

    .margin-top-lg\@xl {
      margin-top: var(--space-lg);
    }

    .margin-top-xl\@xl {
      margin-top: var(--space-xl);
    }

    .margin-top-xxl\@xl {
      margin-top: var(--space-xxl);
    }

    .margin-top-xxxl\@xl {
      margin-top: var(--space-xxxl);
    }

    .margin-top-xxxxl\@xl {
      margin-top: var(--space-xxxxl);
    }

    .margin-top-auto\@xl {
      margin-top: auto;
    }

    .margin-top-0\@xl {
      margin-top: 0;
    }

    .margin-bottom-xxxxs\@xl {
      margin-bottom: var(--space-xxxxs);
    }

    .margin-bottom-xxxs\@xl {
      margin-bottom: var(--space-xxxs);
    }

    .margin-bottom-xxs\@xl {
      margin-bottom: var(--space-xxs);
    }

    .margin-bottom-xs\@xl {
      margin-bottom: var(--space-xs);
    }

    .margin-bottom-sm\@xl {
      margin-bottom: var(--space-sm);
    }

    .margin-bottom-md\@xl {
      margin-bottom: var(--space-md);
    }

    .margin-bottom-lg\@xl {
      margin-bottom: var(--space-lg);
    }

    .margin-bottom-xl\@xl {
      margin-bottom: var(--space-xl);
    }

    .margin-bottom-xxl\@xl {
      margin-bottom: var(--space-xxl);
    }

    .margin-bottom-xxxl\@xl {
      margin-bottom: var(--space-xxxl);
    }

    .margin-bottom-xxxxl\@xl {
      margin-bottom: var(--space-xxxxl);
    }

    .margin-bottom-auto\@xl {
      margin-bottom: auto;
    }

    .margin-bottom-0\@xl {
      margin-bottom: 0;
    }

    .margin-right-xxxxs\@xl {
      margin-right: var(--space-xxxxs);
    }

    .margin-right-xxxs\@xl {
      margin-right: var(--space-xxxs);
    }

    .margin-right-xxs\@xl {
      margin-right: var(--space-xxs);
    }

    .margin-right-xs\@xl {
      margin-right: var(--space-xs);
    }

    .margin-right-sm\@xl {
      margin-right: var(--space-sm);
    }

    .margin-right-md\@xl {
      margin-right: var(--space-md);
    }

    .margin-right-lg\@xl {
      margin-right: var(--space-lg);
    }

    .margin-right-xl\@xl {
      margin-right: var(--space-xl);
    }

    .margin-right-xxl\@xl {
      margin-right: var(--space-xxl);
    }

    .margin-right-xxxl\@xl {
      margin-right: var(--space-xxxl);
    }

    .margin-right-xxxxl\@xl {
      margin-right: var(--space-xxxxl);
    }

    .margin-right-auto\@xl {
      margin-right: auto;
    }

    .margin-right-0\@xl {
      margin-right: 0;
    }

    .margin-left-xxxxs\@xl {
      margin-left: var(--space-xxxxs);
    }

    .margin-left-xxxs\@xl {
      margin-left: var(--space-xxxs);
    }

    .margin-left-xxs\@xl {
      margin-left: var(--space-xxs);
    }

    .margin-left-xs\@xl {
      margin-left: var(--space-xs);
    }

    .margin-left-sm\@xl {
      margin-left: var(--space-sm);
    }

    .margin-left-md\@xl {
      margin-left: var(--space-md);
    }

    .margin-left-lg\@xl {
      margin-left: var(--space-lg);
    }

    .margin-left-xl\@xl {
      margin-left: var(--space-xl);
    }

    .margin-left-xxl\@xl {
      margin-left: var(--space-xxl);
    }

    .margin-left-xxxl\@xl {
      margin-left: var(--space-xxxl);
    }

    .margin-left-xxxxl\@xl {
      margin-left: var(--space-xxxxl);
    }

    .margin-left-auto\@xl {
      margin-left: auto;
    }

    .margin-left-0\@xl {
      margin-left: 0;
    }

    .margin-x-xxxxs\@xl {
      margin-left: var(--space-xxxxs);
      margin-right: var(--space-xxxxs);
    }

    .margin-x-xxxs\@xl {
      margin-left: var(--space-xxxs);
      margin-right: var(--space-xxxs);
    }

    .margin-x-xxs\@xl {
      margin-left: var(--space-xxs);
      margin-right: var(--space-xxs);
    }

    .margin-x-xs\@xl {
      margin-left: var(--space-xs);
      margin-right: var(--space-xs);
    }

    .margin-x-sm\@xl {
      margin-left: var(--space-sm);
      margin-right: var(--space-sm);
    }

    .margin-x-md\@xl {
      margin-left: var(--space-md);
      margin-right: var(--space-md);
    }

    .margin-x-lg\@xl {
      margin-left: var(--space-lg);
      margin-right: var(--space-lg);
    }

    .margin-x-xl\@xl {
      margin-left: var(--space-xl);
      margin-right: var(--space-xl);
    }

    .margin-x-xxl\@xl {
      margin-left: var(--space-xxl);
      margin-right: var(--space-xxl);
    }

    .margin-x-xxxl\@xl {
      margin-left: var(--space-xxxl);
      margin-right: var(--space-xxxl);
    }

    .margin-x-xxxxl\@xl {
      margin-left: var(--space-xxxxl);
      margin-right: var(--space-xxxxl);
    }

    .margin-x-auto\@xl {
      margin-left: auto;
      margin-right: auto;
    }

    .margin-x-0\@xl {
      margin-left: 0;
      margin-right: 0;
    }

    .margin-y-xxxxs\@xl {
      margin-top: var(--space-xxxxs);
      margin-bottom: var(--space-xxxxs);
    }

    .margin-y-xxxs\@xl {
      margin-top: var(--space-xxxs);
      margin-bottom: var(--space-xxxs);
    }

    .margin-y-xxs\@xl {
      margin-top: var(--space-xxs);
      margin-bottom: var(--space-xxs);
    }

    .margin-y-xs\@xl {
      margin-top: var(--space-xs);
      margin-bottom: var(--space-xs);
    }

    .margin-y-sm\@xl {
      margin-top: var(--space-sm);
      margin-bottom: var(--space-sm);
    }

    .margin-y-md\@xl {
      margin-top: var(--space-md);
      margin-bottom: var(--space-md);
    }

    .margin-y-lg\@xl {
      margin-top: var(--space-lg);
      margin-bottom: var(--space-lg);
    }

    .margin-y-xl\@xl {
      margin-top: var(--space-xl);
      margin-bottom: var(--space-xl);
    }

    .margin-y-xxl\@xl {
      margin-top: var(--space-xxl);
      margin-bottom: var(--space-xxl);
    }

    .margin-y-xxxl\@xl {
      margin-top: var(--space-xxxl);
      margin-bottom: var(--space-xxxl);
    }

    .margin-y-xxxxl\@xl {
      margin-top: var(--space-xxxxl);
      margin-bottom: var(--space-xxxxl);
    }

    .margin-y-auto\@xl {
      margin-top: auto;
      margin-bottom: auto;
    }

    .margin-y-0\@xl {
      margin-top: 0;
      margin-bottom: 0;
    }
}

@supports (--css: variables) {
    .padding-xxxxs\@xl {
      padding: var(--space-xxxxs);
    }

    .padding-xxxs\@xl {
      padding: var(--space-xxxs);
    }

    .padding-xxs\@xl {
      padding: var(--space-xxs);
    }

    .padding-xs\@xl {
      padding: var(--space-xs);
    }

    .padding-sm\@xl {
      padding: var(--space-sm);
    }

    .padding-md\@xl {
      padding: var(--space-md);
    }

    .padding-lg\@xl {
      padding: var(--space-lg);
    }

    .padding-xl\@xl {
      padding: var(--space-xl);
    }

    .padding-xxl\@xl {
      padding: var(--space-xxl);
    }

    .padding-xxxl\@xl {
      padding: var(--space-xxxl);
    }

    .padding-xxxxl\@xl {
      padding: var(--space-xxxxl);
    }

    .padding-0\@xl {
      padding: 0;
    }

    .padding-component\@xl {
      padding: var(--component-padding);
    }

    .padding-top-xxxxs\@xl {
      padding-top: var(--space-xxxxs);
    }

    .padding-top-xxxs\@xl {
      padding-top: var(--space-xxxs);
    }

    .padding-top-xxs\@xl {
      padding-top: var(--space-xxs);
    }

    .padding-top-xs\@xl {
      padding-top: var(--space-xs);
    }

    .padding-top-sm\@xl {
      padding-top: var(--space-sm);
    }

    .padding-top-md\@xl {
      padding-top: var(--space-md);
    }

    .padding-top-lg\@xl {
      padding-top: var(--space-lg);
    }

    .padding-top-xl\@xl {
      padding-top: var(--space-xl);
    }

    .padding-top-xxl\@xl {
      padding-top: var(--space-xxl);
    }

    .padding-top-xxxl\@xl {
      padding-top: var(--space-xxxl);
    }

    .padding-top-xxxxl\@xl {
      padding-top: var(--space-xxxxl);
    }

    .padding-top-0\@xl {
      padding-top: 0;
    }

    .padding-top-component\@xl {
      padding-top: var(--component-padding);
    }

    .padding-bottom-xxxxs\@xl {
      padding-bottom: var(--space-xxxxs);
    }

    .padding-bottom-xxxs\@xl {
      padding-bottom: var(--space-xxxs);
    }

    .padding-bottom-xxs\@xl {
      padding-bottom: var(--space-xxs);
    }

    .padding-bottom-xs\@xl {
      padding-bottom: var(--space-xs);
    }

    .padding-bottom-sm\@xl {
      padding-bottom: var(--space-sm);
    }

    .padding-bottom-md\@xl {
      padding-bottom: var(--space-md);
    }

    .padding-bottom-lg\@xl {
      padding-bottom: var(--space-lg);
    }

    .padding-bottom-xl\@xl {
      padding-bottom: var(--space-xl);
    }

    .padding-bottom-xxl\@xl {
      padding-bottom: var(--space-xxl);
    }

    .padding-bottom-xxxl\@xl {
      padding-bottom: var(--space-xxxl);
    }

    .padding-bottom-xxxxl\@xl {
      padding-bottom: var(--space-xxxxl);
    }

    .padding-bottom-0\@xl {
      padding-bottom: 0;
    }

    .padding-bottom-component\@xl {
      padding-bottom: var(--component-padding);
    }

    .padding-right-xxxxs\@xl {
      padding-right: var(--space-xxxxs);
    }

    .padding-right-xxxs\@xl {
      padding-right: var(--space-xxxs);
    }

    .padding-right-xxs\@xl {
      padding-right: var(--space-xxs);
    }

    .padding-right-xs\@xl {
      padding-right: var(--space-xs);
    }

    .padding-right-sm\@xl {
      padding-right: var(--space-sm);
    }

    .padding-right-md\@xl {
      padding-right: var(--space-md);
    }

    .padding-right-lg\@xl {
      padding-right: var(--space-lg);
    }

    .padding-right-xl\@xl {
      padding-right: var(--space-xl);
    }

    .padding-right-xxl\@xl {
      padding-right: var(--space-xxl);
    }

    .padding-right-xxxl\@xl {
      padding-right: var(--space-xxxl);
    }

    .padding-right-xxxxl\@xl {
      padding-right: var(--space-xxxxl);
    }

    .padding-right-0\@xl {
      padding-right: 0;
    }

    .padding-right-component\@xl {
      padding-right: var(--component-padding);
    }

    .padding-left-xxxxs\@xl {
      padding-left: var(--space-xxxxs);
    }

    .padding-left-xxxs\@xl {
      padding-left: var(--space-xxxs);
    }

    .padding-left-xxs\@xl {
      padding-left: var(--space-xxs);
    }

    .padding-left-xs\@xl {
      padding-left: var(--space-xs);
    }

    .padding-left-sm\@xl {
      padding-left: var(--space-sm);
    }

    .padding-left-md\@xl {
      padding-left: var(--space-md);
    }

    .padding-left-lg\@xl {
      padding-left: var(--space-lg);
    }

    .padding-left-xl\@xl {
      padding-left: var(--space-xl);
    }

    .padding-left-xxl\@xl {
      padding-left: var(--space-xxl);
    }

    .padding-left-xxxl\@xl {
      padding-left: var(--space-xxxl);
    }

    .padding-left-xxxxl\@xl {
      padding-left: var(--space-xxxxl);
    }

    .padding-left-0\@xl {
      padding-left: 0;
    }

    .padding-left-component\@xl {
      padding-left: var(--component-padding);
    }

    .padding-x-xxxxs\@xl {
      padding-left: var(--space-xxxxs);
      padding-right: var(--space-xxxxs);
    }

    .padding-x-xxxs\@xl {
      padding-left: var(--space-xxxs);
      padding-right: var(--space-xxxs);
    }

    .padding-x-xxs\@xl {
      padding-left: var(--space-xxs);
      padding-right: var(--space-xxs);
    }

    .padding-x-xs\@xl {
      padding-left: var(--space-xs);
      padding-right: var(--space-xs);
    }

    .padding-x-sm\@xl {
      padding-left: var(--space-sm);
      padding-right: var(--space-sm);
    }

    .padding-x-md\@xl {
      padding-left: var(--space-md);
      padding-right: var(--space-md);
    }

    .padding-x-lg\@xl {
      padding-left: var(--space-lg);
      padding-right: var(--space-lg);
    }

    .padding-x-xl\@xl {
      padding-left: var(--space-xl);
      padding-right: var(--space-xl);
    }

    .padding-x-xxl\@xl {
      padding-left: var(--space-xxl);
      padding-right: var(--space-xxl);
    }

    .padding-x-xxxl\@xl {
      padding-left: var(--space-xxxl);
      padding-right: var(--space-xxxl);
    }

    .padding-x-xxxxl\@xl {
      padding-left: var(--space-xxxxl);
      padding-right: var(--space-xxxxl);
    }

    .padding-x-0\@xl {
      padding-left: 0;
      padding-right: 0;
    }

    .padding-x-component\@xl {
      padding-left: var(--component-padding);
      padding-right: var(--component-padding);
    }

    .padding-y-xxxxs\@xl {
      padding-top: var(--space-xxxxs);
      padding-bottom: var(--space-xxxxs);
    }

    .padding-y-xxxs\@xl {
      padding-top: var(--space-xxxs);
      padding-bottom: var(--space-xxxs);
    }

    .padding-y-xxs\@xl {
      padding-top: var(--space-xxs);
      padding-bottom: var(--space-xxs);
    }

    .padding-y-xs\@xl {
      padding-top: var(--space-xs);
      padding-bottom: var(--space-xs);
    }

    .padding-y-sm\@xl {
      padding-top: var(--space-sm);
      padding-bottom: var(--space-sm);
    }

    .padding-y-md\@xl {
      padding-top: var(--space-md);
      padding-bottom: var(--space-md);
    }

    .padding-y-lg\@xl {
      padding-top: var(--space-lg);
      padding-bottom: var(--space-lg);
    }

    .padding-y-xl\@xl {
      padding-top: var(--space-xl);
      padding-bottom: var(--space-xl);
    }

    .padding-y-xxl\@xl {
      padding-top: var(--space-xxl);
      padding-bottom: var(--space-xxl);
    }

    .padding-y-xxxl\@xl {
      padding-top: var(--space-xxxl);
      padding-bottom: var(--space-xxxl);
    }

    .padding-y-xxxxl\@xl {
      padding-top: var(--space-xxxxl);
      padding-bottom: var(--space-xxxxl);
    }

    .padding-y-0\@xl {
      padding-top: 0;
      padding-bottom: 0;
    }

    .padding-y-component\@xl {
      padding-top: var(--component-padding);
      padding-bottom: var(--component-padding);
    }
}

  .text-center\@xl {
    text-align: center;
  }

  .text-left\@xl {
    text-align: left;
  }

  .text-right\@xl {
    text-align: right;
  }

  .text-justify\@xl {
    text-align: justify;
  }

@supports (--css: variables) {
    .text-xs\@xl {
      font-size: var(--text-xs, 0.694em);
    }

    .text-sm\@xl {
      font-size: var(--text-sm, 0.833em);
    }

    .text-base\@xl {
      font-size: 1em;
    }

    .text-md\@xl {
      font-size: var(--text-md, 1.2em);
    }

    .text-lg\@xl {
      font-size: var(--text-lg, 1.44em);
    }

    .text-xl\@xl {
      font-size: var(--text-xl, 1.728em);
    }

    .text-xxl\@xl {
      font-size: var(--text-xxl, 2.074em);
    }

    .text-xxxl\@xl {
      font-size: var(--text-xxxl, 2.488em);
    }

    .text-xxxxl\@xl {
      font-size: var(--text-xxxxl, 2.985em);
    }
}

  .hide\@xl {
    display: none !important;
  }
}

@media not all and (min-width: 90rem) {
  .has-margin\@xl {
    margin: 0 !important;
  }

  .has-padding\@xl {
    padding: 0 !important;
  }

  .display\@xl {
    display: none !important;
  }
}

.btn {
  --btn-font-size: var(--text-sm);
  --btn-padding-x: var(--space-md);
  --btn-padding-y: var(--space-sm);
  --btn-radius: var(--radius-sm);
  background-color: transparent;
  color: var(--color-primary-darker);
  cursor: pointer;
  border: 1px solid var(--color-primary-darker);
  font-family: var(--font-primary);
  font-weight: 500;
  text-transform: uppercase;
  text-decoration: none;
  line-height: 1.2;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  transition: all 0.2s ease;
}

.btn:hover,
.btn:focus {
  box-shadow: inset 0 0 0 1px currentColor;
}

.btn:active {
  transform: translateY(2px);
}

.btn--primary {
  background-color: var(--color-primary);
  color: var(--color-white);
  border: 1px solid var(--color-primary);
}

.btn--primary:hover {
  background-color: var(--color-primary-dark);
  box-shadow: none;
}

.btn--primary:focus {
  box-shadow: 0px 0px 0px 2px hsla(var(--color-primary-h), var(--color-primary-s), var(--color-primary-l), 0.2);
}

.btn--subtle {
  background-color: transparent;
  color: var(--color-black);
  border: 1px solid var(--color-black);
}

.btn--subtle:hover {
  box-shadow: inset 0 0 0 1px currentColor;
}

.btn--accent {
  background-color: var(--color-accent-dark);
  color: var(--color-white);
  border: 1px solid var(--color-accent-dark);
}

.btn--accent:hover {
  background-color: var(--color-accent-darker);
  box-shadow: none;
}

.btn--accent:focus {
  box-shadow: 0px 0px 0px 2px hsla(var(--color-accent-h), var(--color-accent-s), var(--color-accent-l), 0.2);
}

.btn--white {
  background-color: hsla(var(--color-white-h), var(--color-white-s), var(--color-white-l), 0.9);
  color: var(--color-accent-darker);
  border: 1px solid var(--color-white);
}

.btn--white:hover {
  background-color: var(--color-white);
}

.btn--black {
  background-color: var(--color-black);
  color: var(--color-white);
  border: 1px solid var(--color-black);
}

.btn--black:hover {
  background-color: hsla(var(--color-black-h), var(--color-black-s), var(--color-black-l), 0.7);
  border: 1px solid var(--color-black);
  box-shadow: none;
}

.btn--disabled,
.btn[disabled],
.btn[readonly] {
  background-color: var(--color-contrast-low);
  color: var(--color-bg);
  cursor: not-allowed;
  border: 0 solid var(--color-contrast-low);
  font-size: var(--text-xs);
}

.btn--disabled:hover,
.btn[disabled]:hover,
.btn[readonly]:hover {
  background-color: var(--color-contrast-low);
}

.btn--sm {
  padding-left: var(--space-sm);
  padding-right: var(--space-sm);
  font-size: var(--text-xs);
}

.btn--md {
  font-size: 1em;
}

.btn--lg {
  font-size: 1.25em;
}

.btn--icon {
  padding: var(--space-xs) var(--space-sm);
}

:root,
[data-theme=default] {
  --color-primary-darker: hsl(32, 33%, 27%);
  --color-primary-darker-h: 32;
  --color-primary-darker-s: 33%;
  --color-primary-darker-l: 27%;
  --color-primary-dark: hsl(32, 33%, 37%);
  --color-primary-dark-h: 32;
  --color-primary-dark-s: 33%;
  --color-primary-dark-l: 37%;
  --color-primary: hsl(32, 33%, 47%);
  --color-primary-h: 32;
  --color-primary-s: 33%;
  --color-primary-l: 47%;
  --color-primary-light: hsl(32, 33%, 57%);
  --color-primary-light-h: 32;
  --color-primary-light-s: 33%;
  --color-primary-light-l: 57%;
  --color-primary-lighter: hsl(32, 33%, 67%);
  --color-primary-lighter-h: 32;
  --color-primary-lighter-s: 33%;
  --color-primary-lighter-l: 67%;
  --color-accent-darker: hsl(30, 52%, 52%);
  --color-accent-darker-h: 30;
  --color-accent-darker-s: 52%;
  --color-accent-darker-l: 52%;
  --color-accent-dark: hsl(30, 52%, 62%);
  --color-accent-dark-h: 30;
  --color-accent-dark-s: 52%;
  --color-accent-dark-l: 62%;
  --color-accent: hsl(30, 52%, 72%);
  --color-accent-h: 30;
  --color-accent-s: 52%;
  --color-accent-l: 72%;
  --color-accent-light: hsl(30, 52%, 82%);
  --color-accent-light-h: 30;
  --color-accent-light-s: 52%;
  --color-accent-light-l: 82%;
  --color-accent-lighter: hsl(30, 52%, 92%);
  --color-accent-lighter-h: 30;
  --color-accent-lighter-s: 52%;
  --color-accent-lighter-l: 92%;
  --color-secondary-darker: hsl(29, 41%, 68%);
  --color-secondary-darker-h: 29;
  --color-secondary-darker-s: 41%;
  --color-secondary-darker-l: 68%;
  --color-secondary-dark: hsl(29, 41%, 78%);
  --color-secondary-dark-h: 29;
  --color-secondary-dark-s: 41%;
  --color-secondary-dark-l: 78%;
  --color-secondary: hsl(29, 41%, 88%);
  --color-secondary-h: 29;
  --color-secondary-s: 41%;
  --color-secondary-l: 88%;
  --color-secondary-light: hsl(29, 41%, 94%);
  --color-secondary-light-h: 29;
  --color-secondary-light-s: 41%;
  --color-secondary-light-l: 94%;
  --color-secondary-lighter: hsl(29, 41%, 100%);
  --color-secondary-lighter-h: 29;
  --color-secondary-lighter-s: 41%;
  --color-secondary-lighter-l: 100%;
  --color-tertiary-darker: hsl(23, 39%, 24%);
  --color-tertiary-darker-h: 23;
  --color-tertiary-darker-s: 39%;
  --color-tertiary-darker-l: 24%;
  --color-tertiary-dark: hsl(23, 39%, 34%);
  --color-tertiary-dark-h: 23;
  --color-tertiary-dark-s: 39%;
  --color-tertiary-dark-l: 34%;
  --color-tertiary: hsl(23, 39%, 44%);
  --color-tertiary-h: 23;
  --color-tertiary-s: 39%;
  --color-tertiary-l: 44%;
  --color-tertiary-light: hsl(23, 39%, 54%);
  --color-tertiary-light-h: 23;
  --color-tertiary-light-s: 39%;
  --color-tertiary-light-l: 54%;
  --color-tertiary-lighter: hsl(23, 39%, 64%);
  --color-tertiary-lighter-h: 23;
  --color-tertiary-lighter-s: 39%;
  --color-tertiary-lighter-l: 64%;
  --color-black: hsl(0, 0%, 0%);
  --color-black-h: 0;
  --color-black-s: 0%;
  --color-black-l: 0%;
  --color-white: hsl(0, 0%, 100%);
  --color-white-h: 0;
  --color-white-s: 0%;
  --color-white-l: 100%;
  --color-warning-darker: hsl(53, 62%, 51%);
  --color-warning-darker-h: 53;
  --color-warning-darker-s: 62%;
  --color-warning-darker-l: 51%;
  --color-warning-dark: hsl(53, 62%, 61%);
  --color-warning-dark-h: 53;
  --color-warning-dark-s: 62%;
  --color-warning-dark-l: 61%;
  --color-warning: hsl(53, 62%, 71%);
  --color-warning-h: 53;
  --color-warning-s: 62%;
  --color-warning-l: 71%;
  --color-warning-light: hsl(53, 62%, 81%);
  --color-warning-light-h: 53;
  --color-warning-light-s: 62%;
  --color-warning-light-l: 81%;
  --color-warning-lighter: hsl(53, 62%, 91%);
  --color-warning-lighter-h: 53;
  --color-warning-lighter-s: 62%;
  --color-warning-lighter-l: 91%;
  --color-success-darker: hsl(146, 31%, 17%);
  --color-success-darker-h: 146;
  --color-success-darker-s: 31%;
  --color-success-darker-l: 17%;
  --color-success-dark: hsl(146, 31%, 27%);
  --color-success-dark-h: 146;
  --color-success-dark-s: 31%;
  --color-success-dark-l: 27%;
  --color-success: hsl(146, 31%, 37%);
  --color-success-h: 146;
  --color-success-s: 31%;
  --color-success-l: 37%;
  --color-success-light: hsl(146, 31%, 47%);
  --color-success-light-h: 146;
  --color-success-light-s: 31%;
  --color-success-light-l: 47%;
  --color-success-lighter: hsl(146, 31%, 57%);
  --color-success-lighter-h: 146;
  --color-success-lighter-s: 31%;
  --color-success-lighter-l: 57%;
  --color-error-darker: hsl(348, 85%, 34%);
  --color-error-darker-h: 348;
  --color-error-darker-s: 85%;
  --color-error-darker-l: 34%;
  --color-error-dark: hsl(348, 85%, 44%);
  --color-error-dark-h: 348;
  --color-error-dark-s: 85%;
  --color-error-dark-l: 44%;
  --color-error: hsl(348, 85%, 54%);
  --color-error-h: 348;
  --color-error-s: 85%;
  --color-error-l: 54%;
  --color-error-light: hsl(348, 85%, 64%);
  --color-error-light-h: 348;
  --color-error-light-s: 85%;
  --color-error-light-l: 64%;
  --color-error-lighter: hsl(348, 85%, 74%);
  --color-error-lighter-h: 348;
  --color-error-lighter-s: 85%;
  --color-error-lighter-l: 74%;
  --color-bg: hsl(0, 0%, 100%);
  --color-bg-h: 0;
  --color-bg-s: 0%;
  --color-bg-l: 100%;
  --color-contrast-lower: hsl(0, 0%, 96%);
  --color-contrast-lower-h: 0;
  --color-contrast-lower-s: 0%;
  --color-contrast-lower-l: 96%;
  --color-contrast-low: hsl(0, 0%, 90%);
  --color-contrast-low-h: 0;
  --color-contrast-low-s: 0%;
  --color-contrast-low-l: 90%;
  --color-contrast-medium: hsl(0, 0%, 78%);
  --color-contrast-medium-h: 0;
  --color-contrast-medium-s: 0%;
  --color-contrast-medium-l: 78%;
  --color-contrast-high: hsl(0, 0%, 19%);
  --color-contrast-high-h: 0;
  --color-contrast-high-s: 0%;
  --color-contrast-high-l: 19%;
  --color-contrast-higher: hsl(0, 0%, 0%);
  --color-contrast-higher-h: 0;
  --color-contrast-higher-s: 0%;
  --color-contrast-higher-l: 0%;
}

.bg-secondary-darker {
  background-color: hsla(var(--color-secondary-darker-h), var(--color-secondary-darker-s), var(--color-secondary-darker-l), var(--bg-o, 1));
}

.bg-secondary-dark {
  background-color: hsla(var(--color-secondary-dark-h), var(--color-secondary-dark-s), var(--color-secondary-dark-l), var(--bg-o, 1));
}

.bg-secondary {
  background-color: hsla(var(--color-secondary-h), var(--color-secondary-s), var(--color-secondary-l), var(--bg-o, 1));
}

.bg-secondary-light {
  background-color: hsla(var(--color-secondary-light-h), var(--color-secondary-light-s), var(--color-secondary-light-l), var(--bg-o, 1));
}

.bg-secondary-lighter {
  background-color: hsla(var(--color-secondary-lighter-h), var(--color-secondary-lighter-s), var(--color-secondary-lighter-l), var(--bg-o, 1));
}

.bg-tertiary-darker {
  background-color: hsla(var(--color-tertiary-darker-h), var(--color-tertiary-darker-s), var(--color-tertiary-darker-l), var(--bg-o, 1));
}

.bg-tertiary-dark {
  background-color: hsla(var(--color-tertiary-dark-h), var(--color-tertiary-dark-s), var(--color-tertiary-dark-l), var(--bg-o, 1));
}

.bg-tertiary {
  background-color: hsla(var(--color-tertiary-h), var(--color-tertiary-s), var(--color-tertiary-l), var(--bg-o, 1));
}

.bg-tertiary-light {
  background-color: hsla(var(--color-tertiary-light-h), var(--color-tertiary-light-s), var(--color-tertiary-light-l), var(--bg-o, 1));
}

.bg-tertiary-lighter {
  background-color: hsla(var(--color-tertiary-lighter-h), var(--color-tertiary-lighter-s), var(--color-tertiary-lighter-l), var(--bg-o, 1));
}

.border-secondary-darker {
  border-color: hsla(var(--color-secondary-darker-h), var(--color-secondary-darker-s), var(--color-secondary-darker-l), var(--bg-o, 1));
}

.border-secondary-dark {
  border-color: hsla(var(--color-secondary-dark-h), var(--color-secondary-dark-s), var(--color-secondary-dark-l), var(--bg-o, 1));
}

.border-secondary {
  border-color: hsla(var(--color-secondary-h), var(--color-secondary-s), var(--color-secondary-l), var(--bg-o, 1));
}

.border-secondary-light {
  border-color: hsla(var(--color-secondary-light-h), var(--color-secondary-light-s), var(--color-secondary-light-l), var(--bg-o, 1));
}

.border-secondary-lighter {
  border-color: hsla(var(--color-secondary-lighter-h), var(--color-secondary-lighter-s), var(--color-secondary-lighter-l), var(--bg-o, 1));
}

.border-tertiary-darker {
  border-color: hsla(var(--color-tertiary-darker-h), var(--color-tertiary-darker-s), var(--color-tertiary-darker-l), var(--bg-o, 1));
}

.border-tertiary-dark {
  border-color: hsla(var(--color-tertiary-dark-h), var(--color-tertiary-dark-s), var(--color-tertiary-dark-l), var(--bg-o, 1));
}

.border-tertiary {
  border-color: hsla(var(--color-tertiary-h), var(--color-tertiary-s), var(--color-tertiary-l), var(--bg-o, 1));
}

.border-tertiary-light {
  border-color: hsla(var(--color-tertiary-light-h), var(--color-tertiary-light-s), var(--color-tertiary-light-l), var(--bg-o, 1));
}

.border-tertiary-lighter {
  border-color: hsla(var(--color-tertiary-lighter-h), var(--color-tertiary-lighter-s), var(--color-tertiary-lighter-l), var(--bg-o, 1));
}

.form-control,
#card-element {
  --form-control-font-size: var(--text-sm);
  --form-control-padding-x: var(--space-xs);
  --form-control-padding-y: var(--space-sm);
  --form-control-radius: var(--radius-sm);
  background-color: var(--color-bg);
  color: var(--color-contrast-high);
  border: 1px solid hsla(var(--color-contrast-high-h), var(--color-contrast-high-s), var(--color-contrast-high-l), 0.3);
  font-family: var(--font-secondary);
  line-height: 1.2;
  transition: all 0.2s ease;
}

.form-control::placeholder,
#card-element::placeholder {
  opacity: 1;
  color: hsla(var(--color-contrast-high-h), var(--color-contrast-high-s), var(--color-contrast-high-l), 0.5);
}

.form-control:focus,
#card-element:focus {
  border-color: var(--color-primary);
  box-shadow: none;
  outline: none;
}

#card-element {
  padding: var(--space-sm) var(--space-xs);
}

.form-control--disabled,
.form-control[disabled],
.form-control[readonly] {
  background-color: hsla(var(--color-contrast-low-h), var(--color-contrast-low-s), var(--color-contrast-low-l), 0.75);
  cursor: not-allowed;
}

.form-control[aria-invalid=true],
.form-control--error {
  border-color: var(--color-error-light);
}

.form-control[aria-invalid=true]:focus,
.form-control--error:focus {
  box-shadow: 0px 0px 0px 2px hsla(var(--color-error-h), var(--color-error-s), var(--color-error-l), 0.2);
}

.form-legend {
  font-family: var(--font-primary);
  font-size: var(--text-md);
  font-weight: 600;
  text-transform: uppercase;
}

.form-label {
  font-family: var(--font-secondary);
  font-size: var(--text-sm);
  font-weight: 500;
}

.form-textarea {
  min-height: 100px;
}

.icon {
  vertical-align: middle;
}

:root {
  --radius: 0.25em;
}

:root {
  --space-unit: 1em;
  --space-xxxxs: calc(0.125 * var(--space-unit));
  --space-xxxs: calc(0.25 * var(--space-unit));
  --space-xxs: calc(0.375 * var(--space-unit));
  --space-xs: calc(0.5 * var(--space-unit));
  --space-sm: calc(0.75 * var(--space-unit));
  --space-md: calc(1.25 * var(--space-unit));
  --space-lg: calc(2 * var(--space-unit));
  --space-xl: calc(3.25 * var(--space-unit));
  --space-xxl: calc(5.25 * var(--space-unit));
  --space-xxxl: calc(8.5 * var(--space-unit));
  --space-xxxxl: calc(13.75 * var(--space-unit));
  --component-padding: var(--space-md);
}

@supports (--css: variables) {
@media (min-width: 64rem) {
    :root {
      --space-unit: 1.25em;
    }
}
}

:root {
  --font-primary: Poppins, sans-serif;
  --font-secondary: Poppins, sans-serif;
  --text-base-size: 1em;
  --text-scale-ratio: 1.2;
  --text-xs: calc((1em / var(--text-scale-ratio)) / var(--text-scale-ratio));
  --text-sm: calc(var(--text-xs) * var(--text-scale-ratio));
  --text-md: calc(var(--text-sm) * var(--text-scale-ratio) * var(--text-scale-ratio));
  --text-lg: calc(var(--text-md) * var(--text-scale-ratio));
  --text-xl: calc(var(--text-lg) * var(--text-scale-ratio));
  --text-xxl: calc(var(--text-xl) * var(--text-scale-ratio));
  --text-xxxl: calc(var(--text-xxl) * var(--text-scale-ratio));
  --text-xxxxl: calc(var(--text-xxxl) * var(--text-scale-ratio));
  --body-line-height: 1.5;
  --heading-line-height: 1.2;
  --font-primary-capital-letter: 1;
  --font-secondary-capital-letter: 1;
}

@supports (--css: variables) {
@media (min-width: 64rem) {
    :root {
      --text-base-size: 1.2em;
      --text-scale-ratio: 1.2;
    }
}
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 600;
  color: hsla(var(--color-black-h), var(--color-black-s), var(--color-black-l), 0.9);
  font-family: var(--font-primary);
}

body {
  font-family: var(--font-secondary);
  font-weight: 300;
  color: hsla(var(--color-black-h), var(--color-black-s), var(--color-black-l), 0.8);
}

.font-primary {
  font-family: var(--font-primary);
}

.font-secondary {
  font-family: var(--font-secondary);
}

a,
.link {
  color: inherit;
}

mark {
  background-color: hsla(var(--color-accent-h), var(--color-accent-s), var(--color-accent-l), 0.2);
  color: inherit;
}

.text-component {
  --line-height-multiplier: 1;
  --text-vspace-multiplier: 1;
}

.text-component blockquote {
  padding-left: 1em;
  border-left: 4px solid var(--color-contrast-low);
}

.text-component hr {
  background: var(--color-contrast-low);
  height: 1px;
}

.text-component figcaption {
  font-size: var(--text-sm);
  color: var(--color-contrast-medium);
}

.article {
  --body-line-height: 1.58;
  --text-vspace-multiplier: 1.2;
}

b {
  font-weight: 500;
}

.height-400 {
  height: 400px;
}

.max-width-300px {
  max-width: 300px;
}

.media-wrapper--21\:9 {
  padding-bottom: 42.857%;
}

@media (min-width: 32rem) {
  .height-xxl\@xs {
    height: 6rem;
  }

  .width-xxl\@xs {
    width: 8rem;
  }
}

@media (min-width: 48rem) {
  .height-xxl\@sm {
    height: 6rem;
  }

  .width-xxl\@sm {
    width: 8rem;
  }
}

@media (min-width: 64rem) {
  .height-xxl\@md {
    height: 6rem;
  }

  .width-xxl\@md {
    width: 8rem;
  }
}

@media (min-width: 80rem) {
  .height-xxl\@lg {
    height: 6rem;
  }

  .width-xxl\@lg {
    width: 8rem;
  }
}

@media (min-width: 90rem) {
  .height-xxl\@xl {
    height: 6rem;
  }

  .width-xxl\@xl {
    width: 8rem;
  }
}

/* -------------------------------- 

File#: _1_accordion
Title: Accordion
Descr: Create stacked sections of content and allow the user to expand/collapse them
Usage: codyhouse.co/license

-------------------------------- */

:root {
  --accordion-border-width: 1px;
  --accordion-border-color: var(--color-accent);
  --accordion-icon-size: 1em;
  --accordion-icon-stroke-width: 1.5px;
}

.accordion__item {
  border-style: solid;
  border-color: var(--accordion-border-color);
  border-bottom-width: var(--accordion-border-width);
}

.accordion__item:first-child {
  border-top-width: var(--accordion-border-width);
}

.accordion__header {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  text-align: left;
  cursor: pointer;
}

.accordion__header .icon {
  font-size: var(--accordion-icon-size);
}

.accordion__header .icon .icon__group {
  stroke-width: var(--accordion-icon-stroke-width);
}

.accordion__icon-arrow .icon__group,
.accordion__icon-arrow-v2 .icon__group,
.accordion__icon-plus .icon__group {
  will-change: transform;
  transform-origin: 8px 8px;
}

.accordion__icon-arrow .icon__group > *,
.accordion__icon-arrow-v2 .icon__group > *,
.accordion__icon-plus .icon__group > * {
  transform-origin: 8px 8px;
}

.accordion__icon-arrow .icon__group > *,
.accordion__icon-arrow-v2 .icon__group > * {
  stroke-dasharray: 17;
  transform: translateY(3px);
}

.accordion__icon-arrow .icon__group > *:first-child,
.accordion__icon-arrow-v2 .icon__group > *:first-child {
  stroke-dashoffset: 8.5;
}

.accordion__icon-arrow .icon__group > *:last-child,
.accordion__icon-arrow-v2 .icon__group > *:last-child {
  stroke-dashoffset: 8.5;
}

.accordion__icon-plus .icon__group {
  transform: rotate(-90deg);
}

.accordion__icon-plus .icon__group > *:first-child {
  transform: rotate(-90deg);
}

.accordion__item--is-open > .accordion__header > .accordion__icon-arrow .icon__group > *:first-child {
  transform: translateY(-3px) rotate(-90deg);
}

.accordion__item--is-open > .accordion__header > .accordion__icon-arrow .icon__group > *:last-child {
  transform: translateY(-3px) rotate(90deg);
}

.accordion__item--is-open > .accordion__header > .accordion__icon-arrow-v2 .icon__group {
  transform: rotate(-90deg);
}

.accordion__item--is-open > .accordion__header > .accordion__icon-arrow-v2 .icon__group > *:first-child,
.accordion__item--is-open > .accordion__header > .accordion__icon-arrow-v2 .icon__group *:last-child {
  stroke-dashoffset: 0;
  transform: translateY(0px);
}

.accordion__item--is-open > .accordion__header > .accordion__icon-plus .icon__group {
  transform: rotate(0);
}

.accordion__item--is-open > .accordion__header > .accordion__icon-plus .icon__group > *:first-child {
  transform: rotate(0);
}

.accordion__item--is-open > .accordion__header {
  background-color: var(--color-accent-lighter);
}

.js .accordion__panel {
  display: none;
  will-change: height;
  transform: translateZ(0px);
}

.js .accordion__item--is-open > .accordion__panel {
  display: block;
}

.accordion[data-animation=on] .accordion__item--is-open .accordion__panel > * {
  animation: accordion-entry-animation 0.4s var(--ease-out);
}

.accordion[data-animation=on] .accordion__icon-arrow .icon__group,
.accordion[data-animation=on] .accordion__icon-arrow-v2 .icon__group,
.accordion[data-animation=on] .accordion__icon-plus .icon__group {
  transition: transform 0.3s var(--ease-out);
}

.accordion[data-animation=on] .accordion__icon-arrow .icon__group > *,
.accordion[data-animation=on] .accordion__icon-arrow-v2 .icon__group > *,
.accordion[data-animation=on] .accordion__icon-plus .icon__group > * {
  transition: transform 0.3s, stroke-dashoffset 0.3s;
  transition-timing-function: var(--ease-out);
}

@keyframes accordion-entry-animation {
  from {
    opacity: 0;
    transform: translateY(-10px);
  }

  to {
    opacity: 1;
    transform: translateY(0px);
  }
}

/* -------------------------------- 

File#: _1_adv-gallery
Title: Advanced Gallery
Descr: Advanced image gallery template
Usage: codyhouse.co/license

-------------------------------- */

.adv-gallery {
  position: relative;
  z-index: 1;
}

.adv-gallery__img {
  width: 100%;
  display: block;
  object-fit: cover;
}

.adv-gallery__grid {
  display: grid;
  grid-gap: var(--space-xxs);
  grid-template-rows: repeat(12, 15px);
}

.adv-gallery__grid > :nth-child(1) {
  grid-column: 1/4;
  grid-row: 1/7;
}

.adv-gallery__grid > :nth-child(2) {
  grid-column: 1/4;
  grid-row: 7/-1;
}

.adv-gallery__grid > :nth-child(3) {
  grid-column: 4/10;
  grid-row: 1/-1;
}

.adv-gallery__grid > :nth-child(4) {
  grid-column: 10/13;
  grid-row: 1/7;
}

.adv-gallery__grid > :nth-child(5) {
  grid-column: 10/13;
  grid-row: 7/-1;
}

.adv-gallery__grid .adv-gallery__img {
  height: 100%;
}

@media (min-width: 64rem) {
  .adv-gallery__grid {
    grid-gap: var(--space-sm);
    grid-template-rows: repeat(12, 40px);
  }
}

/* -------------------------------- 

File#: _1_alert
Title: Alert
Descr: Feedback message
Usage: codyhouse.co/license

-------------------------------- */

.alert {
  padding: var(--space-xs) var(--space-sm);
  background-color: hsla(var(--color-primary-h), var(--color-primary-s), var(--color-primary-l), 0.2);
  border-radius: var(--radius-md);
  color: var(--color-contrast-higher);
  position: absolute;
  clip: rect(1px, 1px, 1px, 1px);
  clip-path: inset(50%);
}

.alert__link {
  color: inherit;
  text-decoration: underline;
}

.alert__close-btn {
  display: inline-block;
  flex-shrink: 0;
  margin-left: var(--space-sm);
}

.alert__close-btn .icon {
  display: block;
}

.alert--success {
  background-color: hsla(var(--color-success-h), var(--color-success-s), var(--color-success-l), 0.2);
}

.alert--error {
  background-color: hsla(var(--color-error-h), var(--color-error-s), var(--color-error-l), 0.2);
}

.alert--warning {
  background-color: hsla(var(--color-warning-h), var(--color-warning-s), var(--color-warning-l), 0.2);
}

.alert--is-visible {
  position: static;
  clip: auto;
  clip-path: none;
}

/* -------------------------------- 

File#: _1_badge
Title: Badge
Descr: A small label containing a text string
Usage: codyhouse.co/license

-------------------------------- */

.badge {
  background-color: transparent;
  padding: 0;
  border: 0;
  border-radius: 0;
  color: inherit;
  line-height: inherit;
  appearance: none;
  display: inline-flex;
  align-items: center;
  background-color: var(--color-black);
  padding: var(--space-xxxxs) var(--space-xxs);
  border-radius: var(--radius-md);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: var(--color-white);
}

.badge--outline {
  background-color: transparent;
  box-shadow: inset 0 0 0 1px hsla(var(--color-contrast-higher-h), var(--color-contrast-higher-s), var(--color-contrast-higher-l), 0.25);
}

.badge--contrast-higher {
  background-color: var(--color-contrast-higher);
  color: var(--color-bg);
}

.badge--primary {
  background-color: var(--color-primary);
  color: var(--color-white);
}

.badge--primary-light {
  background-color: hsla(var(--color-primary-h), var(--color-primary-s), var(--color-primary-l), 0.2);
  color: var(--color-contrast-higher);
}

.badge--accent {
  background-color: var(--color-accent);
}

.badge--accent-light {
  background-color: hsla(var(--color-accent-h), var(--color-accent-s), var(--color-accent-l), 0.2);
  color: var(--color-contrast-higher);
}

.badge--error {
  background-color: var(--color-error);
  color: var(--color-white);
}

.badge--error-light {
  background-color: hsla(var(--color-error-h), var(--color-error-s), var(--color-error-l), 0.2);
  color: var(--color-contrast-higher);
}

.badge--success {
  background-color: var(--color-success);
  color: var(--color-white);
}

.badge--success-light {
  background-color: hsla(var(--color-success-h), var(--color-success-s), var(--color-success-l), 0.2);
  color: var(--color-contrast-higher);
}

.badge--warning {
  background-color: var(--color-warning);
  color: var(--color-white);
}

.badge--warning-light {
  background-color: hsla(var(--color-warning-h), var(--color-warning-s), var(--color-warning-l), 0.2);
  color: var(--color-contrast-higher);
}

/* -------------------------------- 

File#: _1_breadcrumbs
Title: Breadcrumbss
Descr: List of links to help the user move within website structure
Usage: codyhouse.co/license

-------------------------------- */

.breadcrumbs__item {
  display: inline-block;
  display: inline-flex;
  align-items: center;
}

/* -------------------------------- 

File#: _1_choice-accordion
Title: Choice Accordion
Descr: A group of radio/checkbox buttons controlling expandable content
Usage: codyhouse.co/license

-------------------------------- */

:root {
  --choice-accordion-border-width: 2px;
  --choice-accordion-border-radius: var(--radius-md);
  --choice-accordion-input-size: 20px;
  --choice-accordion-input-icon-size: 16px;
  --choice-accordion-input-border-width: 2px;
  --choice-accordion-input-margin-right: var(--space-xxs);
}

.choice-accordion {
  border-color: var(--color-contrast-medium);
  border-style: solid;
  overflow: hidden;
}

.choice-accordion__btn {
  display: none;
}

.js .choice-accordion__btn {
  display: block;
  display: grid;
  grid-template-columns: var(--choice-accordion-input-size) 1fr;
  grid-gap: var(--choice-accordion-input-margin-right);
  align-items: center;
  cursor: pointer;
  background-color: var(--color-bg);
  border-top-width: var(--choice-accordion-border-width);
  border-style: solid;
  border-color: var(--color-primary);
}

.js .choice-accordion__btn--checked {
  background-color: var(--color-accent);
}

.choice-accordion__info {
  grid-column: 1/3;
}

.js .choice-accordion__item:first-child .choice-accordion__btn {
  border-top: none;
}

.choice-accordion__panel {
  position: relative;
  background-color: var(--color-bg);
  overflow: hidden;
  will-change: height;
  transform: translateZ(0px);
}

.choice-accordion__panel::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: var(--choice-accordion-border-width);
  background-color: var(--color-contrast-low);
  pointer-events: none;
}

.choice-accordion__input {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--color-bg);
  width: var(--choice-accordion-input-size);
  height: var(--choice-accordion-input-size);
  border-width: var(--choice-accordion-input-border-width);
  border-style: solid;
  border-color: var(--color-primary-light);
  transition: 0.2s;
}

.choice-accordion__input .icon {
  color: var(--color-white);
  font-size: var(--choice-accordion-input-icon-size);
}

.choice-accordion__btn:hover .choice-accordion__input {
  border-color: var(--color-primary);
}

.choice-accordion__btn--checked .choice-accordion__input,
.choice-accordion__btn--focus .choice-accordion__input,
.choice-accordion__btn--checked:hover .choice-accordion__input,
.choice-accordion__btn--focus:hover .choice-accordion__input {
  border-color: var(--color-primary-darker);
}

.choice-accordion__btn--checked .choice-accordion__input {
  background-color: var(--color-primary-darker);
}

.choice-accordion__btn--focus .choice-accordion__input {
  box-shadow: 0 0 0 3px hsla(var(--color-primary-darker-h), var(--color-primary-darker-s), var(--color-primary-darker-l), 0.2);
}

.choice-accordion__input--checkbox {
  border-radius: var(--radius-sm);
}

.choice-accordion__input--checkbox .icon > * {
  transition: stroke-dashoffset 0.3s;
  stroke-dasharray: 18;
  stroke-dashoffset: 18;
}

.choice-accordion__btn--checked .choice-accordion__input--checkbox .icon > * {
  stroke-dasharray: 18;
  stroke-dashoffset: 0;
}

.choice-accordion__input--radio {
  border-radius: 50%;
}

.choice-accordion__input--radio .icon {
  transition: transform 0.3s var(--ease-out-back);
  transform: scale(0);
}

.choice-accordion__btn--checked .choice-accordion__input--radio .icon {
  transform: scale(1);
}

.js .choice-accordion__fallback {
  position: absolute;
  clip: rect(1px, 1px, 1px, 1px);
  clip-path: inset(50%);
}

/* -------------------------------- 

File#: _1_choice-images
Title: Choice Images
Descr: Selectable images that behave like radio/checkbox buttons
Usage: codyhouse.co/license

-------------------------------- */

:root {
  --choice-img-input-size: 100px;
  --choice-img-input-icon-size: 26px;
}

.choice-img {
  position: relative;
  overflow: hidden;
  box-shadow: var(--shadow-sm);
  transition: 0.3s;
}

.choice-img:hover {
  cursor: pointer;
  box-shadow: var(--shadow-md);
}

.choice-img:active {
  transform: translateY(2px);
}

.choice-img[aria-checked=true] {
  transition: transform 0.3s;
  box-shadow: var(--shadow-sm), 0 0 0 2px var(--color-primary);
}

.choice-img__input {
  position: absolute;
  top: 0;
  right: 0;
  transform: translate(1px, -1px);
  background-color: var(--color-primary-light);
  width: var(--choice-img-input-size);
  height: var(--choice-img-input-size);
  clip-path: polygon(15% 0%, 100% 0%, 100% 85%);
  pointer-events: none;
  opacity: 0;
}

.choice-img__input .icon {
  position: absolute;
  top: calc(var(--choice-img-input-size) / 4 - var(--choice-img-input-icon-size) / 2);
  right: calc(var(--choice-img-input-size) / 4 - var(--choice-img-input-icon-size) / 2);
  font-size: var(--choice-img-input-icon-size);
  color: var(--color-white);
}

.choice-img__input .icon > * {
  transition: stroke-dashoffset 0.3s;
  stroke-dasharray: 18;
  stroke-dashoffset: 18;
}

.choice-img[aria-checked=true] .choice-img__input {
  opacity: 1;
}

.choice-img[aria-checked=true] .choice-img__input .icon > * {
  stroke-dashoffset: 0;
}

/* -------------------------------- 

File#: _1_color-swatches
Title: Color Swatches
Descr: A list of selectable swatches used to show a product color variants
Usage: codyhouse.co/license

-------------------------------- */

:root {
  --color-swatches-gap: var(--space-xxs);
  --color-swatch-size: 32px;
  --color-swatch-radius: 50%;
}

.color-swatches__list {
  display: flex;
  margin-bottom: calc(-1 * var(--color-swatches-gap, 0.5em));
  margin-left: calc(-1 * var(--color-swatches-gap, 0.5em));
}

.color-swatches__item {
  margin-bottom: var(--color-swatches-gap);
  margin-left: var(--color-swatches-gap);
}

.color-swatches__swatch {
  position: relative;
  display: block;
  height: var(--color-swatch-size);
  width: var(--color-swatch-size);
  border-radius: var(--color-swatch-radius);
  cursor: pointer;
}

.color-swatches__swatch::before {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  height: 100%;
  border: 1px solid hsla(var(--color-contrast-higher-h), var(--color-contrast-higher-s), var(--color-contrast-higher-l), 0.1);
  border-radius: inherit;
  pointer-events: none;
  transition: 0.2s;
}

.color-swatches__swatch:hover::before {
  border-color: hsla(var(--color-contrast-higher-h), var(--color-contrast-higher-s), var(--color-contrast-higher-l), 0.3);
}

.color-swatches__item--selected .color-swatches__swatch::before {
  border-color: hsla(var(--color-contrast-higher-h), var(--color-contrast-higher-s), var(--color-contrast-higher-l), 0.8);
  width: calc(100% + 6px);
  height: calc(100% + 6px);
}

/* -------------------------------- 

File#: _1_countdown
Title: CountDown
Descr: Widget to display a timer
Usage: codyhouse.co/license

-------------------------------- */

.countdown {
  display: flex;
  align-items: baseline;
}

.countdown__timer {
  display: inline-block;
}

.countdown__item {
  display: inline-block;
}

.countdown__item:nth-of-type(2)::after,
.countdown__item:nth-of-type(3)::after {
  content: ":";
}

.countdown__value {
  font-feature-settings: "tnum";
  font-variant-numeric: tabular-nums;
}

.countdown--has-labels .countdown__item {
  margin-right: var(--space-xs);
}

.countdown--has-labels .countdown__item:last-child {
  margin-right: 0;
}

.countdown--has-labels .countdown__item::after {
  content: "";
}

.countdown--has-labels .countdown__label {
  font-size: 0.45em;
}

.countdown--grid .countdown__timer {
  display: grid;
  grid-template-columns: repeat(4, 1.6em);
  grid-gap: var(--space-xxxs);
}

.countdown--grid .countdown__item {
  text-align: center;
  padding: var(--space-xxxxs) 0;
  background-color: var(--color-bg);
}

.countdown--grid .countdown__item::after {
  content: "";
}

.countdown--grid .countdown__label {
  display: block;
  text-transform: uppercase;
  font-size: 0.2em;
  letter-spacing: 0.1em;
  color: var(--color-contrast-medium);
}

.js .countdown__fallback {
  position: absolute;
  clip: rect(1px, 1px, 1px, 1px);
  clip-path: inset(50%);
}

html:not(.js) .countdown__info {
  display: none;
}

/* -------------------------------- 

File#: _1_counter
Title: Counter
Descr: A label displaying numeric values, often used to indicate unread notification
Usage: codyhouse.co/license

-------------------------------- */

.counter {
  font-size: var(--text-xs);
  background-color: var(--color-contrast-low);
  padding: var(--space-xxxs) var(--space-xs);
  border-radius: 50em;
}

.counter--primary {
  background-color: var(--color-primary);
  color: var(--color-white);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.counter--accent {
  background-color: var(--color-accent);
  color: var(--color-white);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.counter--critical {
  background-color: var(--color-error);
  color: var(--color-white);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.counter--light {
  background-color: hsla(var(--color-white-h), var(--color-white-s), var(--color-white-l), 0.9);
  color: hsla(var(--color-black-h), var(--color-black-s), var(--color-black-l), 0.85);
}

.counter--dark {
  background-color: hsla(var(--color-black-h), var(--color-black-s), var(--color-black-l), 0.8);
  color: hsla(var(--color-white-h), var(--color-white-s), var(--color-white-l), 0.9);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.counter--docked {
  position: absolute;
  top: 0;
  right: 0;
  transform: translate(50%, -50%);
}

.counter-container {
  height: 48px;
  width: 48px;
  background-color: var(--color-contrast-lower);
  border-radius: var(--radius-md);
  position: relative;
}

/* -------------------------------- 

File#: _1_custom-select
Title: Custom Select
Descr: Custom Select Control
Usage: codyhouse.co/license

-------------------------------- */

:root {
  --select-icon-size: 1em;
  --select-icon-right-margin: var(--space-sm);
  --select-text-icon-gap: var(--space-xxxs);
}

.select {
  position: relative;
}

.select__input {
  width: 100%;
  height: 100%;
  padding-right: calc(var(--select-icon-size) + var(--select-icon-right-margin) + var(--select-text-icon-gap)) !important;
}

.select__icon {
  width: var(--select-icon-size);
  height: var(--select-icon-size);
  pointer-events: none;
  position: absolute;
  right: var(--select-icon-right-margin);
  top: 50%;
  transform: translateY(-50%);
}

:root {
  --select-dropdown-gap: 4px;
}

.select__button {
  width: 100%;
}

.select__dropdown {
  position: absolute;
  left: 0;
  top: 100%;
  min-width: 200px;
  max-height: 1px;
  background-color: var(--color-bg);
  box-shadow: var(--shadow-md);
  padding: var(--space-xxs) 0;
  border-radius: var(--radius-md);
  z-index: var(--zindex-popover);
  margin-top: var(--select-dropdown-gap);
  margin-bottom: var(--select-dropdown-gap);
  overflow: auto;
  --space-unit: 1rem;
  --space-xxxxs: calc(0.125 * 1rem);
  --space-xxxs: calc(0.25 * 1rem);
  --space-xxs: calc(0.375 * 1rem);
  --space-xs: calc(0.5 * 1rem);
  --space-sm: calc(0.75 * 1rem);
  --space-md: calc(1.25 * 1rem);
  --space-lg: calc(2 * 1rem);
  --space-xl: calc(3.25 * 1rem);
  --space-xxl: calc(5.25 * 1rem);
  --space-xxxl: calc(8.5 * 1rem);
  --space-xxxxl: calc(13.75 * 1rem);
  --component-padding: var(--space-md);
  --text-xs: calc((1rem / 1.2) / 1.2);
  --text-sm: calc(var(--text-xs) * 1.2);
  --text-md: calc(var(--text-sm) * 1.2 * 1.2);
  --text-lg: calc(var(--text-md) * 1.2);
  --text-xl: calc(var(--text-lg) * 1.2);
  --text-xxl: calc(var(--text-xl) * 1.2);
  --text-xxxl: calc(var(--text-xxl) * 1.2);
  --text-xxxxl: calc(var(--text-xxxl) * 1.2);
  font-size: 1rem;
  visibility: hidden;
  opacity: 0;
  transition: visibility 0s 0.2s, opacity 0.2s;
}

.select__dropdown--right {
  right: 0;
  left: auto;
}

.select__dropdown--up {
  bottom: 100%;
  top: auto;
}

.select__button[aria-expanded=true] + .select__dropdown {
  visibility: visible;
  opacity: 1;
  transition: visibility 0s, opacity 0.2s;
}

.select__list {
  list-style: none !important;
}

.select__list:not(:first-of-type) {
  padding-top: var(--space-xxs);
}

.select__list:not(:last-of-type) {
  border-bottom: 1px solid var(--color-contrast-low);
  padding-bottom: var(--space-xxs);
}

.select__item {
  display: block;
  padding: var(--space-xs) var(--space-lg) var(--space-xs) var(--space-md);
  color: var(--color-contrast-high);
  width: 100%;
  text-align: left;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.select__item--optgroup {
  font-size: var(--text-sm);
  color: var(--color-contrast-medium);
}

.select__item--option {
  cursor: pointer;
}

.select__item--option:hover {
  background-color: var(--color-contrast-lower);
}

.select__item--option:focus {
  outline: none;
  background-color: hsla(var(--color-primary-h), var(--color-primary-s), var(--color-primary-l), 0.1);
}

.select__item--option[aria-selected=true] {
  background-color: var(--color-primary);
  color: var(--color-white);
  position: relative;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.select__item--option[aria-selected=true]::after {
  content: "";
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  height: 16px;
  width: 16px;
  right: var(--space-sm);
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpolyline stroke-width='1.5' stroke='%23ffffff' fill='none' stroke-linecap='round' stroke-linejoin='round' points='1,9 5,13 15,3 '/%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

html:not(.js) .select .icon {
  display: none;
}

/* -------------------------------- 

File#: _1_details
Title: Details
Descr: A button that toggles the visibility of additional information
Usage: codyhouse.co/license

-------------------------------- */

.details__summary {
  display: inline-block;
  cursor: pointer;
  user-select: none;
}

.details__summary:hover {
  color: hsla(var(--color-black-h), var(--color-black-s), var(--color-black-l), 0.9);
}

.details__summary:focus {
  outline: 0;
  outline-offset: 4px;
}

.details__summary .icon {
  transition: transform 0.2s;
  flex-shrink: 0;
}

.js .details__summary {
  list-style: none;
}

.js .details__summary::-webkit-details-marker {
  display: none;
}

.js .details__summary[aria-expanded=true] .icon {
  transform: rotate(90deg);
}

.js .details__content[aria-hidden=true] {
  display: none;
}

html:not(.js) .details__summary .icon {
  display: none;
}

/* -------------------------------- 

File#: _1_dialog
Title: Dialog
Descr: Overlay informing user about tasks/decisions
Usage: codyhouse.co/license

-------------------------------- */

.dialog {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: var(--zindex-overlay);
  opacity: 0;
  visibility: hidden;
}

.dialog__content {
  width: calc(100% - 2 * var(--space-md));
  overflow: auto;
  padding: var(--space-md);
  background-color: var(--color-bg);
  box-shadow: var(--shadow-lg);
}

.dialog--is-visible {
  opacity: 1;
  visibility: visible;
  background: hsla(var(--color-black-h), var(--color-black-s), var(--color-black-l), 0.5);
}

.dialog--sticky {
  align-items: flex-start;
}

.dialog--sticky .dialog__content {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.dialog[data-animation=on] {
  transition: opacity 0.3s, visibility 0s 0.3s;
  perspective: 800px;
}

.dialog[data-animation=on] .dialog__content {
  will-change: transform;
  transition: transform 0.3s var(--ease-out);
  transform: translateY(10%);
}

.dialog[data-animation=on].dialog--is-visible {
  transition: opacity 0.3s;
}

.dialog[data-animation=on].dialog--is-visible .dialog__content {
  transform: translateY(0);
}

.dialog[data-animation=on].dialog--sticky .dialog__content {
  transform: translateY(-100%);
}

.dialog[data-animation=on].dialog--sticky.dialog--is-visible {
  transition: none;
}

.dialog[data-animation=on].dialog--sticky.dialog--is-visible .dialog__content {
  transform: translateY(0);
}

/* -------------------------------- 

File#: _1_drawer
Title: Drawer
Descr: A slide-in panel used to display critical content
Usage: codyhouse.co/license

-------------------------------- */

:root {
  --drawer-width: 420px;
}

.drawer {
  position: fixed;
  z-index: var(--zindex-overlay);
  width: 100%;
  max-width: var(--drawer-width);
  height: 100%;
  top: 0;
  right: 0;
  visibility: hidden;
  transition: visibility 0s 0.3s;
}

.drawer:focus {
  outline: none;
}

.drawer--is-visible {
  visibility: visible;
  transition: none;
}

.drawer--open-left {
  right: auto;
  left: 0;
}

.drawer__content {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  right: 0;
  background-color: var(--color-bg);
  box-shadow: var(--shadow-md);
  transform: translateX(100%);
  transition: transform 0.3s;
  transition-timing-function: var(--ease-in-out);
}

.drawer--open-left .drawer__content {
  transform: translateX(-100%);
}

.drawer--is-visible .drawer__content {
  transform: translateX(0);
}

.drawer__body {
  height: 100%;
  overflow: auto;
  overscroll-behavior: contain;
  -webkit-overflow-scrolling: touch;
}

.drawer__close-btn {
  z-index: var(--zindex-fixed-element);
  top: var(--space-xxs);
  right: var(--space-xxs);
  width: 2em;
  height: 2em;
  display: flex;
  flex-shrink: 0;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background-color: hsla(var(--color-bg-h), var(--color-bg-s), var(--color-bg-l), 0.95);
  box-shadow: var(--shadow-sm);
  transition: 0.2s;
}

.drawer__close-btn:hover {
  background-color: var(--color-bg);
  box-shadow: var(--shadow-md);
}

.drawer__close-btn .icon {
  display: block;
  color: var(--color-contrast-high);
}

.drawer__header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-shrink: 0;
  padding: var(--space-xs) var(--component-padding);
  border-bottom: 1px solid var(--color-contrast-lower);
}

.drawer--modal {
  max-width: none;
  background-color: hsla(var(--color-accent-h), var(--color-accent-s), var(--color-accent-l), 0);
  transition: background-color 0.3s, visibility 0s 0.3s;
}

.drawer--modal.drawer--is-visible {
  background-color: hsla(var(--color-contrast-high-h), var(--color-contrast-high-s), var(--color-contrast-high-l), 0.8);
  transition: background-color 0.3s;
}

.drawer--modal.drawer--open-left .drawer__content {
  right: auto;
  left: 0;
}

.drawer--modal .drawer__content {
  max-width: var(--drawer-width);
}

/* -------------------------------- 

File#: _1_list
Title: List
Descr: Custom list component
Usage: codyhouse.co/license

-------------------------------- */

.list,
.text-component .list {
  --list-v-space: 0.65;
  --list-offset: 1;
  padding-left: 0;
  list-style: none;
}

.list ul,
.list ol,
.text-component .list ul,
.text-component .list ol {
  list-style: none;
  margin: 0;
  margin-top: calc(var(--space-xxxs) * var(--list-v-space, 1));
  padding-top: calc(var(--space-xxxs) * var(--list-v-space, 1));
  padding-left: calc(var(--space-xs) * var(--list-offset, 1));
}

.list li,
.text-component .list li {
  padding-bottom: calc(var(--space-xxxs) * var(--list-v-space, 1));
  margin-bottom: calc(var(--space-xxxs) * var(--list-v-space, 1));
}

.list > li:last-child,
.list ul > li:last-child,
.list ol > li:last-child,
.text-component .list > li:last-child,
.text-component .list ul > li:last-child,
.text-component .list ol > li:last-child {
  margin-bottom: 0;
}

.list:not(.list--border) > li:last-child,
.list ul > li:last-child,
.list ol > li:last-child,
.text-component .list:not(.list--border) > li:last-child,
.text-component .list ul > li:last-child,
.text-component .list ol > li:last-child {
  padding-bottom: 0;
}

.list--ul li,
.list--ol li {
  padding-left: calc(var(--bullet-size) + var(--bullet-margin-right));
}

.list--ul li::before,
.list--ol li::before {
  width: var(--bullet-size);
  height: var(--bullet-size);
  margin-left: calc(var(--bullet-size) * -1);
  display: inline-flex;
  vertical-align: middle;
  position: relative;
  left: calc(var(--bullet-margin-right) * -1);
}

.list--ul {
  --bullet-size: 7px;
  --bullet-margin-right: var(--space-xxs);
}

.list--ul li::before {
  content: "";
  border-radius: 50%;
  color: var(--color-primary);
  background-color: currentColor;
  border: 2px solid currentColor;
}

.list--ul ul li::before {
  background-color: transparent;
}

.list--ol {
  --bullet-size: 26px;
  --bullet-margin-right: 6px;
  counter-reset: list-items;
}

.list--ol li {
  counter-increment: list-items;
}

.list--ol ol {
  counter-reset: list-items;
}

.list--ol li::before {
  content: counter(list-items);
  font-size: 0.75em;
  justify-content: center;
  align-items: center;
  top: -0.1em;
  border-radius: 50%;
  background-color: var(--color-contrast-lower);
  border: 2px solid var(--color-contrast-lower);
  color: var(--color-contrast-high);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.list--ol ol li::before {
  background-color: transparent;
}

.list--border li {
  border-bottom: 1px solid var(--color-contrast-low);
}

.list--border ul,
.list--border ol {
  border-top: 1px solid var(--color-contrast-low);
}

.list--border ul li:last-child,
.list--border ol li:last-child {
  border-bottom-width: 0;
}

/* -------------------------------- 

File#: _1_main-footer
Title: Main Footer
Descr: Footer navigation
Usage: codyhouse.co/license

-------------------------------- */

.main-footer {
  position: relative;
  z-index: 1;
}

.main-footer__logo {
  flex-shrink: 0;
  width: 130px;
  height: 37px;
  display: block;
}

.main-footer__logo svg,
.main-footer__logo img {
  display: block;
  width: inherit;
  height: inherit;
}

.main-footer__link {
  text-decoration: none;
}

.main-footer__link:hover {
  color: var(--color-contrast-high);
  text-decoration: underline;
}

.main-footer__colophon {
  display: grid;
  justify-content: space-between;
  align-items: center;
  grid-template-columns: 1fr 1fr;
}

@media (min-width: 64rem) {
  .main-footer__colophon {
    grid-template-columns: repeat(3, 1fr);
  }
}

.main-footer__social {
  text-decoration: none;
  display: inline-block;
}

.main-footer__social:hover {
  color: var(--color-contrast-high);
}

/* -------------------------------- 

File#: _1_modal-window
Title: Modal Window
Descr: A modal dialog used to display critical information
Usage: codyhouse.co/license

-------------------------------- */

.modal {
  position: fixed;
  z-index: var(--zindex-overlay);
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  opacity: 0;
  visibility: hidden;
}

.modal:not(.modal--is-visible) {
  pointer-events: none;
  background-color: transparent;
}

.modal--is-visible {
  opacity: 1;
  visibility: visible;
}

.modal__content {
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  max-height: 100%;
}

.modal__close-btn {
  display: flex;
  flex-shrink: 0;
  border-radius: 50%;
  transition: 0.2s;
}

.modal__close-btn .icon {
  display: block;
  margin: auto;
}

.modal__close-btn--outer {
  width: 3.2em;
  height: 3.2em;
  position: fixed;
  top: var(--space-sm);
  right: var(--space-sm);
  z-index: var(--zindex-fixed-element);
  background-color: hsla(var(--color-contrast-higher-h), var(--color-contrast-higher-s), var(--color-contrast-higher-l), 0.8);
}

.modal__close-btn--outer:hover {
  background-color: var(--color-contrast-higher);
}

.modal__close-btn--outer .icon {
  color: var(--color-bg);
}

.modal__close-btn--inner {
  width: 2em;
  height: 2em;
  background-color: var(--color-bg);
  box-shadow: var(--shadow-sm);
}

.modal__close-btn--inner .icon {
  color: inherit;
}

.modal__close-btn--inner:hover {
  box-shadow: var(--shadow-md);
}

:root {
  --modal-transition-duration: 0.2s;
}

@media (prefers-reduced-motion: no-preference) {
  .modal--animate-fade {
    --modal-transition-duration: 0.2s;
    transition: opacity var(--modal-transition-duration), background-color var(--modal-transition-duration), visibility 0s var(--modal-transition-duration);
  }

  .modal--animate-fade.modal--is-visible {
    transition: opacity var(--modal-transition-duration), background-color var(--modal-transition-duration), visibility 0s;
  }

  .modal--animate-scale,
  .modal--animate-translate-up,
  .modal--animate-translate-down,
  .modal--animate-translate-right,
  .modal--animate-translate-left {
    --modal-transition-duration: 0.2s;
    transition: opacity var(--modal-transition-duration), visibility 0s var(--modal-transition-duration);
  }

  .modal--animate-scale .modal__content,
  .modal--animate-translate-up .modal__content,
  .modal--animate-translate-down .modal__content,
  .modal--animate-translate-right .modal__content,
  .modal--animate-translate-left .modal__content {
    will-change: transform;
    transition: transform var(--modal-transition-duration) var(--ease-out);
  }

  .modal--animate-scale.modal--is-visible,
  .modal--animate-translate-up.modal--is-visible,
  .modal--animate-translate-down.modal--is-visible,
  .modal--animate-translate-right.modal--is-visible,
  .modal--animate-translate-left.modal--is-visible {
    transition: opacity var(--modal-transition-duration), visibility 0s;
  }

  .modal--animate-scale.modal--is-visible .modal__content,
  .modal--animate-translate-up.modal--is-visible .modal__content,
  .modal--animate-translate-down.modal--is-visible .modal__content,
  .modal--animate-translate-right.modal--is-visible .modal__content,
  .modal--animate-translate-left.modal--is-visible .modal__content {
    transform: scale(1);
  }

  .modal--animate-slide-up,
  .modal--animate-slide-down,
  .modal--animate-slide-right,
  .modal--animate-slide-left {
    --modal-transition-duration: 0.3s;
    transition: opacity 0s var(--modal-transition-duration), background-color var(--modal-transition-duration), visibility 0s var(--modal-transition-duration);
  }

  .modal--animate-slide-up .modal__content,
  .modal--animate-slide-down .modal__content,
  .modal--animate-slide-right .modal__content,
  .modal--animate-slide-left .modal__content {
    will-change: transform;
    transition: transform var(--modal-transition-duration) var(--ease-out);
  }

  .modal--animate-slide-up.modal--is-visible,
  .modal--animate-slide-down.modal--is-visible,
  .modal--animate-slide-right.modal--is-visible,
  .modal--animate-slide-left.modal--is-visible {
    transition: background-color var(--modal-transition-duration), visibility 0s;
  }

  .modal--animate-slide-up.modal--is-visible .modal__content,
  .modal--animate-slide-down.modal--is-visible .modal__content,
  .modal--animate-slide-right.modal--is-visible .modal__content,
  .modal--animate-slide-left.modal--is-visible .modal__content {
    transform: scale(1);
  }

  .modal--animate-scale .modal__content {
    transform: scale(0.95);
  }

  .modal--animate-translate-up .modal__content {
    transform: translateY(40px);
  }

  .modal--animate-translate-down .modal__content {
    transform: translateY(-40px);
  }

  .modal--animate-translate-right .modal__content {
    transform: translateX(-40px);
  }

  .modal--animate-translate-left .modal__content {
    transform: translateX(40px);
  }

  .modal--animate-slide-up .modal__content {
    transform: translateY(100%);
  }

  .modal--animate-slide-down .modal__content {
    transform: translateY(-100%);
  }

  .modal--animate-slide-right .modal__content {
    transform: translateX(-100%);
  }

  .modal--animate-slide-left .modal__content {
    transform: translateX(100%);
  }
}

.modal--is-loading .modal__content {
  visibility: hidden;
}

.modal--is-loading .modal__loader {
  display: flex;
}

.modal__loader {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  display: none;
  pointer-events: none;
}

/* -------------------------------- 

File#: _1_number-input
Title: Number input
Descr: Number input field with custom increment buttons
Usage: codyhouse.co/license

-------------------------------- */

.number-input__btn {
  display: none;
}

.js .number-input .form-control::-webkit-inner-spin-button,
.js .number-input .form-control::-webkit-outer-spin-button {
  display: none;
}

.js .number-input .form-control {
  -moz-appearance: textfield;
  display: block;
}

.js .number-input__btn {
  display: inline-block;
  display: flex;
}

.js .number-input--v1 {
  --number-input-btn-width: 40px;
  position: relative;
  width: 5em;
}

.js .number-input--v1 .form-control {
  padding-right: var(--number-input-btn-width);
  width: 100%;
}

.js .number-input--v1 .number-input__btns {
  position: absolute;
  height: calc(100% - var(--space-xxxs) * 2);
  top: var(--space-xxxs);
  right: 0;
  width: var(--number-input-btn-width);
  display: flex;
  flex-direction: column;
}

.js .number-input--v1 .number-input__btn {
  position: relative;
  height: 50%;
  width: calc(100% - var(--space-xxxs));
  background: var(--color-contrast-lower);
  border-radius: var(--radius-sm);
}

.js .number-input--v1 .number-input__btn .icon {
  display: block;
  font-size: 0.8em;
  width: 1em;
  height: 1em;
  position: absolute;
  left: calc(50% - 0.5em);
  top: calc(50% - 0.5em);
}

.js .number-input--v1 .number-input__btn:hover {
  background-color: var(--color-contrast-low);
}

.js .number-input--v1 .number-input__btn:focus {
  outline: none;
  background-color: var(--color-primary);
}

.js .number-input--v1 .number-input__btn:focus .icon {
  color: var(--color-white);
}

.js .number-input--v1 .number-input__btn:active {
  background-color: hsl(var(--color-primary-h), var(--color-primary-s), calc(var(--color-primary-l) * 0.9));
}

.js .number-input--v1 .number-input__btn--plus {
  margin-bottom: var(--space-xxxxs);
}

.js .number-input--v2 {
  --number-input-btn-width: 1.6em;
  display: flex;
  align-items: center;
  font-size: 0.875em;
}

.js .number-input--v2 .form-control {
  margin: 0 var(--space-xxs);
  width: 4em;
  order: 1;
  text-align: center;
}

.js .number-input--v2 .number-input__btn {
  width: var(--number-input-btn-width);
  height: var(--number-input-btn-width);
  border-radius: 50%;
  flex-shrink: 0;
  justify-content: center;
  align-items: center;
  background-color: var(--color-contrast-lower);
}

.js .number-input--v2 .number-input__btn:hover {
  background-color: var(--color-contrast-low);
}

.js .number-input--v2 .number-input__btn:focus {
  outline: none;
  background-color: var(--color-primary);
}

.js .number-input--v2 .number-input__btn:focus .icon {
  color: var(--color-white);
}

.js .number-input--v2 .number-input__btn:active {
  background-color: hsl(var(--color-primary-h), var(--color-primary-s), calc(var(--color-primary-l) * 0.9));
}

.js .number-input--v2 .number-input__btn .icon {
  font-size: 12px;
  width: 1em;
  height: 1em;
}

.js .number-input--v2 .number-input__btn--plus {
  order: 2;
}

/* -------------------------------- 

File#: _1_order-summary
Title: Order Summary
Descr: A template showing a recap of the cart items
Usage: codyhouse.co/license

-------------------------------- */

:root {
  --order-summary-img-size: 3.6em;
  --order-summary-img-margin-right: var(--space-md);
}

.order-summary__header {
  border-radius: inherit;
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
}

.order-summary__footer {
  border-radius: inherit;
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
}

.order-summary__item {
  display: grid;
  grid-template-columns: var(--order-summary-img-size) 1fr;
  grid-gap: var(--order-summary-img-margin-right);
  align-items: center;
}

.order-summary__img {
  display: flex;
  align-items: center;
  padding: var(--space-xxxs);
  background: var(--color-white);
  width: var(--order-summary-img-size);
  height: var(--order-summary-img-size);
  border-radius: var(--radius-md);
  overflow: hidden;
  box-shadow: var(--shadow-sm);
  transition: 0.2s;
}

.order-summary__img img {
  display: block;
  width: 100%;
  object-fit: cover;
}

.order-summary__img:hover {
  transform: translateY(-1px);
  box-shadow: var(--shadow-sm);
}

/* -------------------------------- 

File#: _1_pagination
Title: Pagination 
Descr: Component used to navigate through pages of related content
Usage: codyhouse.co/license

-------------------------------- */

.pagination {
  font-family: var(--font-primary);
}

.pagination__list > li {
  display: inline-block;
}

.pagination--split .pagination__list {
  width: 100%;
}

.pagination--split .pagination__list > *:first-child {
  margin-right: auto;
}

.pagination--split .pagination__list > *:last-child {
  margin-left: auto;
}

.pagination__item {
  display: inline-block;
  display: inline-flex;
  align-items: center;
  white-space: nowrap;
  line-height: 1;
  padding-top: var(--space-xs);
  padding-bottom: var(--space-xs);
  padding-left: calc(1.355 * var(--space-xs));
  padding-right: calc(1.355 * var(--space-xs));
  border-radius: var(--radius-md);
  text-decoration: none;
  height: 100%;
  color: var(--color-accent-darker);
  transition: 0.2s;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.pagination__item:hover:not(.pagination__item--selected):not(.pagination__item--ellipsis) {
  background-color: var(--color-contrast-lower);
}

.pagination__item:active {
  transform: translateY(2px);
}

.pagination__item--selected {
  background-color: var(--color-contrast-higher);
  color: var(--color-bg);
  box-shadow: var(--shadow-sm);
}

.pagination__item--disabled {
  opacity: 0.5;
  pointer-events: none;
}

.pagination__jumper .form-control {
  width: 3.2em;
  margin-right: var(--space-xs);
  padding: var(--space-xs);
}

.pagination__jumper em {
  flex-shrink: 0;
  white-space: nowrap;
}

/* -------------------------------- 

File#: _1_product-card
Title: Product Card
Descr: A selection of product information used as teasers for further content
Usage: codyhouse.co/license

-------------------------------- */

.prod-card {
  position: relative;
}

.prod-card__body {
  position: relative;
  padding: var(--space-xl) var(--space-md);
}

.prod-card__img-link {
  text-decoration: none;
  display: block;
}

.prod-card__img-link:hover .prod-card__img {
  transform: scale(1.05);
}

.prod-card__img-link:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: var(--color-contrast-medium);
  opacity: 0.07;
  z-index: var(--zindex-popover);
}

.prod-card__img {
  transition: transform 350ms;
}

.prod-card__img img {
  display: block;
  width: 100%;
}

.prod-card__badge {
  position: absolute;
  z-index: 1;
  top: var(--space-sm);
  left: var(--space-sm);
  background-color: var(--color-contrast-medium);
  padding: var(--space-xxs) var(--space-sm);
  font-size: var(--text-xs);
  text-transform: uppercase;
  letter-spacing: 0.1em;
  color: var(--color-white);
}

.prod-card__gender {
  position: absolute;
  z-index: 1;
  top: var(--space-sm);
  right: var(--space-sm);
}

.prod-card__heart {
  position: absolute;
  z-index: var(--zindex-popover);
  bottom: var(--space-sm);
  right: var(--space-sm);
  cursor: pointer;
}

@media (min-width: 64rem) {
  .prod-card__footer {
    padding: var(--space-xxs);
  }
}

.prod-card__footer a {
  color: inherit;
  text-decoration: none;
}

.prod-card__price--old {
  color: var(--color-accent);
  text-decoration: line-through;
}

.prod-card__price--old::before {
  content: "original price";
  position: absolute;
  clip: rect(1px, 1px, 1px, 1px);
  clip-path: inset(50%);
}

.prod-card__price--new {
  text-decoration: none;
}

.prod-card__price--new::before {
  content: "discounted price";
  position: absolute;
  clip: rect(1px, 1px, 1px, 1px);
  clip-path: inset(50%);
}

.loved {
  color: var(--color-error);
}

/* -------------------------------- 

File#: _1_radios-checkboxes
Title: Radios and Checkboxes
Descr: Custom radio and checkbox buttons
Usage: codyhouse.co/license

-------------------------------- */

:root {
  --checkbox-radio-size: 1em;
  --checkbox-radio-translate-y: 0.15em;
  --checkbox-radio-gap: var(--space-xxxs);
  --checkbox-radio-border-width: 2px;
  --checkbox-radio-line-height: var(--body-line-height);
  --radio-marker-size: 8px;
  --checkbox-marker-size: 12px;
  --checkbox-radius: 0.185em;
}

.radio,
.checkbox {
  position: absolute;
  margin: 0 !important;
  padding: 0 !important;
  opacity: 0;
  height: 0;
  width: 0;
  pointer-events: none;
}

.radio + label,
.checkbox + label {
  display: inline-block;
  display: inline-flex;
  align-items: baseline;
  line-height: var(--checkbox-radio-line-height);
  user-select: none;
  cursor: pointer;
}

.radio + label::before,
.checkbox + label::before {
  content: "";
  display: inline-block;
  vertical-align: middle;
  flex-shrink: 0;
  width: var(--checkbox-radio-size);
  height: var(--checkbox-radio-size);
  background-color: var(--color-bg);
  border-width: var(--checkbox-radio-border-width);
  border-color: var(--color-contrast-medium);
  border-style: solid;
  margin-right: var(--checkbox-radio-gap);
  background-repeat: no-repeat;
  background-position: center;
  transition: transform 0.2s, border 0.2s;
}

.radio:not(:checked):not(:focus) + label:hover::before,
.checkbox:not(:checked):not(:focus) + label:hover::before {
  border-color: var(--color-contrast-medium);
}

@supports (grid-area: auto) {
  .radio + label::before,
  .checkbox + label::before {
    position: relative;
    top: var(--checkbox-radio-translate-y);
  }
}

.radio + label::before {
  border-radius: 50%;
}

.checkbox + label::before {
  border-radius: var(--checkbox-radius);
}

.radio:checked + label::before,
.checkbox:checked + label::before {
  background-color: var(--color-primary-darker);
  box-shadow: none;
  border-color: var(--color-primary-darker);
  transition: transform 0.2s;
}

.radio:active + label::before,
.checkbox:active + label::before {
  transform: scale(0.8);
  transition: transform 0.2s;
}

.radio:checked:active + label::before,
.checkbox:checked:active + label::before {
  transform: none;
  transition: none;
}

.radio:checked + label::before {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cg class='nc-icon-wrapper' fill='%23ffffff'%3E%3Ccircle cx='8' cy='8' r='8' fill='%23ffffff'%3E%3C/circle%3E%3C/g%3E%3C/svg%3E");
  background-size: var(--radio-marker-size);
  border-color: var(--color-primary);
  box-shadow: 0 0 0 3px hsla(var(--color-primary-h), var(--color-primary-s), var(--color-primary-l), 0.2);
}

.checkbox:checked + label::before {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cg class='nc-icon-wrapper' stroke-width='2' fill='%23ffffff' stroke='%23ffffff'%3E%3Cpolyline fill='none' stroke='%23ffffff' stroke-linecap='round' stroke-linejoin='round' stroke-miterlimit='10' points='1,9 5,13 15,3 ' data-cap='butt'%3E%3C/polyline%3E%3C/g%3E%3C/svg%3E");
  background-size: var(--checkbox-marker-size);
}

.radio:checked:active + label::before,
.checkbox:checked:active + label::before,
.radio:focus + label::before,
.checkbox:focus + label::before {
  border-color: var(--color-primary);
  box-shadow: 0 0 0 3px hsla(var(--color-primary-h), var(--color-primary-s), var(--color-primary-l), 0.2);
}

.radio--bg + label,
.checkbox--bg + label {
  padding: var(--space-xxxxs) var(--space-xxxs);
  border-radius: var(--radius-md);
  transition: background 0.2s;
}

.radio--bg + label:hover,
.checkbox--bg + label:hover {
  background-color: var(--color-contrast-lower);
}

.radio--bg:active + label,
.checkbox--bg:active + label,
.radio--bg:focus + label,
.checkbox--bg:focus + label {
  background-color: hsla(var(--color-primary-h), var(--color-primary-s), var(--color-primary-l), 0.1);
}

/* -------------------------------- 

File#: _1_read-more
Title: Read More
Descr: A truncated paragraph with the option of reading more content
Usage: codyhouse.co/license

-------------------------------- */

.js .read-more {
  opacity: 0;
}

.js .read-more--loaded {
  opacity: 1;
}

.read-more__btn {
  background-color: transparent;
  padding: 0;
  border: 0;
  border-radius: 0;
  color: inherit;
  line-height: inherit;
  appearance: none;
  cursor: pointer;
  color: var(--color-primary);
  text-decoration: underline;
}

/* -------------------------------- 

File#: _1_responsive-sidebar
Title: Responsive Sidebar
Descr: Responsive sidebar container
Usage: codyhouse.co/license

-------------------------------- */

.sidebar:not(.sidebar--static) {
  position: fixed;
  top: 0;
  left: 0;
  z-index: var(--zindex-fixed-element);
  width: 100%;
  height: 100%;
  visibility: hidden;
  transition: visibility 0s 0.3s;
}

.sidebar:not(.sidebar--static)::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: hsla(var(--color-black-h), var(--color-black-s), var(--color-black-l), 0);
  transition: background-color 0.3s;
  z-index: 1;
}

.sidebar:not(.sidebar--static) .sidebar__panel {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
  width: 100%;
  max-width: 380px;
  height: 100%;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  background-color: var(--color-bg);
  transform: translateX(-100%);
  transition: 0.3s;
}

.sidebar:not(.sidebar--static).sidebar--right-on-mobile .sidebar__panel {
  left: auto;
  right: 0;
  transform: translateX(100%);
}

.sidebar:not(.sidebar--static).sidebar--is-visible {
  visibility: visible;
  transition: none;
}

.sidebar:not(.sidebar--static).sidebar--is-visible::after {
  background-color: hsla(var(--color-black-h), var(--color-black-s), var(--color-black-l), 0.85);
}

.sidebar:not(.sidebar--static).sidebar--is-visible .sidebar__panel {
  transform: translateX(0);
  box-shadow: var(--shadow-md);
}

.sidebar__header {
  background-color: var(--color-bg);
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: var(--space-sm) var(--space-md);
  border-bottom: 2px solid var(--color-contrast-lower);
  position: sticky;
  top: 0;
}

.sidebar__close-btn {
  width: 2em;
  height: 2em;
  display: flex;
  border-radius: 50%;
  background-color: var(--color-bg);
  box-shadow: var(--shadow-sm);
  transition: 0.2s;
  flex-shrink: 0;
}

.sidebar__close-btn:hover {
  box-shadow: var(--shadow-md);
}

.sidebar__close-btn .icon {
  display: block;
  color: var(--color-contrast-high);
  margin: auto;
}

.sidebar--static,
html:not(.js) .sidebar {
  flex-shrink: 0;
  flex-grow: 1;
}

.sidebar--static .sidebar__header,
html:not(.js) .sidebar .sidebar__header {
  display: none;
}

.sidebar--sticky-on-desktop {
  position: sticky;
  top: var(--space-sm);
  max-height: calc(100vh - var(--space-sm));
  overflow: auto;
  -webkit-overflow-scrolling: touch;
}

.js .sidebar {
  opacity: 0;
}

.js .sidebar--loaded {
  opacity: 1;
}

html:not(.js) .sidebar {
  position: static;
}

[class*=sidebar--static]::before {
  display: none;
}

.sidebar--static\@xs::before {
  content: "mobile";
}

@media (min-width: 32rem) {
  .sidebar--static\@xs::before {
    content: "static";
  }
}

.sidebar--static\@sm::before {
  content: "mobile";
}

@media (min-width: 48rem) {
  .sidebar--static\@sm::before {
    content: "static";
  }
}

.sidebar--static\@md::before {
  content: "mobile";
}

@media (min-width: 64rem) {
  .sidebar--static\@md::before {
    content: "static";
  }
}

.sidebar--static\@lg::before {
  content: "mobile";
}

@media (min-width: 80rem) {
  .sidebar--static\@lg::before {
    content: "static";
  }
}

.sidebar--static\@xl::before {
  content: "mobile";
}

@media (min-width: 90rem) {
  .sidebar--static\@xl::before {
    content: "static";
  }
}

/* -------------------------------- 

File#: _1_steps
Title: Steps
Descr: Multi-step indicator
Usage: codyhouse.co/license

-------------------------------- */

:root {
  --steps-number: 4;
  --step-separator-line-stroke: 3px;
  --step-separator-line-gap: 3px;
  --step-circle-size: 32px;
  --step-circle-font-size: 16px;
}

.steps {
  background-color: var(--color-contrast-lower);
  padding: var(--space-md);
  border-radius: var(--radius-md);
  font-weight: 500;
}

.steps__list {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: calc(var(--space-xs) * -1);
}

.step {
  display: inline-block;
  display: inline-flex;
  align-items: center;
  margin-bottom: var(--space-xs);
}

.step__label {
  color: inherit;
  text-decoration: none;
}

.step--completed .step__label,
.step--current .step__label {
  color: var(--color-primary);
}

.steps {
  background-color: transparent;
  padding: calc(var(--step-circle-size) / 2) 0 0;
  border-radius: 0px;
}

.steps__list {
  margin-bottom: initial;
  align-items: flex-start;
}

@supports (grid-area: auto) {
  .steps__list {
    display: grid;
    align-items: start;
    grid-template-columns: repeat(var(--steps-number), 1fr);
  }
}

.step {
  float: left;
  width: calc(100% / var(--steps-number));
  justify-content: center;
  text-align: center;
  position: relative;
  margin-bottom: initial;
  padding: 0 var(--space-xs);
}

@supports (grid-area: auto) {
  .step {
    width: auto;
  }
}

.step__label {
  display: inline-block;
  margin-top: calc(var(--space-xxs) + var(--step-circle-size) / 2);
  display: none;
}

.step__separator {
  position: absolute;
  top: calc(var(--step-separator-line-stroke) * -1 / 2);
  left: calc(50% + var(--step-circle-size) / 2 + var(--step-separator-line-gap));
  height: var(--step-separator-line-stroke);
  width: calc(100% - var(--step-circle-size) - var(--step-separator-line-gap) * 2);
  margin: 0;
  background-color: var(--color-white);
  color: transparent;
  text-indent: 100%;
  white-space: nowrap;
  overflow: hidden;
}

.step--completed .step__separator {
  background-color: var(--color-black);
}

.step__circle {
  display: inline-block;
  line-height: var(--step-circle-size);
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--color-white);
  width: var(--step-circle-size);
  height: var(--step-circle-size);
  border-radius: 50%;
  position: absolute;
  left: calc(50% - var(--step-circle-size) / 2);
  top: calc(var(--step-circle-size) * -1 / 2);
  font-size: var(--step-circle-font-size);
}

.step__circle .icon {
  width: var(--step-circle-font-size);
  height: var(--step-circle-font-size);
}

.step--completed .step__circle,
.step--current .step__circle {
  background-color: var(--color-black);
  color: var(--color-white);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* screen reader */

.step--completed .step__label::after,
.step--current .step__label::after {
  position: absolute;
  clip: rect(1px, 1px, 1px, 1px);
  clip-path: inset(50%);
}

.step--completed .step__label::after {
  content: "step completed";
}

.step--current .step__label::after {
  content: "step current";
}

/* -------------------------------- 

File#: _1_sticky-banner
Title: Sticky Banner
Descr: A sticky banner that slides in passed a target element or a scrolling offset.
Usage: codyhouse.co/license

-------------------------------- */

.sticky-banner {
  position: fixed;
  top: -1px;
  left: 0;
  width: 100%;
  z-index: var(--zindex-fixed-element);
  transform: translateY(-100%);
  transition: transform 0.3s;
}

.sticky-banner--bottom {
  top: auto;
  bottom: -1px;
  transform: translateY(100%);
}

.sticky-banner--visible {
  transform: translateY(0);
}

.sticky-banner:not(.sticky-banner--visible) {
  box-shadow: none;
}

/* -------------------------------- 

File#: _1_tabbed-navigation-v2
Title: Tabbed Navigation v2
Descr: Tabbed (secondary) navigation
Usage: codyhouse.co/license

-------------------------------- */

.tabs-nav-v2 {
  --tabs-nav-border-width: 1px;
  display: flex;
  flex-wrap: wrap;
  border-bottom: var(--tabs-nav-border-width) solid var(--color-accent);
}

.tabs-nav-v2 li {
  display: inline-block;
  margin-right: var(--space-xs);
  margin-bottom: var(--space-xs);
}

.tabs-nav-v2__item {
  display: inline-block;
  padding: var(--space-xs) var(--space-sm);
  border-radius: var(--radius-md);
  background-color: var(--color-contrast-low);
  color: inherit;
  white-space: nowrap;
  font-family: var(--font-primary);
  text-decoration: none;
}

.tabs-nav-v2__item--selected,
.tabs-nav-v2__item[aria-selected=true] {
  color: var(--color-white);
  background-color: var(--color-contrast-higher);
}

@media (min-width: 64rem) {
  .tabs-nav-v2 li {
    margin: 0;
  }

  .tabs-nav-v2__item {
    border-radius: var(--radius-md) var(--radius-md) 0 0;
    background-color: transparent;
    border: var(--tabs-nav-border-width) solid transparent;
    border-bottom-width: 0;
  }

  .tabs-nav-v2__item:hover {
    background-color: var(--color-contrast-lower);
  }

  .tabs-nav-v2__item--selected,
  .tabs-nav-v2__item[aria-selected=true] {
    position: relative;
    background-color: var(--color-bg);
    color: var(--color-black);
    border-color: var(--color-accent);
    font-weight: 600;
  }

  .tabs-nav-v2__item--selected::after,
  .tabs-nav-v2__item[aria-selected=true]::after {
    content: "";
    position: absolute;
    bottom: calc(var(--tabs-nav-border-width) * -1);
    left: 0;
    width: 100%;
    height: var(--tabs-nav-border-width);
    background-color: var(--color-bg);
  }

  .tabs-nav-v2__item--selected:hover,
  .tabs-nav-v2__item[aria-selected=true]:hover {
    background-color: var(--color-bg);
  }
}

/* -------------------------------- 

File#: _1_table-v2
Title: Table v2
Descr: Basic table template
Usage: codyhouse.co/license

-------------------------------- */

.tbl {
  position: relative;
  z-index: 1;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
}

.tbl::-webkit-scrollbar {
  height: 8px;
  width: 8px;
}

.tbl::-webkit-scrollbar-track {
  background-color: var(--color-contrast-lower);
}

.tbl::-webkit-scrollbar-thumb {
  background-color: hsla(var(--color-contrast-higher-h), var(--color-contrast-higher-s), var(--color-contrast-higher-l), 0.9);
  border-radius: 50em;
}

.tbl::-webkit-scrollbar-thumb:hover {
  background-color: var(--color-contrast-higher);
}

.tbl__table {
  width: 100%;
}

.tbl__body .tbl__row {
  border-bottom: 1px solid var(--color-contrast-lower);
  transition: 0.2s;
}

.tbl__body .tbl__row:hover {
  background-color: hsla(var(--color-contrast-lower-h), var(--color-contrast-lower-s), var(--color-contrast-lower-l), 0.35);
}

.tbl__body .tbl__row:last-child {
  border-bottom: none;
}

.tbl__cell {
  padding: var(--space-xxxxs);
}

/* -------------------------------- 

File#: _1_tabs
Title: Tabs
Descr: A list of content sections (panels), accessible one at a time using control labels
Usage: codyhouse.co/license

-------------------------------- */

.tabs__control {
  text-decoration: none;
  color: var(--color-contrast-medium);
}

.tabs__control:focus {
  outline: 2px solid hsla(var(--color-primary-h), var(--color-primary-s), var(--color-primary-l), 0.2);
  outline-offset: 2px;
}

.tabs__control:hover {
  color: var(--color-contrast-high);
}

.js .tabs__control[aria-selected=true] {
  color: var(--color-contrast-high);
  text-decoration: underline;
}

/* -------------------------------- 

File#: _2_accordion-v2
Title: Accordion v2
Descr: Stacked, expandable content sections
Usage: codyhouse.co/license

-------------------------------- */

:root {
  --accordion-v2-icon-size: 1em;
  --accordion-v2-icon-stroke-width: 1.5px;
}

.accordion-v2__item {
  box-shadow: var(--shadow-sm);
  transition: 0.3s;
}

.accordion-v2__item:hover {
  box-shadow: var(--shadow-md);
}

.accordion-v2__header {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  text-align: left;
  cursor: pointer;
}

.accordion-v2__header .icon {
  font-size: var(--accordion-v2-icon-size);
}

.accordion-v2__header .icon .icon__group {
  stroke-width: var(--accordion-v2-icon-stroke-width);
}

.accordion-v2__icon-arrow .icon__group {
  will-change: transform;
  transform-origin: 8px 8px;
}

.accordion-v2__icon-arrow .icon__group > * {
  transform-origin: 8px 8px;
  stroke-dasharray: 17;
  transform: translateY(3px);
}

.accordion-v2__icon-arrow .icon__group > *:first-child {
  stroke-dashoffset: 8.5;
}

.accordion-v2__icon-arrow .icon__group > *:last-child {
  stroke-dashoffset: 8.5;
}

.accordion-v2__item--is-open > .accordion-v2__header {
  background-color: var(--color-accent);
}

.accordion-v2__item--is-open > .accordion-v2__header > .accordion-v2__icon-arrow .icon__group {
  transform: rotate(-90deg);
}

.accordion-v2__item--is-open > .accordion-v2__header > .accordion-v2__icon-arrow .icon__group > *:first-child,
.accordion-v2__item--is-open > .accordion-v2__header > .accordion-v2__icon-arrow .icon__group *:last-child {
  stroke-dashoffset: 0;
  transform: translateY(0px);
}

.js .accordion-v2__panel {
  display: none;
  will-change: height;
  transform: translateZ(0px);
}

.js .accordion-v2__item--is-open > .accordion-v2__panel {
  display: block;
}

.accordion-v2[data-animation=on] .accordion-v2__item--is-open .accordion-v2__panel > * {
  animation: accordion-v2-entry-animation 0.4s var(--ease-out);
}

.accordion-v2[data-animation=on] .accordion-v2__icon-arrow .icon__group {
  transition: transform 0.3s var(--ease-out);
}

.accordion-v2[data-animation=on] .accordion-v2__icon-arrow .icon__group > * {
  transition: transform 0.3s, stroke-dashoffset 0.3s;
  transition-timing-function: var(--ease-out);
}

@keyframes accordion-v2-entry-animation {
  from {
    opacity: 0;
    transform: translateY(-10px);
  }

  to {
    opacity: 1;
    transform: translateY(0px);
  }
}

/* -------------------------------- 

File#: _2_carousel
Title: Carousel
Descr: Display and cycle through a collection of items
Usage: codyhouse.co/license

-------------------------------- */

:root {
  --carousel-grid-gap: var(--space-xs);
  --carousel-item-auto-size: 150px;
  --carousel-transition-duration: 0.5s;
}

.carousel {
  position: relative;
}

.carousel__list {
  display: flex;
  flex-wrap: nowrap;
  will-change: transform;
}

.carousel__item {
  flex-shrink: 0;
  width: var(--carousel-item-auto-size);
  margin-right: var(--carousel-grid-gap);
  margin-bottom: var(--carousel-grid-gap);
}

@media (min-width: 64rem) {
  .carousel__item {
    width: calc(var(--carousel-item-auto-size) * 2);
  }
}

.js .carousel__list--animating {
  transition-property: transform;
  transition-duration: var(--carousel-transition-duration);
  transition-timing-function: var(--ease-out);
}

.js .carousel__item {
  opacity: 0;
  margin-bottom: 0;
}

.js .carousel--loaded .carousel__item {
  opacity: 1;
}

.js .carousel:not(.carousel--is-dragging) .carousel__list:not(.carousel__list--animating) .carousel__item[tabindex="-1"] > * {
  visibility: hidden;
}

.js .carousel[data-drag=on] .carousel__item {
  user-select: none;
}

.js .carousel[data-drag=on] .carousel__item img {
  pointer-events: none;
}

.carousel__control {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  background-color: var(--color-bg);
  border-radius: 50%;
  box-shadow: var(--shadow-sm);
  z-index: 1;
  transition: 0.2s;
}

.carousel__control:active {
  transform: translateY(1px);
}

.carousel__control:hover {
  box-shadow: var(--shadow-md);
}

.carousel__control[disabled] {
  pointer-events: none;
  background-color: var(--color-contrast-lower);
  color: var(--color-contrast-low);
  box-shadow: none;
}

.carousel__control .icon {
  display: block;
  font-size: 20px;
}

.carousel__navigation {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fit, 10px);
  gap: var(--space-xs);
  justify-content: center;
  align-items: center;
  margin-top: var(--space-sm);
}

.carousel__nav-item {
  display: inline-block;
  margin: 0 var(--space-xxxs);
}

@supports (grid-area: auto) {
  .carousel__nav-item {
    margin: 0;
  }
}

.carousel__nav-item button {
  display: block;
  position: relative;
  font-size: 10px;
  height: 1em;
  width: 1em;
  border-radius: 50%;
  background-color: var(--color-contrast-high);
  opacity: 0.4;
  cursor: pointer;
  transition: background 0.3s;
}

.carousel__nav-item button::before {
  content: "";
  position: absolute;
  top: calc(50% - 0.5em);
  left: calc(50% - 0.5em);
  font-size: 16px;
  height: 1em;
  width: 1em;
  border-radius: inherit;
  border: 1px solid var(--color-contrast-high);
  opacity: 0;
  transform: scale(0);
  transition: 0.3s;
}

.carousel__nav-item button:focus {
  outline: none;
}

.carousel__nav-item button:focus::before {
  opacity: 1;
  transform: scale(1);
}

.carousel__nav-item--selected button {
  opacity: 1;
}

.carousel__navigation--pagination {
  grid-template-columns: repeat(auto-fit, minmax(24px, auto));
}

.carousel__navigation--pagination .carousel__nav-item button {
  width: 100%;
  height: auto;
  color: var(--color-bg);
  font-size: var(--text-xs);
  padding: var(--space-xxxs) var(--space-xxs);
  border-radius: var(--radius-md);
  text-align: center;
}

.carousel__navigation--pagination .carousel__nav-item button:focus {
  outline: 1px solid var(--color-primary);
  outline-offset: 2px;
}

html:not(.js) .carousel__list {
  overflow: auto;
}

/* -------------------------------- 

File#: _2_cart-drawer
Title: Cart Drawer
Descr: A cart template in a drawer panel
Usage: codyhouse.co/license

-------------------------------- */

.dr-cart__product {
  display: grid;
  grid-template-columns: 80px 1fr auto;
  grid-gap: var(--space-sm);
  align-items: start;
  padding: var(--space-md) 0;
}

.dr-cart__product:not(:last-child) {
  border-bottom: 1px solid var(--color-contrast-lower);
}

.dr-cart__img {
  display: flex;
  overflow: hidden;
  border-radius: var(--radius-sm);
  box-shadow: var(--shadow-md);
  transition: 0.2s;
  align-items: center;
  height: 100%;
  padding: var(--space-xxxs);
  background: var(--color-bg);
}

.dr-cart__img img {
  display: block;
}

.dr-cart__img:hover {
  opacity: 0.85;
  box-shadow: var(--shadow-sm);
}

.dr-cart__select {
  --select-icon-size: 0.85em;
  --select-icon-right-margin: var(--space-xxs);
  font-size: 0.875em;
  width: 3.6em;
}

.dr-cart__select .select__input {
  padding: var(--space-xxxxs) var(--space-xxxs);
}

.dr-cart__remove-btn {
  background-color: transparent;
  padding: 0;
  border: 0;
  border-radius: 0;
  color: inherit;
  line-height: inherit;
  appearance: none;
  font-size: var(--text-sm);
  cursor: pointer;
  text-decoration: underline;
}

.dr-cart__remove-btn:focus {
  outline-offset: 2px;
}

/* -------------------------------- 

File#: _2_dropdown
Title: Dropdown
Descr: A hoverable link that toggles the visibility of a dropdown list
Usage: codyhouse.co/license

-------------------------------- */

:root {
  --dropdown-item-padding: var(--space-xxs) var(--space-sm);
}

.dropdown {
  position: relative;
}

.dropdown__menu {
  width: 200px;
  border-radius: var(--radius-md);
  padding: var(--space-xxs) 0;
  background-color: var(--color-bg);
  box-shadow: var(--shadow-sm);
  z-index: var(--zindex-popover);
  position: absolute;
  left: 0;
  top: 100%;
  --space-unit: 1rem;
  --space-xxxxs: calc(0.125 * 1rem);
  --space-xxxs: calc(0.25 * 1rem);
  --space-xxs: calc(0.375 * 1rem);
  --space-xs: calc(0.5 * 1rem);
  --space-sm: calc(0.75 * 1rem);
  --space-md: calc(1.25 * 1rem);
  --space-lg: calc(2 * 1rem);
  --space-xl: calc(3.25 * 1rem);
  --space-xxl: calc(5.25 * 1rem);
  --space-xxxl: calc(8.5 * 1rem);
  --space-xxxxl: calc(13.75 * 1rem);
  --component-padding: var(--space-md);
  --text-xs: calc((1rem / 1.2) / 1.2);
  --text-sm: calc(var(--text-xs) * 1.2);
  --text-md: calc(var(--text-sm) * 1.2 * 1.2);
  --text-lg: calc(var(--text-md) * 1.2);
  --text-xl: calc(var(--text-lg) * 1.2);
  --text-xxl: calc(var(--text-xl) * 1.2);
  --text-xxxl: calc(var(--text-xxl) * 1.2);
  --text-xxxxl: calc(var(--text-xxxl) * 1.2);
  font-size: 1rem;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.2s, visibility 0s 0.2s;
}

@media (pointer: fine) {
  .dropdown__wrapper:hover > .dropdown__menu,
  .dropdown__sub-wrapper:hover > .dropdown__menu {
    opacity: 1;
    visibility: visible;
    transition: opacity 0.2s;
  }

  .dropdown__sub-wrapper:hover > .dropdown__menu {
    left: 100%;
  }
}

.dropdown__item {
  display: block;
  text-decoration: none;
  color: var(--color-contrast-high);
  padding: var(--dropdown-item-padding);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.dropdown__item:hover,
.dropdown__item.dropdown__item--hover {
  background-color: var(--color-contrast-lower);
}

.dropdown__separator {
  height: 1px;
  background-color: var(--color-contrast-low);
  margin: var(--dropdown-item-padding);
}

.dropdown__sub-wrapper {
  position: relative;
}

.dropdown__sub-wrapper > .dropdown__item {
  position: relative;
  padding-right: calc(var(--space-sm) + 12px);
}

.dropdown__sub-wrapper > .dropdown__item .icon {
  position: absolute;
  display: block;
  width: 12px;
  height: 12px;
  right: var(--space-xxs);
  top: calc(50% - 6px);
}

.dropdown__sub-wrapper > .dropdown__menu {
  top: calc(var(--space-xxs) * -1);
  box-shadow: var(--shadow-md);
}

.js .dropdown__menu {
  top: calc(100% + 4px);
}

.js .dropdown__sub-wrapper .dropdown__menu {
  top: calc(var(--space-xxs) * -1);
}

@media (pointer: fine) {
  .js .dropdown__menu--is-visible {
    opacity: 1;
    visibility: visible;
    transition: opacity 0.2s;
  }
}

.js .dropdown__menu--is-hidden {
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.2s, visibility 0s 0.2s;
}

.js .dropdown__sub-wrapper > .dropdown__menu--is-visible,
.js .dropdown__sub-wrapper > .dropdown__menu--is-hidden {
  left: 100%;
}

.js .dropdown__sub-wrapper > .dropdown__menu--is-visible.dropdown__menu--left,
.js .dropdown__sub-wrapper > .dropdown__menu--is-hidden.dropdown__menu--left {
  left: -100%;
}

/* -------------------------------- 

File#: _3_advanced-filter
Title: Advanced Filter
Descr: A filter panel template
Usage: codyhouse.co/license

-------------------------------- */

.adv-filter {
  font-family: var(--font-primary);
}

.adv-filter .sidebar--static {
  width: 100%;
  max-width: 300px;
  background: var(--color-accent-lighter);
}

.adv-filter .accordion {
  --accordion-icon-stroke-width: 1px;
}

.adv-filter .slider {
  --slider-width: 80%;
}

@media (min-width: 64rem) {
  .adv-filter__checkbox-list,
  .adv-filter__radio-list {
    font-size: var(--text-sm);
    --checkbox-radio-size: 1.25em;
    --checkbox-radio-translate-y: 0.25em;
  }
}

/* -------------------------------- 

File#: _3_mega-site-navigation
Title: Mega-Site Navigation
Descr: Navigation template for mega-sites
Usage: codyhouse.co/license

-------------------------------- */

:root {
  --mega-nav-height: 50px;
  --mega-nav-content-max-width: var(--max-width-lg);
}

@media (min-width: 64rem) {
  :root {
    --mega-nav-height: 70px;
  }
}

.mega-nav {
  height: var(--mega-nav-height);
  width: 100%;
  z-index: var(--zindex-header);
  box-shadow: inset 0px -1px 0px hsla(var(--color-contrast-higher-h), var(--color-contrast-higher-s), var(--color-contrast-higher-l), 0.1);
}

.mega-nav--expanded {
  background-color: var(--color-bg);
}

.mega-nav__container {
  width: calc(100% - 2 * var(--component-padding));
  height: 100%;
  max-width: var(--mega-nav-content-max-width);
  margin-left: auto;
  margin-right: auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.mega-nav__logo {
  display: block;
  width: 100px;
  height: 28px;
}

@media (min-width: 64rem) {
  .mega-nav__logo {
    width: 130px;
    height: 37px;
  }
}

.mega-nav__logo > * {
  width: inherit;
  height: inherit;
}

.mega-nav__icon-btns {
  display: flex;
  align-items: center;
}

.mega-nav__icon-btn {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  font-size: 24px;
  cursor: pointer;
  transition: 0.2s;
  margin-left: var(--space-xxs);
}

.mega-nav__icon-btn .icon {
  display: block;
}

.mega-nav__icon-btn .icon__group {
  stroke-width: 2px;
}

.mega-nav__icon-btn .icon__group > * {
  transition: transform 0.3s var(--ease-in-out), stroke-dashoffset 0.3s, opacity 0.3s;
}

.mega-nav__icon-btn .counter {
  position: absolute;
  top: -2px;
  right: -2px;
  font-size: 0.6rem;
  line-height: 1.2;
  font-family: var(--font-primary);
}

.mega-nav__icon-btn--menu .icon__group > * {
  stroke-dasharray: 24;
}

.mega-nav__icon-btn--menu .icon__group > *:nth-child(1) {
  transform-origin: 12px 6px;
}

.mega-nav__icon-btn--menu .icon__group > *:nth-child(2) {
  stroke-dashoffset: 0;
}

.mega-nav__icon-btn--menu .icon__group > *:nth-child(3) {
  transform-origin: 12px 18px;
}

.mega-nav__icon-btn--menu.mega-nav__icon-btn--state-b .icon__group > *:nth-child(1) {
  transform: translateY(6px) rotate(-45deg);
}

.mega-nav__icon-btn--menu.mega-nav__icon-btn--state-b .icon__group > *:nth-child(2) {
  stroke-dashoffset: 24;
}

.mega-nav__icon-btn--menu.mega-nav__icon-btn--state-b .icon__group > *:nth-child(3) {
  transform: translateY(-6px) rotate(45deg);
}

.mega-nav__icon-btn--search .icon__group {
  transform-origin: 12px 12px;
  transform: rotate(0deg);
  transition: transform 0.3s var(--ease-out);
}

.mega-nav__icon-btn--search .icon__group > *:nth-child(1) {
  stroke-dashoffset: 34;
  stroke-dasharray: 24;
}

.mega-nav__icon-btn--search .icon__group > *:nth-child(2) {
  stroke-dashoffset: 24;
  stroke-dasharray: 24;
}

.mega-nav__icon-btn--search .icon__group > *:nth-child(3) {
  transform-origin: 9.5px 9.5px;
  transform: rotate(45deg);
  stroke-dashoffset: 84;
  stroke-dasharray: 42;
  opacity: 1;
}

.mega-nav__icon-btn--search.mega-nav__icon-btn--state-b .icon__group {
  transform: rotate(-90deg);
}

.mega-nav__icon-btn--search.mega-nav__icon-btn--state-b .icon__group > *:nth-child(1) {
  stroke-dashoffset: 48;
}

.mega-nav__icon-btn--search.mega-nav__icon-btn--state-b .icon__group > *:nth-child(2) {
  stroke-dashoffset: 48;
}

.mega-nav__icon-btn--search.mega-nav__icon-btn--state-b .icon__group > *:nth-child(3) {
  stroke-dashoffset: 42;
  opacity: 0;
}

.mega-nav__arrow-icon {
  font-size: 16px;
  color: currentColor;
  transition: color 0.2s;
}

.mega-nav__arrow-icon .icon {
  display: block;
}

.mega-nav__arrow-icon .icon__group {
  stroke-width: 2px;
  will-change: transform;
  transform-origin: 8px 8px;
  transition: transform 0.3s var(--ease-out);
}

.mega-nav__arrow-icon .icon__group > * {
  stroke-dasharray: 17;
  transform-origin: 8px 8px;
  transform: translateY(3px);
  transition: transform 0.3s, stroke-dashoffset 0.3s;
  transition-timing-function: var(--ease-out);
}

.mega-nav__arrow-icon .icon__group > *:first-child {
  stroke-dashoffset: 8.5;
}

.mega-nav__arrow-icon .icon__group > *:last-child {
  stroke-dashoffset: 8.5;
}

.mega-nav__label {
  letter-spacing: 0.05em;
  font-size: var(--text-xs);
  font-family: var(--font-primary);
  text-transform: uppercase;
}

.mega-nav__card img {
  transition: opacity 0.3s;
}

.mega-nav__card img:hover {
  opacity: 0.85;
}

.mega-nav__card-title {
  text-decoration: none;
}

.mega-nav__card-title:hover {
  text-decoration: underline;
}

.mega-nav__view-all {
  grid-column: 1/4;
  grid-row: 2/2;
}

.mega-nav__sub-nav-column:last-child {
  grid-column: 4/4;
  grid-row: 1/3;
}

.mega-nav--mobile .mega-nav__icon-btns--desktop {
  display: none;
}

.mega-nav--mobile .mega-nav__nav,
.mega-nav--mobile .mega-nav__search {
  display: none;
  position: absolute;
  top: var(--mega-nav-height);
  left: 0;
  width: 100%;
  height: calc(100vh - var(--mega-nav-height) - var(--mega-nav-offset-y, 0px));
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  background-color: var(--color-bg);
  box-shadow: var(--shadow-lg);
  z-index: var(--zindex-header);
}

.mega-nav--mobile .mega-nav__nav--is-visible,
.mega-nav--mobile .mega-nav__search--is-visible {
  display: block;
}

.mega-nav--mobile .mega-nav__nav--is-visible > *,
.mega-nav--mobile .mega-nav__search--is-visible > * {
  animation: mega-nav-entry-animation 0.5s var(--ease-out);
}

.mega-nav--mobile .mega-nav__nav-inner,
.mega-nav--mobile .mega-nav__search-inner {
  padding: var(--space-md) 0;
  width: calc(100% - 2 * var(--component-padding));
  max-width: var(--mega-nav-content-max-width);
  margin-left: auto;
  margin-right: auto;
}

.mega-nav--mobile .mega-nav__label {
  margin: var(--space-lg) 0 var(--space-xs);
}

.mega-nav--mobile .mega-nav__item {
  border-bottom: 1px solid var(--color-contrast-lower);
}

.mega-nav--mobile .mega-nav__control {
  display: flex;
  align-items: center;
  width: 100%;
  font-size: var(--text-sm);
  text-align: left;
  padding: var(--space-sm) 0;
  text-decoration: none;
  cursor: pointer;
  font-family: var(--font-primary);
  color: var(--color-black);
  text-transform: uppercase;
}

.mega-nav--mobile a[aria-current=page] {
  color: var(--color-primary);
}

.mega-nav--mobile .mega-nav__arrow-icon {
  margin-left: auto;
  margin-right: 12px;
}

.mega-nav--mobile .mega-nav__btn {
  width: 100%;
  margin: var(--space-sm) 0;
  font-size: var(--text-md);
}

.mega-nav--mobile .mega-nav__sub-nav-wrapper {
  display: none;
  padding: 0 var(--space-md) var(--space-lg);
  overflow: hidden;
}

.mega-nav--mobile .mega-nav__sub-items:not(:last-child) {
  margin-bottom: var(--space-lg);
}

.mega-nav--mobile .mega-nav__sub-item {
  border-bottom: 1px solid var(--color-contrast-lower);
}

.mega-nav--mobile .mega-nav__sub-link {
  display: block;
  color: var(--color-primary-darker);
  text-decoration: none;
  padding: var(--space-xxs) 0;
}

.mega-nav--mobile .mega-nav__quick-link {
  display: block;
  color: var(--color-primary-darker);
  text-decoration: none;
  border-bottom: 1px solid var(--color-contrast-lower);
  padding: var(--space-xs) 0;
}

.mega-nav--mobile .mega-nav__sub-nav--layout-1 .mega-nav__sub-items {
  margin-bottom: 0;
}

.mega-nav--mobile .mega-nav__sub-nav--layout-1 .mega-nav__tabs {
  display: none;
}

.mega-nav--mobile .mega-nav__sub-nav--layout-3 {
  padding-top: var(--space-md);
  display: grid;
  gap: var(--space-md);
  grid-template-columns: repeat(auto-fill, minmax(260px, 1fr));
}

.mega-nav--mobile .mega-nav__item--expanded .mega-nav__arrow-icon .icon__group > *:first-child {
  transform: translateY(-3px) rotate(-90deg);
}

.mega-nav--mobile .mega-nav__item--expanded .mega-nav__arrow-icon .icon__group > *:last-child {
  transform: translateY(-3px) rotate(90deg);
}

.mega-nav--mobile .mega-nav__item--expanded .mega-nav__sub-nav-wrapper {
  display: block;
}

.mega-nav--mobile .mega-nav__item--expanded .mega-nav__sub-nav-wrapper > * {
  animation: mega-nav-entry-animation 0.5s var(--ease-out);
}

.mega-nav--desktop .mega-nav__icon-btns--mobile,
.mega-nav--desktop .mega-nav__sub-nav-wrapper,
.mega-nav--desktop .mega-nav__search,
.mega-nav--desktop .mega-nav__label {
  display: none;
}

.mega-nav--desktop .mega-nav__logo {
  flex-shrink: 0;
  margin-right: var(--space-sm);
}

.mega-nav--desktop .mega-nav__nav {
  flex-grow: 1;
  height: 100%;
}

.mega-nav--desktop .mega-nav__nav-inner {
  height: 100%;
  display: flex;
  justify-content: space-between;
}

.mega-nav--desktop .mega-nav__items {
  display: flex;
  height: 100%;
}

.mega-nav--desktop .mega-nav__item {
  display: flex;
  align-items: center;
}

.mega-nav--desktop .mega-nav__control {
  font-family: var(--font-primary);
  position: relative;
  display: flex;
  align-items: center;
  padding: 0 var(--space-md);
  height: 100%;
  font-size: var(--text-sm);
  text-decoration: none;
  transition: 0.2s;
  text-transform: uppercase;
}

.mega-nav--desktop .mega-nav__control::after {
  content: "";
  position: absolute;
  bottom: 0px;
  left: 0;
  width: 100%;
  height: 1px;
  background-color: var(--color-primary);
  pointer-events: none;
  opacity: 0;
  transition: opacity 0.2s;
}

.mega-nav--desktop .mega-nav__control .mega-nav__arrow-icon {
  margin-left: var(--space-xxs);
}

.mega-nav--desktop .mega-nav__control:hover {
  cursor: pointer;
  background-color: hsla(var(--color-contrast-higher-h), var(--color-contrast-higher-s), var(--color-contrast-higher-l), 0.05);
}

.mega-nav--desktop .mega-nav__sub-nav-wrapper,
.mega-nav--desktop .mega-nav__search {
  position: absolute;
  top: var(--mega-nav-height);
  left: 0;
  width: 100%;
  background-color: var(--color-bg);
  box-shadow: var(--shadow-lg);
  max-height: calc(100vh - var(--mega-nav-height) - var(--mega-nav-offset-y, 0px));
  overflow: auto;
  z-index: var(--zindex-header);
}

.mega-nav--desktop .mega-nav__sub-nav,
.mega-nav--desktop .mega-nav__search-inner {
  width: calc(100% - 2 * var(--component-padding));
  margin: 0 auto;
  padding: var(--space-lg) 0;
}

.mega-nav--desktop .mega-nav__sub-nav .mega-nav__label,
.mega-nav--desktop .mega-nav__search-inner .mega-nav__label {
  display: block;
  font-weight: 600;
}

.mega-nav--desktop .mega-nav__sub-nav {
  max-width: var(--mega-nav-content-max-width);
}

.mega-nav--desktop .mega-nav__search-inner {
  max-width: var(--max-width-xs);
}

.mega-nav--desktop .mega-nav__label {
  margin-bottom: var(--space-sm);
}

.mega-nav--desktop .mega-nav__sub-item:not(:last-child) {
  margin-bottom: var(--space-xxxxs);
}

.mega-nav--desktop .mega-nav__sub-link {
  font-size: 14px;
  color: var(--color-contrast-mediumer);
  text-decoration: none;
  letter-spacing: 0.05em;
}

.mega-nav--desktop .mega-nav__sub-link:hover {
  text-decoration: underline;
}

.mega-nav--desktop .mega-nav__quick-link {
  display: inline-block;
  margin-bottom: var(--space-xxs);
  font-size: var(--text-sm);
  color: var(--color-primary-darker);
  text-decoration: none;
}

.mega-nav--desktop .mega-nav__quick-link:hover {
  text-decoration: underline;
}

.mega-nav--desktop .mega-nav__btn {
  font-size: var(--text-sm);
  margin-left: var(--space-sm);
}

.mega-nav--desktop .mega-nav__sub-nav--layout-1 .mega-nav__sub-items {
  display: none;
}

.mega-nav--desktop .mega-nav__sub-nav--layout-1 .mega-nav__tabs {
  display: flex;
}

.mega-nav--desktop .mega-nav__sub-nav--layout-2 {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 2fr;
  gap: var(--space-sm);
}

.mega-nav--desktop .mega-nav__sub-nav--layout-3 {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: var(--space-md);
}

.mega-nav--desktop .mega-nav__sub-nav--layout-4 {
  text-align: center;
}

.mega-nav--desktop .mega-nav__sub-nav--layout-4 .mega-nav__sub-link {
  font-size: var(--text-lg);
}

.mega-nav--desktop .mega-nav__tabs-control {
  display: block;
  width: 100%;
  padding: var(--space-xs);
  overflow: hidden;
  text-decoration: none;
  cursor: pointer;
  transition: 0.2s;
  font-family: var(--font-primary);
  font-size: var(--text-xs);
  margin-left: -1px;
  letter-spacing: 0.05em;
  padding: var(--space-md);
  border-left: 1px solid transparent;
  font-weight: 700;
  text-transform: uppercase;
}

.mega-nav--desktop .mega-nav__tabs-control .icon {
  opacity: 0;
  transform: translateX(-10px);
  transition: transform 0.5s var(--ease-out), opacity 0.5s;
}

.mega-nav--desktop .mega-nav__tabs-control:hover {
  background-color: var(--color-contrast-lower);
  border-left: 1px solid var(--color-contrast-medium);
}

.mega-nav--desktop .mega-nav__tabs-control[aria-selected=true] {
  background-color: var(--color-primary);
  color: var(--color-white);
  border-left: 1px solid var(--color-primary);
}

.mega-nav--desktop .mega-nav__tabs-control[aria-selected=true] .icon {
  opacity: 1;
  transform: translateX(0px);
}

.mega-nav--desktop .mega-nav__tabs-img {
  display: block;
  overflow: hidden;
  border-radius: var(--radius-lg);
  transition: opacity 0.3s;
}

.mega-nav--desktop .mega-nav__tabs-img:hover {
  opacity: 0.85;
}

.mega-nav--desktop .mega-nav__tabs-list {
  display: grid;
  grid-template-columns: 1fr 1fr;
}

.mega-nav--desktop .mega-nav__item--expanded .mega-nav__control {
  background-color: hsla(var(--color-contrast-higher-h), var(--color-contrast-higher-s), var(--color-contrast-higher-l), 0.05);
}

.mega-nav--desktop .mega-nav__item--expanded .mega-nav__control::after {
  opacity: 1;
}

.mega-nav--desktop .mega-nav__item--expanded .mega-nav__arrow-icon .icon__group {
  transform: rotate(-90deg);
}

.mega-nav--desktop .mega-nav__item--expanded .mega-nav__arrow-icon .icon__group > *:first-child,
.mega-nav--desktop .mega-nav__item--expanded .mega-nav__arrow-icon .icon__group *:last-child {
  stroke-dashoffset: 0;
  transform: translateY(0px);
}

.mega-nav--desktop .mega-nav__item--expanded .mega-nav__sub-nav-wrapper {
  display: block;
}

.mega-nav--desktop .mega-nav__item--expanded .mega-nav__sub-nav {
  animation: mega-nav-entry-animation 0.5s var(--ease-out);
}

.mega-nav--desktop .mega-nav__icon-btn {
  border-radius: 50%;
}

.mega-nav--desktop .mega-nav__icon-btn:hover,
.mega-nav--desktop .mega-nav__icon-btn--state-b {
  background-color: hsla(var(--color-contrast-higher-h), var(--color-contrast-higher-s), var(--color-contrast-higher-l), 0.05);
}

.mega-nav--desktop .mega-nav__search--is-visible {
  display: block;
}

.mega-nav--desktop .mega-nav__search--is-visible .mega-nav__search-inner {
  animation: mega-nav-entry-animation 0.5s var(--ease-out);
}

@keyframes mega-nav-entry-animation {
  from {
    opacity: 0;
    transform: translateY(-10px);
  }

  to {
    opacity: 1;
    transform: translateY(0px);
  }
}

[class*=mega-nav--desktop]::before {
  display: none;
  content: "mobile";
}

@media (min-width: 32rem) {
  .mega-nav--desktop\@xs::before {
    content: "desktop";
  }
}

@media (min-width: 48rem) {
  .mega-nav--desktop\@sm::before {
    content: "desktop";
  }
}

@media (min-width: 64rem) {
  .mega-nav--desktop\@md::before {
    content: "desktop";
  }
}

@media (min-width: 80rem) {
  .mega-nav--desktop\@lg::before {
    content: "desktop";
  }
}

@media (min-width: 90rem) {
  .mega-nav--desktop\@xl::before {
    content: "desktop";
  }
}

html:not(.js) .mega-nav--mobile .mega-nav__logo {
  margin: var(--space-md) 0;
}

html:not(.js) .mega-nav--mobile .mega-nav__icon-btns {
  display: none;
}

html:not(.js) .mega-nav--mobile .mega-nav__container,
html:not(.js) .mega-nav--mobile .mega-nav__sub-nav-wrapper {
  display: block;
}

html:not(.js) .mega-nav--mobile .mega-nav__nav,
html:not(.js) .mega-nav--mobile .mega-nav__search {
  display: block;
  position: static;
  height: auto;
  box-shadow: none;
  border-top: 1px solid var(--color-contrast-lower);
}

.collection__items {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: var(--space-sm);
}

@media (min-width: 64rem) {
  .collection__items {
    grid-template-columns: repeat(3, 1fr);
  }
}

.collection__items li {
  margin-bottom: var(--space-sm);
}

.hero {
  display: grid;
  align-items: center;
}

@media (min-width: 64rem) {
  .hero,
  .hero img {
    height: calc(100vh - 250px);
  }

  .hero__media {
    position: absolute;
    width: 55%;
    right: 0;
  }

  .hero__media img {
    width: 100%;
    object-fit: cover;
  }

  .hero__text {
    width: 40%;
  }
}

.collection__hero {
  background: url("/img/hero-2.jpg") no-repeat center;
  background-size: cover;
  min-height: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
}

@media (min-width: 64rem) {
  .collection__hero {
    min-height: 300px;
  }
}

.link-card {
  position: relative;
  background: hsla(var(--color-bg-h), var(--color-bg-s), var(--color-bg-l), 0.85);
  padding: var(--space-md);
  text-align: center;
  display: inline-flex;
  flex-direction: column;
}

.link-card:before {
  content: "";
  border: 2px solid var(--color-primary);
  position: absolute;
  top: var(--space-xs);
  left: var(--space-xs);
  right: var(--space-xs);
  bottom: var(--space-xs);
}

.link-card a {
  z-index: 1;
}

.product__image-holder {
  position: relative;
}

.product__gender {
  position: absolute;
  top: var(--space-md);
  right: var(--space-md);
}

.product__360-view {
  position: absolute;
  bottom: var(--space-md);
  right: var(--space-md);
  cursor: pointer;
}

.product__360-view .icon {
  font-size: var(--text-xxxl);
}

@media (min-width: 64rem) {
  .product__360-view .icon {
    font-size: var(--text-xxxxl);
  }
}

.product__color {
  position: relative;
  overflow: hidden;
  box-shadow: var(--shadow-sm);
  transition: 0.3s;
}

.product__color:hover {
  cursor: pointer;
}

.product__color--selected {
  transition: transform 0.3s;
  box-shadow: var(--shadow-sm), 0 0 0 2px var(--color-primary-lighter);
}

.product__color-input {
  display: none;
}

.product__info {
  margin-top: var(--space-md);
}

@media (min-width: 64rem) {
  .product__info {
    background: var(--color-contrast-lower);
    padding: var(--space-md) var(--space-lg) 0;
  }
}

.order-summary-table {
  font-size: var(--text-sm);
}

.order-summary-table td {
  padding: var(--space-xs) var(--space-sm) var(--space-xs) 0;
  border-bottom: 1px solid var(--color-contrast-low);
}

.order-summary-table td:last-child {
  width: 20%;
}

.order-summary-table tfoot td {
  border-bottom: 0;
}

html {
  scroll-behavior: smooth;
}

.loved {
  color: var(--color-primary-darker);
}

.loved path {
  fill: var(--color-primary);
}

.home-brands {
  display: none;
}

.invalid-feedback {
  color: #b85c5c;
  font-size: 14px;
  margin-top: 5px;
  text-transform: uppercase;
  display: none;
}

.lensflow select {
  border: 0;
  border-bottom: 2px solid var(--color-contrast-medium);
}

.lens-price {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  padding: 8px;
  background: var(--color-primary);
  color: var(--color-white);
  font-weight: 500;
}

.lens-features-list {
  display: table;
  margin-left: auto;
  margin-right: auto;
  text-align: left;
}

.lensflow .tabs > ul {
  background: var(--color-bg);
  border-radius: calc(50em * 1.4);
  padding: 8px 5px;
  max-width: 310px;
  margin: auto;
}

.lensflow .tabs li {
  margin: 0;
}

.lensflow .tabs__control {
  color: #000;
  padding: 5px 20px;
  border-radius: calc(50em * 1.4);
}

.lensflow .tabs__control[aria-selected=true] {
  text-decoration: none;
  background: #dfdfdf;
  background: linear-gradient(135deg, #dfdfdf 50%, #d0d0d0 50%);
  position: relative;
  color: inherit;
}

.lensflow .tabs__control--gold[aria-selected=true] {
  background: #f6d374;
  background: linear-gradient(135deg, #f6d374 50%, #efb840 50%);
}

.lensflow .tabs__control--platinum[aria-selected=true] {
  background: #e7eaea;
  background: linear-gradient(135deg, #e7eaea 50%, #cbd4e2 50%);
}

.order-4\@sm {
  order: 4;
}

.order-5\@sm {
  order: 5;
}

.order-6\@sm {
  order: 6;
}

.order-7\@sm {
  order: 7;
}

.lensflow .form-control {
  padding: 0.5em;
  box-sizing: border-box;
}

.lensflow .select__icon {
  width: 10px;
  height: 10px;
  color: hsla(var(--color-contrast-high-h), var(--color-contrast-high-s), var(--color-contrast-high-l), 0.8);
}

.lensflow .prescription-enter label {
  line-height: 1.3;
  display: block;
}

.lensflow .prescription-enter label.lh-normal {
  line-height: initial;
}

