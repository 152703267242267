/* -------------------------------- 

File#: _1_product-card
Title: Product Card
Descr: A selection of product information used as teasers for further content
Usage: codyhouse.co/license

-------------------------------- */

.prod-card {
  position: relative;
}

.prod-card__body {
  position: relative;
  padding: var(--space-xl) var(--space-md);
}

.prod-card__img-link {
  text-decoration: none;
  display: block;

  &:hover {
    .prod-card__img {
      transform: scale(1.05);
    }
  }

  &:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: var(--color-contrast-medium);
    opacity: 0.07;
    z-index: var(--zindex-popover);
  }
}

.prod-card__img {
  transition: transform 350ms;

  img {
    display: block;
    width: 100%;
  }
}

.prod-card__badge {
  position: absolute;
  z-index: 1;
  top: var(--space-sm);
  left: var(--space-sm);
  background-color: var(--color-contrast-medium);
  padding: var(--space-xxs) var(--space-sm);

  font-size: var(--text-xs);
  text-transform: uppercase;
  letter-spacing: 0.1em;
  color: var(--color-white);
}

.prod-card__gender {
  position: absolute;
  z-index: 1;
  top: var(--space-sm);
  right: var(--space-sm);
}

.prod-card__heart {
  position: absolute;
  z-index: var(--zindex-popover);
  bottom: var(--space-sm);
  right: var(--space-sm);
  cursor: pointer;
}

.prod-card__footer {
  @include breakpoint(md) {
    padding: var(--space-xxs);
  }

  a {
    color: inherit;
    text-decoration: none;
  }
}

.prod-card__price {
}

.prod-card__price--old {
  color: var(--color-accent);
  text-decoration: line-through;

  &::before {
    content: "original price";
    @include srHide;
  }
}

.prod-card__price--new {
  text-decoration: none;

  &::before {
    content: "discounted price";
    @include srHide;
  }
}

.loved {
  color: var(--color-error);
}
