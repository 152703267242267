:root {
  // size - 👇 uncomment to modify default icon sizes
  // --icon-xxxs: 8px;
  // --icon-xxs:  12px;
  // --icon-xs:   16px;
  // --icon-sm:   24px;
  // --icon-md:   32px;
  // --icon-lg:   48px;
  // --icon-xl:   64px;
  // --icon-xxl:  96px;
  // --icon-xxxl: 128px;
}

.icon {
  // 👇 include the font-family declaration here if you are using an icon font
  // font-family: 'fontName';
  vertical-align: middle;
}
