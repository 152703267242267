.product__image-holder {
  position: relative;
}
.product__gender {
  position: absolute;
  top: var(--space-md);
  right: var(--space-md);
}
.product__360-view {
  position: absolute;
  bottom: var(--space-md);
  right: var(--space-md);
  cursor: pointer;
  .icon {
    font-size: var(--text-xxxl);
    @include breakpoint(md) {
      font-size: var(--text-xxxxl);
    }
  }
}
.product__color {
  position: relative;
  overflow: hidden;
  box-shadow: var(--shadow-sm);
  transition: 0.3s;

  &:hover {
    cursor: pointer;
  }
  &--selected {
    transition: transform 0.3s;
    box-shadow: var(--shadow-sm), 0 0 0 2px var(--color-primary-lighter);
  }
}
.product__color-input {
  display: none;
}
.product__info {
  margin-top: var(--space-md);
  @include breakpoint(md) {
    background: var(--color-contrast-lower);
    padding: var(--space-md) var(--space-lg) 0;
  }
}
