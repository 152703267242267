/* -------------------------------- 

File#: _1_order-summary
Title: Order Summary
Descr: A template showing a recap of the cart items
Usage: codyhouse.co/license

-------------------------------- */

:root {
  --order-summary-img-size: 3.6em;
  --order-summary-img-margin-right: var(--space-md);
}

.order-summary {
}

.order-summary__header {
  border-radius: inherit;
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
}

.order-summary__footer {
  border-radius: inherit;
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
}

.order-summary__item {
  display: grid;
  grid-template-columns: var(--order-summary-img-size) 1fr;
  grid-gap: var(--order-summary-img-margin-right);
  align-items: center;
}

.order-summary__img {
  display: flex;
  align-items: center;
  padding: var(--space-xxxs);
  background: var(--color-white);
  width: var(--order-summary-img-size);
  height: var(--order-summary-img-size);
  border-radius: var(--radius-md);
  overflow: hidden;
  box-shadow: var(--shadow-sm);
  transition: 0.2s;

  img {
    display: block;
    width: 100%;
    object-fit: cover;
  }

  &:hover {
    transform: translateY(-1px);
    box-shadow: var(--shadow-sm);
  }
}
