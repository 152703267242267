.order-summary-table {
  font-size: var(--text-sm);
  td {
    padding: var(--space-xs) var(--space-sm) var(--space-xs) 0;
    border-bottom: 1px solid var(--color-contrast-low);
    &:last-child {
      width: 20%;
    }
  }
  tfoot td {
    border-bottom: 0;
  }
}
