/* -------------------------------- 

File#: _1_adv-gallery
Title: Advanced Gallery
Descr: Advanced image gallery template
Usage: codyhouse.co/license

-------------------------------- */

.adv-gallery {
    position: relative;
    z-index: 1;
}

.adv-gallery__img {
    width: 100%;
    display: block;
    object-fit: cover;
}

.adv-gallery__grid {
    display: grid;
    grid-gap: var(--space-xxs);
    grid-template-rows: repeat(12, 15px);

    > :nth-child(1) {
        grid-column: 1 / 4;
        grid-row: 1 / 7;
    }

    > :nth-child(2) {
        grid-column: 1 / 4;
        grid-row: 7 / -1;
    }

    > :nth-child(3) {
        grid-column: 4 / 10;
        grid-row: 1 / -1;
    }

    > :nth-child(4) {
        grid-column: 10 / 13;
        grid-row: 1 / 7;
    }
    > :nth-child(5) {
        grid-column: 10 / 13;
        grid-row: 7 / -1;
    }

    .adv-gallery__img {
        height: 100%;
    }
}

@include breakpoint(md) {
    .adv-gallery__grid {
        grid-gap: var(--space-sm);
        grid-template-rows: repeat(12, 40px);
    }
}
