// --------------------------------

// (START) Global Editor code https://codyhouse.co/ds/globals/typography

// --------------------------------

:root {
  // font family
  --font-primary: Poppins, sans-serif;
  --font-secondary: Poppins, sans-serif;

  // body font size
  --text-base-size: 1em;

  // type scale
  --text-scale-ratio: 1.2;
  --text-xs: calc((1em / var(--text-scale-ratio)) / var(--text-scale-ratio));
  --text-sm: calc(var(--text-xs) * var(--text-scale-ratio));
  --text-md: calc(var(--text-sm) * var(--text-scale-ratio) * var(--text-scale-ratio));
  --text-lg: calc(var(--text-md) * var(--text-scale-ratio));
  --text-xl: calc(var(--text-lg) * var(--text-scale-ratio));
  --text-xxl: calc(var(--text-xl) * var(--text-scale-ratio));
  --text-xxxl: calc(var(--text-xxl) * var(--text-scale-ratio));
  --text-xxxxl: calc(var(--text-xxxl) * var(--text-scale-ratio));

  // line-height
  --body-line-height: 1.5;
  --heading-line-height: 1.2;

  // capital letters - used in combo with the lhCrop mixin
  --font-primary-capital-letter: 1;
  --font-secondary-capital-letter: 1;
}

@supports (--css: variables) {
  :root {
    @include breakpoint(md) {
      --text-base-size: 1.2em;
      --text-scale-ratio: 1.2;
    }
  }
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 600;
  color: alpha(var(--color-black), 0.9);
  font-family: var(--font-primary);
}
body {
  font-family: var(--font-secondary);
  font-weight: 300;
  color: alpha(var(--color-black), 0.8);
}

// font family
.font-primary {
  font-family: var(--font-primary);
}
.font-secondary {
  font-family: var(--font-secondary);
}

// --------------------------------

// (END) Global Editor Code

// --------------------------------

// link style
a,
.link {
  color: inherit;
}

mark {
  background-color: alpha(var(--color-accent), 0.2);
  color: inherit;
}

.text-component {
  --line-height-multiplier: 1;
  --text-vspace-multiplier: 1;

  blockquote {
    padding-left: 1em;
    border-left: 4px solid var(--color-contrast-low);
  }

  hr {
    background: var(--color-contrast-low);
    height: 1px;
  }

  figcaption {
    font-size: var(--text-sm);
    color: var(--color-contrast-medium);
  }
}

.article {
  // e.g., blog posts
  --body-line-height: 1.58; // set body line-height
  --text-vspace-multiplier: 1.2; // increase vertical spacing
}

b {
  font-weight: 500;
}
