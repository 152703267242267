/* -------------------------------- 

File#: _1_tabbed-navigation-v2
Title: Tabbed Navigation v2
Descr: Tabbed (secondary) navigation
Usage: codyhouse.co/license

-------------------------------- */

.tabs-nav-v2 {
  --tabs-nav-border-width: 1px;
  display: flex;
  flex-wrap: wrap;
  border-bottom: var(--tabs-nav-border-width) solid var(--color-accent);

  li {
    display: inline-block; // flexbox fallback
    margin-right: var(--space-xs);
    margin-bottom: var(--space-xs);
  }
}

.tabs-nav-v2__item {
  display: inline-block;
  padding: var(--space-xs) var(--space-sm);
  border-radius: var(--radius-md);
  background-color: var(--color-contrast-low);
  color: inherit;
  white-space: nowrap;
  font-family: var(--font-primary);
  text-decoration: none;
}

.tabs-nav-v2__item--selected,
.tabs-nav-v2__item[aria-selected="true"] {
  color: var(--color-white);
  background-color: var(--color-contrast-higher);
}

@include breakpoint(md) {
  .tabs-nav-v2 {
    li {
      margin: 0;
    }
  }

  .tabs-nav-v2__item {
    border-radius: var(--radius-md) var(--radius-md) 0 0;
    background-color: transparent;
    border: var(--tabs-nav-border-width) solid transparent;
    border-bottom-width: 0;

    &:hover {
      background-color: var(--color-contrast-lower);
    }
  }

  .tabs-nav-v2__item--selected,
  .tabs-nav-v2__item[aria-selected="true"] {
    position: relative;
    background-color: var(--color-bg);
    color: var(--color-black);
    border-color: var(--color-accent);
    font-weight: 600;

    &::after {
      content: "";
      position: absolute;
      bottom: calc(var(--tabs-nav-border-width) * -1);
      left: 0;
      width: 100%;
      height: var(--tabs-nav-border-width);
      background-color: var(--color-bg);
    }

    &:hover {
      background-color: var(--color-bg);
    }
  }
}
