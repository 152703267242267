/* -------------------------------- 

File#: _1_dialog
Title: Dialog
Descr: Overlay informing user about tasks/decisions
Usage: codyhouse.co/license

-------------------------------- */

.dialog {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: var(--zindex-overlay);
  // hide element
  opacity: 0;
  visibility: hidden;
}

.dialog__content {
  width: calc(100% - 2 * var(--space-md));
  overflow: auto;
  padding: var(--space-md);
  background-color: var(--color-bg);
  box-shadow: var(--shadow-lg);
}

.dialog--is-visible {
  opacity: 1;
  visibility: visible;
  background: alpha(var(--color-black), 0.5);
}

.dialog--sticky {
  align-items: flex-start;

  .dialog__content {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }
}

// if animation = enabled
.dialog[data-animation="on"] {
  transition: opacity 0.3s, visibility 0s 0.3s;
  perspective: 800px;

  .dialog__content {
    will-change: transform;
    transition: transform 0.3s var(--ease-out);
    transform: translateY(10%);
  }

  &.dialog--is-visible {
    transition: opacity 0.3s;

    .dialog__content {
      transform: translateY(0);
    }
  }

  &.dialog--sticky {
    .dialog__content {
      transform: translateY(-100%);
    }
  }

  &.dialog--sticky.dialog--is-visible {
    transition: none;

    .dialog__content {
      transform: translateY(0);
    }
  }
}
