.form-control,
#card-element {
  --form-control-font-size: var(--text-sm);
  --form-control-padding-x: var(--space-xs);
  --form-control-padding-y: var(--space-sm);
  --form-control-radius: var(--radius-sm);
  background-color: var(--color-bg);
  color: var(--color-contrast-high);
  border: 1px solid alpha(var(--color-contrast-high), 0.3);
  font-family: var(--font-secondary);
  line-height: 1.2;
  transition: all 0.2s ease;

  &::placeholder {
    opacity: 1;
    color: alpha(var(--color-contrast-high), 0.5);
  }

  &:focus {
    border-color: var(--color-primary);
    box-shadow: none;
    outline: none;
  }
}

#card-element {
  padding: var(--space-sm) var(--space-xs);
}

.form-control--disabled,
.form-control[disabled],
.form-control[readonly] {
  background-color: alpha(var(--color-contrast-low), 0.75);
  cursor: not-allowed;
}

.form-control[aria-invalid="true"],
.form-control--error {
  border-color: var(--color-error-light);

  &:focus {
    box-shadow: 0px 0px 0px 2px alpha(var(--color-error), 0.2);
  }
}

.form-legend {
  font-family: var(--font-primary);
  font-size: var(--text-md);
  font-weight: 600;
  text-transform: uppercase;
}

.form-label {
  font-family: var(--font-secondary);
  font-size: var(--text-sm);
  font-weight: 500;
}
.form-textarea {
  min-height: 100px;
}
