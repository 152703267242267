html {
  scroll-behavior: smooth;
}
.loved {
  color: var(--color-primary-darker);
}
.loved path {
  fill: var(--color-primary);
}
.home-brands {
  display: none;
}

// Errors
.invalid-feedback {
  color: #b85c5c;
  font-size: 14px;
  margin-top: 5px;
  text-transform: uppercase;
  display: none;
}

// lensflow
.lensflow select {
  border: 0;
  border-bottom: 2px solid var(--color-contrast-medium);
}
.lens-price {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  padding: 8px;
  background: var(--color-primary);
  color: var(--color-white);
  font-weight: 500;
}
.lens-features-list {
  display: table;
  margin-left: auto;
  margin-right: auto;
  text-align: left;
}
.lensflow .tabs > ul {
  background: var(--color-bg);
  border-radius: calc(50em * 1.4);
  padding: 8px 5px;
  max-width: 310px;
  margin: auto;
}
.lensflow .tabs li {
  margin: 0;
}
.lensflow .tabs__control {
  // background: #ccc;
  color: #000;
  padding: 5px 20px;
  border-radius: calc(50em * 1.4);
}
.lensflow .tabs__control[aria-selected="true"] {
  text-decoration: none;
  background: rgb(223, 223, 223);
  background: linear-gradient(135deg, rgba(223, 223, 223, 1) 50%, rgba(208, 208, 208, 1) 50%);
  position: relative;
  color: inherit;
}
.lensflow .tabs__control--gold[aria-selected="true"] {
  background: rgb(246, 211, 116);
  background: linear-gradient(135deg, rgba(246, 211, 116, 1) 50%, rgba(239, 184, 64, 1) 50%);
}
.lensflow .tabs__control--platinum[aria-selected="true"] {
  background: rgb(231, 234, 234);
  background: linear-gradient(135deg, rgba(231, 234, 234, 1) 50%, rgba(203, 212, 226, 1) 50%);
}

.order-4\@sm {
  order: 4;
}
.order-5\@sm {
  order: 5;
}
.order-6\@sm {
  order: 6;
}
.order-7\@sm {
  order: 7;
}

.lensflow .form-control {
  padding: 0.5em;
  box-sizing: border-box;
}
.lensflow .select__icon {
  width: 10px;
  height: 10px;
  color: alpha(var(--color-contrast-high), 0.8);
}
.lensflow .prescription-enter label {
  line-height: 1.3;
  display: block;
}
.lensflow .prescription-enter label.lh-normal {
  line-height: initial;
}
